import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Tabs, Tab, Avatar, Stack } from "@mui/material";
import PropTypes from "prop-types";
import "src/App.css";
import CustomInput from "src/components/CustomInput";
import PersonalData from "./PersonalData";
import Family from "./Family";
import Education from "./Education";
import Organization from "./Organization";
import Experience from "./Experience";
import PersonalInfo from "./PersonalInfo";
import UploadDocument from "./UploadDocument";
import Illness from "./Illness";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "0 24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Index = ({}) => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = React.useState(0);

  useEffect(() => {}, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Grid
      container
      component="main"
      maxWidth={"lg"}
      sx={{ m: "auto" }}
      spacing={2}
    >
      <Grid item sm={12}>
        <Typography variant="h5" sx={{ m: "3rem 0 1rem 0" }}>
          Profile
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              sx={{ alignItems: "baseline", textTransform: "none" }}
              label="Data Diri"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ alignItems: "baseline", textTransform: "none" }}
              label="Data Keluarga"
              {...a11yProps(1)}
            />
            <Tab
              sx={{ alignItems: "baseline", textTransform: "none" }}
              label="Data Pendidikan"
              {...a11yProps(2)}
            />
            <Tab
              sx={{
                alignItems: "baseline",
                textTransform: "none",
                textAlign: "left",
              }}
              label="Pengalaman Organisasi"
              {...a11yProps(3)}
            />
            <Tab
              sx={{ alignItems: "baseline", textTransform: "none" }}
              label="Pengalaman Kerja"
              {...a11yProps(4)}
            />
            <Tab
              sx={{
                alignItems: "baseline",
                textTransform: "none",
                textAlign: "left",
              }}
              label="Pernyataan Pribadi"
              {...a11yProps(5)}
            />
            <Tab
              sx={{
                alignItems: "baseline",
                textTransform: "none",
                textAlign: "left",
              }}
              label="Riwayat Kesehatan"
              {...a11yProps(5)}
            />
            <Tab
              sx={{ alignItems: "baseline", textTransform: "none" }}
              label="Upload Dokumen"
              {...a11yProps(7)}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <PersonalData />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Family />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Education />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Organization />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Experience />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <PersonalInfo />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Illness />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <UploadDocument />
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Index;
