import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Divider,
  Card,
  Grid,
  Stack,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  refWorkerStatus,
  createExperience,
  getExperience,
  updateExperience,
  deleteExperience,
  setSnackbarOpen,
} from "src/redux/actions";
import { CustomAutoComplete } from "src/pages/admin/Setting/EditPanel/ComponentGroup";
import CustomDatePicker from "src/components/CustomDatePicker";
import { parsedDate } from "src/functions/parsedDate";

const dummyData = [
  {
    institute_name: "PT ABC",
    field: "Electrical",
    status: "Kontrak",
    started_at: "2015-08-08",
    finished_at: "2017-08-08",
    reason_to_stop: "Habis kontrak",
    descriptions: "Membuat a",
    net_income: "Rp 6.000.000",
    bio_experience_id: "1231233332",
    position: "Leader",
  },
];

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const Experience = () => {
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState("");
  const [workerStatus, setWorkerStatus] = useState([]);
  const [form, setForm] = useState({
    started_at: new Date(),
    finished_at: new Date(),
  });
  const [experiences, setExperiences] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    fetchRef();
    fetchExp();
  }, []);

  const fetchRef = async () => {
    const resRef = await Promise.all([refWorkerStatus()]);
    setWorkerStatus(resRef[0].data);
  };

  const fetchExp = async () => {
    setLoading(true);
    const resExp = await getExperience();
    setExperiences(resExp.data);
    setLoading(false);
  };

  const handleSelectAuto = async (val, key) => {
    if (key === "status") {
      setForm({ ...form, worker_status: val, status: val.worker_status_id });
      return;
    }
    setForm({ ...form, [key]: val[key] });
  };

  const handleDateChange = (val, name) => {
    setForm({ ...form, [name]: val });
  };

  const handleChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleEdit = (d) => {
    setForm(d);
    setOpenForm("edit");
  };

  const handleDelete = (d) => {
    setForm(d);
    setAlertOpen(true);
  };

  const handleSubmitDelete = async () => {
    await deleteExperience(form);
    setForm({});
    setSnackbarOpen("success", "Data berhasil dihapus");
    setAlertOpen(false);
    fetchExp();
  };

  const handleSave = async () => {
    if (openForm === "new") {
      await createExperience(form);
    } else {
      await updateExperience(form);
    }
    setSnackbarOpen("success", "Data disimpan");
    setOpenForm("");
    setForm({});
    fetchExp();
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 2 }}>
        <b>Pengalaman Kerja</b>
      </Typography>
      <Button
        variant="contained"
        sx={{ display: "flex", m: "auto 0 1rem auto" }}
        onClick={() => setOpenForm("new")}
      >
        Tambah
      </Button>
      {experiences.map((exp) => (
        <Card
          id={exp.bio_experience_id}
          sx={{ backgroundColor: "#FFF", p: 2, borderRadius: "16px" }}
        >
          <Grid container>
            <Grid item sm={10}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "bold", mb: "1rem" }}
              >
                {exp.institute_name}{" "}
                <span style={{ fontWeight: "normal" }}>{` (${parsedDate(
                  exp.started_at
                )} s.d. ${parsedDate(exp.finished_at)})`}</span>
              </Typography>
              <Grid container>
                <Grid item sm={3}>
                  <Typography sx={{ fontSize: "14px" }}>
                    Bidang Industri
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    : {exp.field}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={3}>
                  <Typography sx={{ fontSize: "14px" }}>
                    Nama Posisi/Jabatan
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    : {exp.position}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={3}>
                  <Typography sx={{ fontSize: "14px" }}>
                    Status Pekerja
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    : {exp.worker_status?.worker_status_name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={3}>
                  <Typography sx={{ fontSize: "14px" }}>
                    Alasan Berhenti
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    : {exp.reason_to_stop}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={3}>
                  <Typography sx={{ fontSize: "14px" }}>
                    Uraian Pekerjaan
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    : {exp.descriptions}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={3}>
                  <Typography sx={{ fontSize: "14px" }}>
                    Pendapatan Bersih
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    : {exp.net_income}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2} sx={{ m: "auto" }}>
              <Stack direction="row" justifyContent={"end"} spacing={1}>
                <IconButton onClick={() => handleEdit(exp)}>
                  <EditRoundedIcon />{" "}
                </IconButton>
                <IconButton onClick={() => handleDelete(exp)}>
                  <DeleteRoundedIcon />{" "}
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      ))}
      <Divider sx={{ mt: 2, mb: 2 }} />
      {openForm && (
        <>
          <CustomInput
            label="Nama Perusahaan"
            name="institute_name"
            onChange={handleChangeInput}
            value={form.institute_name}
          />
          <CustomInput
            label="Bidang Industri"
            name="field"
            onChange={handleChangeInput}
            value={form.field}
          />
          <CustomInput
            label="Nama Posisi/Jabatan"
            name="position"
            onChange={handleChangeInput}
            value={form.position}
          />
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Status Pekerja
          </Typography>
          <CustomAutoComplete
            name="status"
            optionsArr={workerStatus}
            keyLabel={"worker_status_name"}
            handleSelect={handleSelectAuto}
            register="true"
            style={{ marginBottom: "1rem" }}
            value={form.worker_status}
          />
          <CustomDatePicker
            label="Tanggal Mulai"
            onChange={handleDateChange}
            name="started_at"
            value={form.started_at}
          />
          <CustomDatePicker
            label="Tanggal Selesai"
            onChange={handleDateChange}
            name="finished_at"
            value={form.finished_at}
          />
          <CustomInput
            label="Alasan Berhenti"
            multiline
            rows={4}
            name="reason_to_stop"
            onChange={handleChangeInput}
            value={form.reason_to_stop}
          />
          <CustomInput
            label="Uraian Pekerjaan"
            multiline
            rows={4}
            name="descriptions"
            onChange={handleChangeInput}
            value={form.descriptions}
          />
          <CustomInput
            label="Pendapatan Bersih"
            name="net_income"
            onChange={handleChangeInput}
            value={form.net_income}
          />
          <Stack direction="row" spacing={2} justifyContent={"end"}>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenForm("");
                setForm({});
              }}
            >
              Batal
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Simpan
            </Button>
          </Stack>
        </>
      )}

      <CustomDialog open={alertOpen} fullWidth maxWidth="xs">
        <DialogTitle>Konfirmasi</DialogTitle>
        <DialogContent>
          Data{" "}
          <b>
            {form.institute_name} - {form.position}
          </b>{" "}
          akan dihapus
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setAlertOpen(false);
              setForm({});
            }}
          >
            Batal
          </Button>
          <Button variant="contained" onClick={handleSubmitDelete}>
            Ya, Hapus
          </Button>
        </DialogActions>
      </CustomDialog>
    </Container>
  );
};

export default Experience;
