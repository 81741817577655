import axios from "axios";
import { setDeauthenticated, setSnackbarOpen } from "src/redux/actions";
import localforage from "localforage";

const setAPI = async () => {
  const instance = axios.create({
    // baseURL: newAPI[0].api + "api_colagio/",
    baseURL:
      process.env.NODE_ENV !== "production"
        ? "http://localhost:5000/api_colagio"
        : window.location.origin + "/api_colagio",
    // baseUrl: "https://colagio.shratech.com/api_colagio", 
  });

  instance.interceptors.request.use(async (config) => {
    config.headers["x-access-token"] = await localforage.getItem("token");
    return config;
  });

  instance.interceptors.request.use((config) => {
    if (config.data instanceof FormData) {
      return config;
    } else {
      if (config.data === undefined) {
        return config;
      } else {
        config.headers["x-access-token"] = config.data.token;
        return config;
      }
    }
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        setDeauthenticated();
        setSnackbarOpen("error", "Session Ands sudah habis");
        setTimeout(() => {
          window.location.replace("/v2/user/");
        }, 1000);
      }
      return Promise.reject(error);
    }
  );
  return instance;
};
export default setAPI;
