import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Typography,
  useTheme,
  Grid,
  Stack,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router";
import {
  enrollCourse,
  getTokenPayment,
  paidChecker,
  setOpenLogin,
  setSnackbarOpen,
  candidateCheckApply,
  candidateApplyJob,
} from "src/redux/actions";
import localforage from "localforage";
import { LocationCity, DateRange } from "@mui/icons-material";
import { parsedDate } from "src/functions/parsedDate";
import { domain } from "src/components/Layout/data";
import ModalApplyJob from "../Modal/ModalApplyJob";
import ModalConfirmApplyJob from "../Modal/ModalConfirmApplyJob";
import "src/App.css";

const CardPro01 = ({
  v,
  isAuthenticated,
  libPages,
  buttons,
  home,
  userRole,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [tempBio, setTempBio] = useState({});
  // const [openRegist, setOpenRegist] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleSelect = async (v) => {
    if (!isAuthenticated) {
      setOpenLogin(true);
      return;
    }
    const token = await localforage.getItem("token");
    setLoadingBtn(true);

    if (home) {
      if (v.job_posting_id) {
        const resCheck = await candidateCheckApply({
          job_posting_id: v.job_posting_id,
        });
        if (resCheck.code === 200) {
          setTempBio(resCheck.data);
          // setOpenRegist(true);
          setOpenAlert(true);
        } else {
          setSnackbarOpen("error", "Anda sudah terdaftar");
        }
        setLoadingBtn(false);
        return;
      }

      // ini kalau bukan tipenya recruitment
      const resEnroll = await enrollCourse({
        token,
        publishId: v.publish_id,
      });
      if (resEnroll.code === 200) {
        navigate(`/v2/user/dashboard/${libPages[1]}`);
        setSnackbarOpen("success", "Enrolled successfully");
      } else if (resEnroll.status === 400) {
        if (resEnroll.data.message.indexOf("sudah terdaftar") !== -1) {
          navigate(`/v2/user/dashboard/${libPages[1]}`);
        }
        setSnackbarOpen("error", resEnroll.data.message);
      }
      setLoadingBtn(false);
    }

    if (!home) {
      if (v.job_posting_id) {
        const resCheck = await candidateCheckApply({
          job_posting_id: v.job_posting_id,
        });
        console.log(resCheck);
        return;
      }

      // ini kalau bukan tipenya recruitment
      const resPaidChecker = await paidChecker({
        token,
        publishId: v.publish_id,
      });

      if (v.payment_type === "in-advance" && resPaidChecker.status === 400) {
        const resToken = await getTokenPayment({
          token,
          publishId: v.publish_id,
          domain,
        });
        window.snap.pay(resToken.transactionToken);
        setLoadingBtn(false);
        return;
      }

      setLoadingBtn(false);
      const newWindow = window.open(
        `${window.location.origin}/entrance/pages/login?email=${v.participantId}&password=${v.password}&ecommerceUrl=${window.location.hostname}`,
        // `https://entrance.shratech.com/entrance/pages/login?email=${v.participantId}&password=${v.password}&ecommerceUrl=${window.location.hostname}`,
        "_self",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  return (
    <Grid item xs={12} sx={{ maxWidth: "25% !important" }}>
      <Paper
        elevation={2}
        sx={{
          p: 2,
          borderRadius: "0.5rem",
          "&:hover": {
            cursor: "pointer",
            boxShadow:
              "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px",
          },
        }}
      >
        <Stack direction="column">
          <Typography
            variant="body1"
            className="multi-line-text"
            sx={{ fontWeight: "600" }}
          >
            {v?.ref_position_group?.position_group_name}
          </Typography>
          <Typography
            variant="body2"
            noWrap={true}
            sx={{
              borderTop: `0.1px solid lightgrey`,
              borderBottom: `0.1px solid lightgrey`,
              fontWeight: "700",
              color: `${theme.palette.primary.main}`,
              textAlign: "center",
              m: 1,
              mb: 2,
              ml: 2,
              mr: 2,
            }}
          >
            {v.job_role?.job_role_name}
          </Typography>
          <Typography
            variant="caption"
            noWrap={true}
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: "500",
              color: "text.tertiary",
              mb: 1,
            }}
          >
            <DateRange sx={{ mr: 1 }} />
            {`${parsedDate(v.closed_at)}`}
          </Typography>
          {v.ref_job_location && (
            <Typography
              variant="caption"
              noWrap={true}
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "500",
                color: "text.tertiary",
              }}
            >
              <LocationCity sx={{ mr: 1 }} />
              {v.ref_job_location?.job_location_name}
            </Typography>
          )}

          <Button
            size="normal"
            variant="outlined"
            color="primary"
            sx={{
              m: `${theme.spacing(2)} auto`,
              mb: 1,
              width: "90%",
              borderRadius: "5rem",
              transition: "all linear .4s",
            }}
            // onClick={handleSearch}
          >
            Lihat Detail
          </Button>
          <Button
            size="normal"
            variant="contained"
            color="primary"
            sx={{
              m: "0 auto",
              width: "90%",
              fontWeight: 600,
              borderRadius: "5rem",
              transition: "all linear .4s",
            }}
            disabled={loadingBtn}
            // onClick={handleSearch}
            onClick={
              loadingBtn || userRole === "5" ? null : () => handleSelect(v)
            }
          >
            Lamar Sekarang
          </Button>
        </Stack>
      </Paper>

      {/* {openRegist && (
        <ModalApplyJob
          open={openRegist}
          defaultBio={tempBio}
          jobPostingId={v.job_posting_id}
          onClose={() => setOpenRegist(false)}
        />
      )} */}

      {openAlert && (
        <ModalConfirmApplyJob
          open={openAlert}
          defaultBio={tempBio}
          jobPostingId={v.job_posting_id}
          onClose={() => setOpenAlert(false)}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  libPages: state.nav.libPages,
  buttons: state.preview.buttons,
});

export default connect(mapStateToProps)(CardPro01);
