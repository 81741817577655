import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Divider,
  Card,
  Grid,
  Stack,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  styled,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { DropzoneArea, DropzoneDialog } from "material-ui-dropzone";
import { Face } from "@mui/icons-material";
import {
  uploadFileJp,
  createPersonalDocument,
  getPersonalDocument,
  updatePersonalDocument,
  setSnackbarOpen,
} from "src/redux/actions";
import DownloadDoneRoundedIcon from "@mui/icons-material/DownloadDoneRounded";
import CloseIcon from "@mui/icons-material/Close";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const baseURL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:5000/"
    : window.location.origin + "/";

const UploadDocument = () => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [preview, setPreview] = useState("");
  const [openPreivew, setOpenPreview] = useState(false);

  useEffect(() => {
    fetchDoc();
  }, []);

  const fetchDoc = async () => {
    setLoading(true);
    const resDoc = await getPersonalDocument();
    setForm(resDoc.data[0] || {});
    setLoading(false);
  };

  const handleUpload = async (files, key) => {
    if (files.length) {
      setLoadingUpload(true);
      const resUpload = await uploadFileJp(files[0]);
      const copyForm = { ...form };
      copyForm[key] = resUpload.data?.path;
      setForm(copyForm);
      if (form.bio_document_id) {
        await updatePersonalDocument(copyForm);
      } else {
        await createPersonalDocument(copyForm);
      }
      setLoadingUpload(false);
      setSnackbarOpen("success", "Data tersimpan");
      fetchDoc();
    }
  };

  const handleOpenPreview = (d) => {
    setPreview(d);
    setOpenPreview(!openPreivew);
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 3 }}>
        <b>Upload Dokumen</b>
      </Typography>
      <Stack sx={{ mb: "2rem" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          Kartu Identitas
        </Typography>
        <Stack direction="row" spacing={2}>
          {form.identity_card ? (
            <Button
              variant="contained"
              endIcon={<DownloadDoneRoundedIcon />}
              onClick={() => handleOpenPreview("identity_card")}
            >
              Preview
            </Button>
          ) : null}
          <Button variant="outlined" component="label" disabled={loadingUpload}>
            Upload Kartu Identitas
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => handleUpload(e.target.files, "identity_card")}
            />
          </Button>
        </Stack>
      </Stack>
      <Stack sx={{ mb: "2rem" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          Ijazah/Surat Keterangan Lulus
        </Typography>
        <Stack direction="row" spacing={2}>
          {form.graduation_certificate ? (
            <Button
              variant="contained"
              endIcon={<DownloadDoneRoundedIcon />}
              onClick={() => handleOpenPreview("graduation_certificate")}
            >
              Preview
            </Button>
          ) : null}
          <Button variant="outlined" component="label" disabled={loadingUpload}>
            Upload Ijazah/Surat Keterangan Lulus
            <input
              hidden
              accept="application/pdf"
              type="file"
              onChange={(e) =>
                handleUpload(e.target.files, "graduation_certificate")
              }
            />
          </Button>
        </Stack>
      </Stack>
      <Stack sx={{ mb: "2rem" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          Transkrip Nilai
        </Typography>
        <Stack direction="row" spacing={2}>
          {form.grade_transcript ? (
            <Button
              variant="contained"
              endIcon={<DownloadDoneRoundedIcon />}
              onClick={() => handleOpenPreview("grade_transcript")}
            >
              Preview
            </Button>
          ) : null}
          <Button variant="outlined" component="label" disabled={loadingUpload}>
            Upload Transkrip Nilai
            <input
              hidden
              accept="application/pdf"
              type="file"
              onChange={(e) => handleUpload(e.target.files, "grade_transcript")}
            />
          </Button>
        </Stack>
      </Stack>
      <Stack sx={{ mb: "2rem" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          Resume / CV
        </Typography>
        <Stack direction="row" spacing={2}>
          {form.resume ? (
            <Button
              variant="contained"
              endIcon={<DownloadDoneRoundedIcon />}
              onClick={() => handleOpenPreview("resume")}
            >
              Preview
            </Button>
          ) : null}
          <Button variant="outlined" component="label" disabled={loadingUpload}>
            Upload Resume/CV
            <input
              hidden
              accept="application/pdf"
              type="file"
              onChange={(e) => handleUpload(e.target.files, "resume")}
            />
          </Button>
        </Stack>
      </Stack>

      <CustomDialog
        open={openPreivew}
        fullWidth
        maxWidth="md"
        onClose={() => {
          setOpenPreview(false);
          setPreview("");
        }}
      >
        <DialogTitle sx={{ mb: "1rem" }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenPreview(false);
              setPreview("");
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {preview === "identity_card" ? (
          <img
            src={baseURL + form.identity_card}
            alt="ktp"
            style={{ maxWidth: "500px", margin: "auto" }}
          />
        ) : (
          <object
            data={baseURL + form[preview]}
            type="application/pdf"
            width="100%"
            height="700px"
          >
            <p>
              Unable to display PDF file.{" "}
              <a href={baseURL + form[preview]}>Download</a> instead.
            </p>
          </object>
        )}
      </CustomDialog>
    </Container>
  );
};

export default UploadDocument;
