import React from "react";
import { connect } from "react-redux";
import { setContentHoverId, setContentPreview } from "src/redux/actions";
import {
  Box,
  Paper,
  Typography,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  ArrowDropDown,
  ArrowRight,
  Edit,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import "src/App.css";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "20px !important",
  },
}));

const Panel3 = ({
  openPanel3,
  setOpenPanel3,
  setOpenModal,
  setDataId,
  contentPreview,
  contentHoverId,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleDisable = (v) => {
    const arr = [...contentPreview];
    let idx = arr.findIndex((obj) => obj.id === v.id);
    arr[idx].disabled = !arr[idx].disabled;
    setContentPreview(arr);
  };

  return (
    <>
      <Typography
        component="p"
        variant="body2"
        sx={{
          display: "flex",
          alignItems: "center",
          fontWeight: 600,
          transition: "all linear .4s",
          pt: 1,
          pb: 1,
          "&:hover": { cursor: "pointer" },
        }}
        onClick={() => setOpenPanel3(!openPanel3)}
      >
        {openPanel3 && (
          <ArrowDropDown sx={{ mr: 1 }} className={classes.root} />
        )}
        {!openPanel3 && <ArrowRight sx={{ mr: 1 }} className={classes.root} />}
        Content
      </Typography>
      {openPanel3 && (
        <>
          {contentPreview.map((v, i) => (
            <Paper
              key={i}
              elevation={2}
              sx={{
                width: "325px",
                mt: i === 0 ? 1 : 0, 
                mb: 1,
                p: 1,
                pl: 2,
                pr: 2,
                border:
                  contentHoverId === v.id
                    ? `1px solid ${theme.palette.blue.main}`
                    : "#ffff",
                backgroundColor: "#ffff",
                borderRadius: "1rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onMouseOver={() => setContentHoverId(v.id)}
              onMouseOut={() => setContentHoverId("")}
            >
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {v.component}
              </Typography>

              <Box>
                <Tooltip title="Edit Content">
                  <IconButton
                    sx={{
                      color: `${theme.palette.blue.main}`,
                    }}
                    onClick={() => {
                      setOpenModal(true);
                      setDataId(v.id);
                      // setContentHoverId(v.id);
                    }}
                  >
                    <Edit className={classes.root} />
                  </IconButton>
                </Tooltip>

                <Tooltip title={!v.disabled ? "Hide Content" : "Show Content"}>
                  <IconButton
                    sx={{
                      color: v.disabled
                        ? "lightgrey"
                        : `${theme.palette.blue.main}`,
                    }}
                    onClick={() => handleDisable(v)}
                  >
                    {v.disabled && <VisibilityOff className={classes.root} />}
                    {!v.disabled && <Visibility className={classes.root} />}
                  </IconButton>
                </Tooltip>
              </Box>
            </Paper>
          ))}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  contentPreview: state.preview.contentPreview,
  contentHoverId: state.preview.contentHoverId,
});

export default connect(mapStateToProps)(Panel3);
