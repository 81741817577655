import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Stack,
  Paper,
  useMediaQuery,
  IconButton,
  Button,
  Autocomplete,
  useTheme,
  Typography,
  styled,
  InputAdornment,
} from "@mui/material";
import {
  getRefZip,
  getRefEdu,
  registerForm,
  setAuthenticated,
  getRefRegion,
  getRef,
  getRefUniSP,
  createEducation,
} from "src/redux/actions";
import localforage from "localforage";
import { useNavigate } from "react-router";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { inputNumberOnly } from "src/functions/inputNumberOnly";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { CustomAutoComplete } from "src/pages/admin/Setting/EditPanel/ComponentGroup";

const CustomTextField = styled(TextField)(({ theme, errorValidate }) => ({
  "& fieldset": {
    borderColor: errorValidate ? "red !important" : "auto",
  },
  "& .MuiFormHelperText-root": {
    color: `red !important`,
  },
  "&.Mui-focused": {
    border: `0.1px solid ${theme.palette.primary.main}`,
  },
  "&:hover fieldset": {
    border: `0.1px solid ${theme.palette.primary.main} !important`,
  },
}));

const Register = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({});
  const [tempBD, setTempBD] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [openSelect, setOpenSelect] = useState(false);
  const [refZip, setRefZip] = useState([]);
  const [refEdu, setRefEdu] = useState([]);
  const [validate, setValidate] = useState(false);
  const [cities, setCities] = useState([]);
  const [university, setUniversity] = useState([]);
  const [loadingEdu, setLoadingEdu] = useState(false);
  const [uniSp, setUniSp] = useState([]);
  const [loadingReFetch, setLoadingReFetch] = useState(false);
  const [loading, setLoading] = useState("container");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");

  useEffect(() => {
    fetchZip();
    fetchEdu();
    fetchCity();
  }, []);

  const fetchCity = async () => {
    const resCity = await getRefRegion({ province: "all" });
    setCities(resCity.data);
  };

  const fetchZip = async (e, code) => {
    setInputValue(code);
    if (code.length > 0) {
      setOpenSelect(true);
      const resZip = await getRefZip(code || "all");
      setRefZip(resZip.data);
    } else {
      setOpenSelect(false);
    }
  };

  const handleOpenSelect = () => {
    if (inputValue.length > 0) {
      setOpenSelect(true);
    }
  };

  const fetchEdu = async () => {
    const resRef = await Promise.all([
      getRefEdu(),
      getRef({ type: "refUniversity" }),
    ]);
    setRefEdu(resRef[0].data);
    setUniversity(resRef[1].data);
    const resEmail = await localforage.getItem("email");
    const resFullname = await localforage.getItem("fullname");
    const copyForm = { ...form };
    copyForm["email"] = resEmail;
    copyForm["fullname"] = resFullname;
    setForm(copyForm);
    setLoading(false);
  };

  const handleChange = (e) => {
    const copyForm = { ...form };
    copyForm[e.target.name] = e.target.value;
    setForm(copyForm);
  };

  const handleSelectGender = (d) => {
    const copyForm = { ...form };
    copyForm["gender_code"] = d.gender_code;
    setForm(copyForm);
  };

  const handleSelectZip = (d) => {
    const copyForm = { ...form };
    copyForm["id_pos"] = d.id_pos;
    copyForm["zipcode"] = d.kode_pos;
    copyForm["city"] = d.kota;
    copyForm["province"] = d.provinsi;
    setForm(copyForm);
  };

  const handleSelectEdu = async (d) => {
    const copyForm = { ...form };
    copyForm["educational_code"] = d.educational_code;
    setForm(copyForm);
    const res = await getRefUniSP({
      university_id: form.university_id,
      educational_code: d.educational_code,
    });
    setUniSp(res.data);
  };

  const handleDatePicker = (d) => {
    const copyForm = { ...form };
    setTempBD(d);

    const dd = d.getDate();
    const mm = d.getMonth() + 1;
    const yyyy = d.getFullYear();
    copyForm["birth_date"] = `${yyyy}-${mm}-${dd}`;
    setForm(copyForm);
  };

  const handleSubmit = async () => {
    setValidate(true);
    const con =
      !form.email ||
      !form.identity_number ||
      !form.fullname ||
      !form.nickname ||
      !form.password ||
      !form.birth_place ||
      !form.birth_date ||
      !form.gender_code ||
      !form.educational_code ||
      // !form.university ||
      !form.mobile_phone ||
      !form.zipcode ||
      !form.address;
    if (con) return;

    setLoading("buttonRegist");
    const resRegist = await registerForm({ data_personal: form });
    setLoading("");

    if (resRegist.code === 200) {
      setAuthenticated(resRegist.data.token);
      navigate("/v2/user/");
    }
  };

  const handleSelectAutoBirthCity = (val, key) => {
    const copyForm = { ...form };
    copyForm.birth_place = val.city;
    setForm(copyForm);
  };

  const handleSelectAuto = async (val, key) => {
    setForm({ ...form, [key]: val[key] });
    if (key === "university_id") {
      const res = await getRefUniSP({
        university_id: val[key],
        educational_code: form.educational_code,
      });
      setUniSp(res.data);
    } else if (key === "university_study_program_id") {
      setLoadingEdu(true);
      setForm({
        ...form,
        [key]: val[key],
        educational_code: val.educational_code,
        educational_stage: refEdu.filter(
          (edu) => edu.educational_code === val.educational_code
        )[0],
      });
      setTimeout(() => {
        setLoadingEdu(false);
      }, 500);
    }
  };

  const handleReFetchUniSp = async (val) => {
    setLoadingReFetch(true);
    const res = await getRefUniSP({
      university_id: form.university_id,
      study_program_name: val,
      educational_code: form.educational_code,
    });
    setUniSp(res.data);
    setLoadingReFetch(false);
  };

  if (loading === "container") return null;

  return (
    <Stack sx={{ width: "100%", height: "100%", p: 2 }}>
      <Paper
        elevation={isMobile ? 0 : 3}
        sx={{
          width: "100%",
          maxHeight: `calc( 100vh - 4rem )`,
          m: "auto !important",
          borderRadius: "1rem",
          maxWidth: isScreen2000
            ? "1536px"
            : isScreen1400
            ? "1200px"
            : "1024px",
          p: "1rem",
          backgroundColor: "rgba(255, 255, 255, 0.75)",
          boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
        }}
      >
        <Grid container>
          {!isMobile && (
            <Grid
              item
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: `calc( 100vh - 4rem )`,
                backgroundColor: `${theme.palette.primary.main}`,
                borderRadius: "1rem",
                border: `1px solid ${theme.palette.primary.main}`,
              }}
            >
              <Typography variant="h3" sx={{ color: "#ffff" }}>
                Let's get started!
              </Typography>
            </Grid>
          )}
          <Grid item sm={12} md={6} sx={{ pl: "1rem" }}>
            <Stack
              spacing={2}
              direction="column"
              sx={{
                width: "100%",
                maxHeight: `calc( 100vh - 7rem )`,
                overflowY: "auto",
                // pl: 2,
                // pr: 2,
                p: 2,
              }}
            >
              <CustomTextField
                name="email"
                placeholder="Email"
                variant="outlined"
                color="primary"
                hiddenLabel
                fullWidth
                error={validate && !form.email}
                helperText={validate && !form.email && "* Email kosong"}
                disabled
                value={form.email}
                size="small"
              />
              <CustomTextField
                placeholder="No KTP"
                name="identity_number"
                variant="outlined"
                color="primary"
                hiddenLabel
                fullWidth
                error={validate && !form.identity_number}
                helperText={
                  validate && !form.identity_number && "* No KTP harap diisi"
                }
                onChange={handleChange}
                onKeyDown={inputNumberOnly}
                size="small"
              />
              <CustomTextField
                placeholder="Nama Lengkap"
                name="fullname"
                variant="outlined"
                color="primary"
                hiddenLabel
                fullWidth
                error={validate && !form.fullname}
                helperText={
                  validate && !form.fullname && "* Nama lengkap harap diisi"
                }
                onChange={handleChange}
                value={form.fullname}
                size="small"
                sx={{ mb: 1 }}
              />
              <CustomTextField
                placeholder="Nama Panggilan"
                name="nickname"
                variant="outlined"
                color="primary"
                hiddenLabel
                fullWidth
                error={validate && !form.nickname}
                helperText={
                  validate && !form.nickname && "* Nama panggilan harap diisi"
                }
                onChange={handleChange}
                size="small"
                sx={{ mb: 1 }}
              />
              <CustomTextField
                placeholder="Kata Sandi"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                color="primary"
                name="password"
                hiddenLabel
                fullWidth
                error={validate && !form.password}
                helperText={
                  validate && !form.password && "* Kata sandi harap diisi"
                }
                sx={{ mb: 1 }}
                size="small"
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? (
                          <Visibility color="primary" />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <CustomTextField
                placeholder="Kota Kelahiran"
                name="birth_place"
                variant="outlined"
                color="primary"
                hiddenLabel
                fullWidth
                error={validate && !form.birth_place}
                helperText={
                  validate &&
                  !form.birth_place &&
                  "* Kota kelahiran harap diisi"
                }
                onChange={handleChange}
                size="small"
                sx={{ mb: 1 }}
              /> */}
              <CustomAutoComplete
                name="city"
                optionsArr={cities}
                keyLabel={"city"}
                handleSelect={handleSelectAutoBirthCity}
                register="true"
                value={form.birth_place ? { city: form.birth_place } : null}
                placeholder={"Kota Kelahiran"}
              />
              <MobileDatePicker
                hiddenLabel
                value={tempBD}
                DialogProps={{
                  sx: {
                    "& .MuiPaper-root": { backgroundColor: "#ffff !important" },
                  },
                }}
                onChange={handleDatePicker}
                renderInput={(params) => (
                  <CustomTextField
                    placeholder="Tanggal Kelahiran"
                    hiddenLabel
                    variant="outlined"
                    color="primary"
                    size="small"
                    errorValidate={validate && !form.birth_date ? true : false}
                    error={validate && !form.birth_date}
                    helperText={
                      validate &&
                      !form.birth_date &&
                      "* Tanggal kelahiran harap diisi"
                    }
                    sx={{ mb: 1 }}
                    {...params}
                  />
                )}
              />
              <CustomAutoComplete
                name="gender_code"
                optionsArr={[
                  { gender_code: 1, gender_name: "Laki-laki" },
                  { gender_code: 2, gender_name: "Perempuan" },
                ]}
                keyLabel={"gender_name"}
                handleSelect={handleSelectGender}
                validate={validate}
                value={form.gender_code}
                placeholder="Jenis Kelamin"
                register="true"
              />
              <CustomAutoComplete
                name="educational_code"
                optionsArr={refEdu}
                keyLabel={"educational_name"}
                handleSelect={handleSelectEdu}
                validate={validate}
                value={form.educational_code}
                placeholder="Pendidikan Terakhir"
                register="true"
              />
              {form.educational_code === "001" ||
              form.educational_code === "002" ||
              form.educational_code === "01" ||
              form.educational_code === "02" ||
              form.educational_code === "03" ||
              form.educational_code === "031" ||
              form.educational_code === "04" ||
              form.educational_code === "041" ||
              form.educational_code === "042" ? (
                <>
                  <CustomAutoComplete
                    name="university_id"
                    optionsArr={university}
                    keyLabel={"university_name"}
                    handleSelect={handleSelectAuto}
                    register="true"
                    placeholder={"Asal sekolah/universitas (saat ini)"}
                    value={
                      university.filter(
                        (u) => u.university_id === form.university_id
                      )[0]
                    }
                  />
                  <CustomAutoComplete
                    name="university_study_program_id"
                    optionsArr={loadingReFetch ? [] : uniSp}
                    loading={loadingReFetch}
                    keyLabel={"sp_stage_name"}
                    handleSelect={handleSelectAuto}
                    fetchData={handleReFetchUniSp}
                    register="true"
                    placeholder={"Jurusan/Fakultas"}
                    value={
                      uniSp.filter(
                        (u) =>
                          u.university_study_program_id ===
                          form.university_study_program_id
                      )[0]
                    }
                  />
                </>
              ) : (
                <CustomTextField
                  placeholder="Asal sekolah/universitas (saat ini)"
                  name="university"
                  variant="outlined"
                  color="primary"
                  hiddenLabel
                  fullWidth
                  error={validate && !form.university}
                  helperText={
                    validate &&
                    !form.university &&
                    "* Asal sekolah/universitas harap diisi"
                  }
                  onChange={handleChange}
                  size="small"
                  sx={{ mb: 1 }}
                />
              )}
              <CustomTextField
                placeholder="No Handphone"
                name="mobile_phone"
                variant="outlined"
                color="primary"
                hiddenLabel
                fullWidth
                error={validate && !form.mobile_phone}
                helperText={
                  validate && !form.mobile_phone && "* No handphone harap diisi"
                }
                onChange={handleChange}
                size="small"
                sx={{ mb: 1 }}
              />
              <Autocomplete
                open={openSelect}
                onOpen={handleOpenSelect}
                onClose={() => setOpenSelect(false)}
                id="zipcode"
                options={refZip}
                fullWidth
                size="small"
                getOptionLabel={(option) => option.pos_name}
                onInputChange={fetchZip}
                onChange={(e, val) => handleSelectZip(val)}
                renderInput={(params) => (
                  <CustomTextField
                    hiddenLabel
                    placeholder="Ketikkan Kode Pos"
                    error={validate && !form.zipcode}
                    helperText={
                      validate && !form.zipcode && "* Kode pos harap diisi"
                    }
                    variant="outlined"
                    color="primary"
                    {...params}
                  />
                )}
              />
              <CustomTextField
                placeholder="Alamat"
                name="address"
                variant="outlined"
                color="primary"
                hiddenLabel
                fullWidth
                error={validate && !form.address}
                helperText={validate && !form.address && "* Alamat harap diisi"}
                multiline
                rows={4}
                sx={{ mb: 3 }}
                size="small"
                onChange={handleChange}
              />
              <Stack
                direction="row"
                justifyContent="space-evenly"
                sx={{ width: "100%" }}
              >
                <Button
                  size="medium"
                  fullWidth
                  variant="outlined"
                  sx={{ mr: 2, mt: 2 }}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  fullWidth
                  variant="contained"
                  sx={{ ml: 2, mt: 2 }}
                  onClick={handleSubmit}
                  disabled={loading === "buttonRegist"}
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
};

export default Register;
