import React from "react";
import { Button, Typography, Stack, Divider, IconButton } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import CustomInput from "../CustomInput";
// import "src/hoc/css/form.css";
import { Delete, Edit } from "@mui/icons-material";

const EducationForm = ({
  isEduOpen,
  eduDatas,
  eduData,
  handleOpenEdu,
  handleEditEdu,
  handleDeleteEdu,
  handleBlurEdu,
  handleDateChangeEdu,
  handleSaveEdu,
}) => {
  return (
    <Stack sx={{ mb: "1rem" }}>
      <Stack direction="row" justifyContent={"space-between"}>
        <Typography sx={{ fontWeight: 600, m: "auto 0" }}>Education</Typography>
        <Button
          variant="outlined"
          startIcon={<AddRoundedIcon />}
          onClick={handleOpenEdu}
        >
          Add
        </Button>
      </Stack>
      {isEduOpen && (
        <Stack
          sx={{
            backgroundColor: "#E1E6EB",
            p: "2rem",
            borderRadius: "10px",
            mt: "1rem",
          }}
        >
          <CustomInput
            label="Institution name"
            name="institution_name"
            defaultValue={eduData.institution_name}
            onBlur={handleBlurEdu}
          />
          <CustomInput
            label="Field of study"
            name="field_of_study"
            defaultValue={eduData.field_of_study}
            onBlur={handleBlurEdu}
          />
          <CustomInput
            label="Degree"
            name="degree"
            defaultValue={eduData.degree}
            onBlur={handleBlurEdu}
          />
          <Stack direction="row" spacing={4}>
            <div>
              <Typography sx={{ fontWeight: 600 }}>Start date</Typography>
              <DatePicker
                disableFuture
                label=""
                // value={new Date(eduData.starts_at)}
                onChange={(d) => handleDateChangeEdu(d, "starts_at")}
                renderInput={(params) => <CustomInput {...params} />}
              />
            </div>
            <div>
              <Typography sx={{ fontWeight: 600 }}>End date</Typography>
              <DatePicker
                disableFuture
                label=""
                // value={new Date(eduData.ends_at)}
                onChange={(d) => handleDateChangeEdu(d, "ends_at")}
                renderInput={(params) => <CustomInput {...params} />}
              />
            </div>
          </Stack>
          <Divider sx={{ m: "1.5rem 0" }} />
          <Stack direction="row" spacing={2} justifyContent={"end"}>
            <Button onClick={handleOpenEdu}>Cancel</Button>
            <Button variant="contained" onClick={handleSaveEdu}>
              Save
            </Button>
          </Stack>
        </Stack>
      )}
      {eduDatas.map((edu) => (
        <Stack
          sx={{
            backgroundColor: "#F2F4F5",
            m: "0.5rem 0",
            p: "0.5rem 0",
            position: "relative",
          }}
          key={edu.tempId}
        >
          <Stack direction="row">
            <Typography
              sx={{ fontWeight: 600, width: "160px", textAlign: "right" }}
            >
              Institution name
            </Typography>
            <Typography sx={{ ml: "1rem" }}>{edu.institution_name}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography
              sx={{ fontWeight: 600, width: "160px", textAlign: "right" }}
            >
              Field of study
            </Typography>
            <Typography sx={{ ml: "1rem" }}>{edu.field_of_study}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography
              sx={{ fontWeight: 600, width: "160px", textAlign: "right" }}
            >
              Degree
            </Typography>
            <Typography sx={{ ml: "1rem" }}>{edu.degree}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography
              sx={{ fontWeight: 600, width: "160px", textAlign: "right" }}
            >
              Period
            </Typography>
            <Typography sx={{ ml: "1rem" }}>
              {edu.starts_at} - {edu.ends_at}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            sx={{ position: "absolute", right: "16px" }}
            spacing={2}
          >
            <IconButton sx={{ p: 0 }} onClick={() => handleEditEdu(edu)}>
              <Edit />
            </IconButton>
            <IconButton sx={{ p: 0 }} onClick={() => handleDeleteEdu(edu)}>
              <Delete />
            </IconButton>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default EducationForm;
