import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import localforage from "localforage";
import { useMediaQuery, Grid, useTheme, Stack } from "@mui/material";
import { useNavigate } from "react-router";
import { galleryAPI } from "src/redux/actions";
import CardGal01 from "../Cards/CardGal01";
import { dataGal, domain } from "./data";
import "src/App.css";
import { BoxTitle } from "../BoxTitleLanding";

const SectionGalery = ({
  id,
  libPages,
  contentPreview,
  contentHoverId,
  isChange,
}) => {
  const navigate = useNavigate();
  const isScreen500 = useMediaQuery("(min-width:500px)");
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen700 = useMediaQuery("(min-width:700px)");
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const [objContent, setObjContent] = useState({});
  const [userRole, setUserRole] = useState("");
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [contentPreview, isChange, isScreen1400, isScreen900]);

  const fetchData = async () => {
    const arrGal = [{ ...dataGal }, { ...dataGal }, { ...dataGal }];
    setGallery(arrGal);

    const resRole = await localforage.getItem("userRole");
    setUserRole(resRole);

    const resContent = contentPreview.length
      ? contentPreview
      : await localforage.getItem("content");
    const arr = resContent.filter((d) => d.id === id);
    setObjContent(arr[0]);

    const data = await galleryAPI("get", { domain });
    if (data.data) {
      if (isScreen1400)
        setGallery(data.data.length > 1 ? data.data.slice(0, 4) : data.data);
      else if (isScreen900)
        setGallery(data.data.length > 1 ? data.data.slice(0, 3) : data.data);
      else if (!isScreen900)
        setGallery(data.data.length > 1 ? data.data.slice(0, 4) : data.data);
      setLoading(false);
    }
  };

  const handleMore = () => {
    navigate(`/v2/user/${libPages[3]}`);
  };

  return (
    <Grid
      container
      component="section"
      className="slide-in-left"
      // sx={{
      //   border: "1px solid red",
      //   // m: "3rem auto"
      // }}
    >
      <Grid
        item
        container
        sx={{
          maxWidth:
            userRole === "5"
              ? "500px"
              : isScreen2000
              ? "1536px"
              : isScreen1700
              ? "1440px"
              : isScreen1400
              ? "1200px"
              : isScreen1200
              ? "1024px"
              : "900px",
          m: "0 auto",
          p: 2,
          pb: userRole === "5" ? 0 : 2,
          // pl: isScreen600 && userRole !== "5" ? 3 : 2,
          // pr: isScreen600 && userRole !== "5" ? 3 : 2,
          pl: userRole === "5" ? 2 : isScreen700 ? 3 : 0,
          pr: userRole === "5" ? 2 : isScreen700 ? 3 : 0,
          border: contentHoverId === id ? "1px dashed black" : null,
        }}
      >
        <BoxTitle
          objContent={objContent}
          userRole={userRole}
          handleMore={handleMore}
        />
        {!isScreen900 && (
          <Stack
            direction="row"
            spacing={isScreen600 ? 3 : 2}
            sx={{ pt: 2, overflowY: "auto" }}
          >
            {gallery.map((v, i) => {
              return (
                <CardGal01
                  v={v}
                  key={i}
                  list="row"
                  home="true"
                  loading={loading}
                  i={i}
                  z={gallery.length - 1}
                />
              );
            })}
          </Stack>
        )}
        {isScreen900 && (
          <Grid
            item
            container
            spacing={
              userRole === "5" ? 2 : isScreen600 ? 3 : isScreen500 ? 2 : 3
            }
            sx={{
              pt: userRole === "5" ? "0.9rem" : 2,
              // mb: userRole === "5" ? 0 : 1,
            }}
          >
            {gallery.map((v, i) => (
              <CardGal01
                v={v}
                key={i}
                loading={loading}
                userRole={userRole}
                home="true"
              />
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  libPages: state.nav.libPages,
  contentPreview: state.preview.contentPreview,
  contentHoverId: state.preview.contentHoverId,
  isChange: state.preview.isChange,
});

export default connect(mapStateToProps)(SectionGalery);
