export const AUTHENTICATED = "AUTHENTICATED";
export const DEAUTHENTICATED = "DEAUTHENTICATED";
export const SET_OPEN_LOGIN = "SET_OPEN_LOGIN";
export const SET_MIDDLEWARE_APPS = "SET_MIDDLEWARE_APPS";
export const SET_SNACKBAR_OPEN = "SET_SNACKBAR_OPEN";
export const SET_SNACKBAR_CLOSE = "SET_SNACKBAR_CLOSE";
export const SET_IS_ACTIVE_NAVBAR = "SET_IS_ACTIVE_NAVBAR";
export const SET_LIB_PAGES = "SET_LIB_PAGES";
export const SET_NAV_PREVIEW = "SET_NAV_PREVIEW";
export const SET_LOGO_PREVIEW = "SET_LOGO_PREVIEW";
export const SET_THEME_PREVIEW = "SET_THEME_PREVIEW";
export const SET_CONTENT_PREVIEW = "SET_CONTENT_PREVIEW";
export const SET_IS_PREVIEW = "SET_IS_PREVIEW";
export const SET_CONTENT_HOVER_ID = "SET_CONTENT_HOVER_ID";
export const SET_PAGE_PREVIEW = "SET_PAGE_PREVIEW";
export const SET_ARTICLE_PREVIEW = "SET_ARTICLE_PREVIEW";
export const SET_TESTI_PREVIEW = "SET_TESTI_PREVIEW";
export const SET_GAL_PREVIEW = "SET_GAL_PREVIEW";
export const SET_BTN_PREVIEW = "SET_BTN_PREVIEW";
// export const SET_IS_CHANGE_ART = "SET_IS_CHANGE_ART";
// export const SET_IS_CHANGE_GAL = "SET_IS_CHANGE_GAL";
export const SET_IS_CHANGE = "SET_IS_CHANGE";
