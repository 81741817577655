import React, { useState, useEffect } from "react";
import {
  galleryAPI,
  refTags,
  setIsActiveNavbar,
  setIsPreview,
} from "src/redux/actions";
import { connect } from "react-redux";
import localforage from "localforage";
import { useTheme, useMediaQuery, Grid, Chip } from "@mui/material";
import { domain, dataGal } from "src/components/Layout/data";
import CardGal01 from "src/components/Cards/CardGal01";
import "src/App.css";

const Index = () => {
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [refTagsGal, setRefTagsGal] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [tagName, setTagName] = useState("All Tags");

  useEffect(() => {
    checkParamsUrl();
    setIsPreview(false);
    fetchData();
  }, []);

  const checkParamsUrl = async () => {
    const resRole = await localforage.getItem("userRole");
    setUserRole(resRole);
    setIsActiveNavbar("Galery");
  };

  const fetchData = async () => {
    const arrGal = [{ ...dataGal }, { ...dataGal }, { ...dataGal }];
    setGallery(arrGal);

    const arrTags = [];
    const obj = { tag_id: "123", tag_name: "All Tags" };
    arrTags.push(obj);

    // const arrTags = [
    //   { tag_id: "123", tag_name: "All Tags" },
    //   { tag_id: "1678967419517331", tag_name: "Stunting" },
    //   {
    //     tag_id: "1678967449729758",
    //     tag_name: "Tuberkulosis",
    //   },
    //   { tag_id: "1678967475151368", tag_name: "Kesehatan Mental" },
    //   { tag_id: "123", tag_name: "All Tags" },
    //   { tag_id: "1678967419517331", tag_name: "Stunting" },
    //   {
    //     tag_id: "1678967449729758",
    //     tag_name: "Tuberkulosis",
    //   },
    //   { tag_id: "1678967475151368", tag_name: "Kesehatan Mental" },
    // ];

    const resTags = await refTags("get", { domain });
    const resGal = await galleryAPI("get", { domain });

    if (resTags.data) setRefTagsGal([...arrTags, ...resTags.data]);
    if (resGal.data) {
      setGallery(resGal.data);
      setLoading(false);
    }
  };

  const fetchDataWithName = async (name) => {
    setTagName(name);
  };

  const theme = useTheme();
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");

  return (
    <Grid
      container
      component="main"
      sx={{
        maxWidth: isScreen2000
          ? "1536px"
          : isScreen1700
          ? "1440px"
          : isScreen1400
          ? "1200px"
          : isScreen1200
          ? "1024px"
          : "900px",
        m: "auto",
        p: 3,
        pt: isScreen600 ? 3 : 2,
        pb: isScreen600 ? 3 : 2,
      }}
    >
      <Grid item container spacing={1} sx={{ mt: 1, mb: 0 }}>
        {refTagsGal.map((v) => (
          <Grid item key={v.tag_id}>
            <Chip
              label={v.tag_name}
              color="primary"
              sx={{
                p: 1,
                backgroundColor:
                  tagName === v.tag_name
                    ? `${theme.palette.primary.main}`
                    : "#f5f5f5",
                color:
                  tagName === v.tag_name
                    ? "#ffff"
                    : `${theme.palette.text.tertiary}`,
                transition: "all linear .4s",
              }}
              onClick={() => fetchDataWithName(v.tag_name)}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item container spacing={3} sx={{ mt: 0 }}>
        {gallery.map((v, i) => (
          <CardGal01 v={v} key={i} loading={loading} />
        ))}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  // libPages: state.nav.libPages,
});

export default connect(mapStateToProps)(Index);
