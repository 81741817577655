import React from "react";
import { connect } from "react-redux";
import {
  Box,
  Paper,
  Typography,
  useTheme,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import { ArrowDropDown, ArrowRight, Edit } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import "src/App.css";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "20px !important",
  },
}));

const Panel4 = ({ openPanel4, setOpenPanel4, setOpenModal, setDataId }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Typography
        component="p"
        variant="body2"
        sx={{
          display: "flex",
          alignItems: "center",
          fontWeight: 600,
          transition: "all linear .4s",
          pt: 1,
          pb: 1,
          "&:hover": { cursor: "pointer" },
        }}
        onClick={() => setOpenPanel4(!openPanel4)}
      >
        {openPanel4 && (
          <ArrowDropDown sx={{ mr: 1 }} className={classes.root} />
        )}
        {!openPanel4 && <ArrowRight sx={{ mr: 1 }} className={classes.root} />}
        Buttons
      </Typography>
      {openPanel4 && (
        <>
          <Paper
            // key={i}
            elevation={2}
            sx={{
              width: "325px",
              mt: 1,
              // mt: i === 0 ? 1 : 0,
              mb: 1,
              p: 1,
              pl: 2,
              pr: 2,
              backgroundColor: "#ffff",
              borderRadius: "1rem",
              alignItems: "center",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Card Product
              </Typography>
              <Box>
                <Tooltip title={"Edit buttons"}>
                  <IconButton
                    sx={{ color: `${theme.palette.blue.main}` }}
                    onClick={() => {
                      setOpenModal(true);
                      setDataId("btn");
                    }}
                  >
                    <Edit className={classes.root} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          </Paper>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  contentPreview: state.preview.contentPreview,
  contentHoverId: state.preview.contentHoverId,
});

export default connect(mapStateToProps)(Panel4);
