import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  useTheme,
  Paper,
  Stack,
  Tooltip,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import image from "src/assets/img/images7.jpg";
import { parsedDate } from "src/functions/parsedDate";
import "src/App.css";

const CardGal01 = ({
  v, i, z,
  loading,
  userRole,
  home,
  list,
  displayHover,
  setDisplayHover,
}) => {
  const isScreen500 = useMediaQuery("(min-width:500px)");
  const isScreen700 = useMediaQuery("(min-width:700px)");
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (v) => {
    setOpen(v.gallery_id);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        maxWidth:
          isScreen1400 && userRole !== "5"
            ? "25% !important"
            : isScreen900
            ? "33.3% !important"
            : isScreen500 && !list
            ? "50% !important"
            : !isScreen500 && !list
            ? "100% !important"
            : null,

        minWidth:
          isScreen700 && list
            ? "40vw !important"
            : isScreen500 && list
            ? "60vw !important"
            : !isScreen500 && list
            ? "70vw !important"
            : null,
        ml: !isScreen700 && i === 0 ? 2 : null,
        mr: !isScreen700 && i === z ? 2 : null,
      }}
    >
      <Paper
        // elevation={list || home ? 0 : 2}
        elevation={0}
        sx={{
          backgroundColor: "#ffff",
          border: "1px solid lightgrey",
          borderRadius: userRole === "5" ? "0.5rem" : "1rem", // 1rem
          cursor: "default",
          "&:hover": {
            boxShadow: isScreen1024
              ? "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px"
              : "none",
          },
        }}
      >
        {loading && (
          <Skeleton
            variant="rectangular"
            sx={{
              height: "10rem",
              borderRadius: "1rem",
            }}
          />
        )}

        {!loading && (
          <>
            <Box
              className="box-image-zoom"
              sx={{ aspectRatio: home ? "16/9" : "4/3" }}
            >
              <img
                src={v?.photo}
                alt={v?.gallery_id}
                style={{
                  backgroundColor: "#f5f5f5",
                  width: "100%",
                  height: "100%",
                  objectPosition: "center",
                  objectFit: "cover",
                  borderRadius: userRole === "5" ? "0.5rem" : "1rem",

                  border:
                    userRole === "5" ? "5px solid #ffff" : "10px solid #ffff", //
                  borderBottom: "none", //
                  borderBottomLeftRadius: "0.5rem",
                  borderBottomRightRadius: " 0.5rem",
                }}
              />
            </Box>
            {v.name && (
              <Tooltip
                title={`${v.name} - ${parsedDate(v.created_at)}`}
                placement={isScreen1024 ? "top-start" : "bottom-start"}
                arrow
                onClose={handleTooltipClose}
                open={v.gallery_id === open ? true : false}
              >
                <Stack
                  sx={{
                    p: userRole === "5" ? "0.25rem" : 1,
                    pl: userRole === "5" ? 1 : 2,
                    pr: userRole === "5" ? 1 : 2,
                  }}
                  onClick={() => handleTooltipOpen(v)}
                >
                  <Typography
                    noWrap={true}
                    variant={userRole === "5" ? "caption2" : "body2"}
                    sx={{
                      fontWeight: 600,
                      textAlign: "center",
                      fontSize: userRole === "5" ? 9 : null,
                    }}
                  >
                    {v.name}
                  </Typography>
                </Stack>
              </Tooltip>
            )}
          </>
        )}
      </Paper>
    </Grid>
    // <Grid
    //   item
    //   sx={{
    //     // mt: userRole === "5" ? "0.75rem" : "1.75rem",
    //     // mb: userRole === "5" ? "0.75rem" : "1.75rem",
    //     ml:
    //       userRole === "5" && i !== 0
    //         ? `${theme.spacing(2)}`
    //         : userRole !== "5" && i !== 0
    //         ? `${theme.spacing(3)}`
    //         : null,
    //   }}
    // >
    //   <Paper
    //     elevation={2}
    //     sx={{
    //       backgroundColor: "#ffff",
    //       borderRadius: userRole === "5" ? "0.5rem" : "1rem",
    //       position: "relative",
    //       transition: "all linear .4s",
    //       "&:hover": {
    //         cursor: "pointer",
    //       },
    //     }}
    //     onMouseOver={() => setDisplayHover(v.publish_id)}
    //     onMouseOut={() => setDisplayHover("")}
    //   >
    //     <Box
    //       sx={{
    //         backgroundImage: `url(${image})`,
    //         backgroundSize: "cover",
    //         // height: "auto",
    //         height: userRole === "5" ? "4.5rem" : "10rem",
    //         borderRadius: userRole === "5" ? "0.5rem" : "1rem",
    //         position: "relative",
    //       }}
    //     ></Box>
    //     {/* <img
    //       src={image}
    //       style={{
    //         width: "100%",
    //         height: "auto",
    //         borderRadius: userRole === "5" ? "0.5rem" : "1rem",
    //         position: "relative",
    //       }}
    //     /> */}
    //     <Stack
    //       direction="column"
    //       justifyContent="center"
    //       alignItems="center"
    //       sx={{
    //         display: displayHover === v.publish_id ? "flex" : "none",
    //         position: "absolute",
    //         left: 0,
    //         top: 0,
    //         borderRadius: userRole === "5" ? "0.5rem" : "1rem",
    //         width: "100%",
    //         height: "100%",
    //         p: 2,
    //         backgroundColor: "rgba(0, 0, 0, 0.5)",
    //       }}
    //     >
    //       <Typography
    //         variant={userRole === "5" ? "caption2" : "body2"}
    //         sx={{
    //           fontSize: userRole === "5" ? 8 : null,
    //           overflowY: "auto",
    //           color: "#ffff",
    //           textAlign: "center",
    //         }}
    //       >
    //         {v.publish_name}
    //       </Typography>
    //       <Typography
    //         variant={userRole === "5" ? "caption2" : "caption"}
    //         sx={{
    //           mt: userRole === "5" ? "0.15rem" : 1,
    //           fontSize: userRole === "5" ? 6 : null,
    //           color: "#ffff",
    //         }}
    //       >
    //         {parsedDate(v.created_at)}
    //       </Typography>
    //     </Stack>
    //   </Paper>
    // </Grid>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(CardGal01);
