import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import localforage from "localforage";
import {
  Box,
  Typography,
  useMediaQuery,
  Grid,
  useTheme,
  Paper,
  Stack,
  Button,
  Chip,
} from "@mui/material";
import {
  KeyboardDoubleArrowRight,
  PersonOutline,
  SellOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import { getPublish } from "src/redux/actions";
import ModalConfirm from "../ModalConfirm";
import CardPro02 from "../Cards/CardPro02";
import image from "src/assets/img/images7.jpg";
import { makeStyles } from "@mui/styles";
import { dataPub } from "./data";
import "src/App.css";
import { BoxTitle } from "../BoxTitleLanding";

const SectionProduct = ({ id, libPages, contentPreview, contentHoverId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isScreen500 = useMediaQuery("(min-width:500px)");
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen700 = useMediaQuery("(min-width:700px)");
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1536 = useMediaQuery("(min-width:1536px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const [openModal, setOpenModal] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [objContent, setObjContent] = useState({});
  const [userRole, setUserRole] = useState("");
  const [publish, setPublish] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [contentPreview, isScreen1536, isScreen1200, isScreen700, isScreen500]);

  const fetchData = async () => {
    // when load the data article
    const arrPublish = [{ ...dataPub }, { ...dataPub }, { ...dataPub }];
    setPublish(arrPublish);

    const resRole = await localforage.getItem("userRole");
    setUserRole(resRole);

    const resContent = contentPreview.length
      ? contentPreview
      : await localforage.getItem("content");
    const arr = resContent.filter((d) => d.id === id);
    setObjContent(arr[0]);

    const data = await getPublish();
    if (data.data) {
      if (isScreen1536 && userRole !== "5")
        setPublish(data.data.length > 1 ? data.data.slice(0, 5) : data.data);
      else if (isScreen1200)
        setPublish(data.data.length > 1 ? data.data.slice(0, 4) : data.data);
      else if (isScreen700)
        setPublish(data.data.length > 1 ? data.data.slice(0, 3) : data.data);
      else if (isScreen500)
        setPublish(data.data.length > 1 ? data.data.slice(0, 2) : data.data);
      else if (!isScreen500)
        setPublish(data.data.length > 1 ? data.data.slice(0, 4) : data.data);
      setLoading(false);
    }
  };

  const handleMore = () => {
    navigate(`/v2/user/${libPages[0]}`);
  };

  // const handleSelectCard = (v) => {
  //   if (v.publish_id.indexOf("1a2b3") !== -1) {
  //     return;
  //   } else {
  //     setDataModal(v);
  //     setOpenModal(true);
  //   }
  // };

  return (
    <Grid
      container
      component="section"
      className="slide-in-left"
      // sx={{
      //   border: "1px solid red",
      //   // m: "3rem auto"
      // }}
    >
      <Grid
        item
        container
        // sx={{
        //   maxWidth:
        //     userRole === "5"
        //       ? "500px"
        //       : isScreen2000
        //       ? "1536px"
        //       : isScreen1400
        //       ? "1200px"
        //       : "1024px",
        //   m: "1rem auto",
        //   mt: userRole === "5" ? 0 : "1rem",
        //   // backgroundColor: "red",
        //   // p: isScreen1024
        //   //   ? "2rem 0 1rem 0"
        //   //   : isScreen600
        //   //   ? "2rem 2rem 1rem 2rem"
        //   //   : "1rem 1rem 0 1rem",
        //   // border: "1px solid red",
        //   p: userRole === "5" ? 1 : 2,
        //   border: contentHoverId === id ? "1px dashed black" : null,
        //   // pt: userRole === "5" ? 1 : 2,
        // }}
        sx={{
          maxWidth:
            userRole === "5"
              ? "500px"
              : isScreen2000
              ? "1536px"
              : isScreen1700
              ? "1440px"
              : isScreen1400
              ? "1200px"
              : isScreen1200
              ? "1024px"
              : "900px",
          m: "0 auto",
          p: 2,
          pl: userRole === "5" ? 2 : isScreen700 ? 3 : 0,
          // pl: isScreen700 && userRole !== "5" ? 3 :isScreen700?: 0,
          // pr: isScreen600 && userRole !== "5" ? 3 : 2,
          pr: userRole === "5" ? 2 : isScreen700 ? 3 : 0,
          pb: userRole === "5" ? 0 : 2,
          pt: userRole === "5" ? 0 : 2,
          border: contentHoverId === id ? "1px dashed black" : null,
          // border: "1px solid black",
          // backgroundColor: "yellow",
        }}
      >
        {/* <BoxTitle
          objContent={objContent}
          userRole={userRole}
          handleMore={handleMore}
        /> */}

        <BoxTitle
          objContent={objContent}
          userRole={userRole}
          handleMore={handleMore}
        />

        {!isScreen700 && (
          <Stack
            direction="row"
            spacing={isScreen600 ? 3 : 2}
            sx={{
              pt: 2,
              pb: 1,
              overflowX: "auto",
              width: "100%",
            }}
          >
            {publish.map((v, i) => {
              return (
                <CardPro02
                  v={v}
                  key={i}
                  i={i}
                  z={publish.length - 1}
                  home="true"
                  loading={loading}
                />
              );
            })}
          </Stack>
        )}
        {isScreen700 && (
          <Grid
            item
            container
            spacing={
              userRole === "5" ? 2 : isScreen600 ? 3 : isScreen500 ? 2 : 3
            }
            sx={{
              pt: userRole === "5" ? "0.9rem" : "1rem",
              // mb: userRole === "5" ? 0 : 1,
            }}
          >
            {publish.map((v, i) => (
              <CardPro02
                v={v}
                key={i}
                home="true"
                userRole={userRole}
                loading={loading}
              />
            ))}
          </Grid>
        )}

        {/* <Grid
          item
          container
          spacing={userRole === "5" ? 2 : 3}
          sx={{ pt: "0.75rem" }}
        >
          {publish
            .slice(0, isScreen1536 && userRole !== "5" ? 5 : 4)
            .map((v, i) => {
              return (
                <CardPro02
                  v={v}
                  key={i}
                  userRole={userRole}
                  // handleSelectCard={handleSelectCard}
                />
              );
            })} */}
        {/* <ModalConfirm
            open={openModal}
            onClose={() => setOpenModal(false)}
            data={dataModal}
          /> */}
        {/* </Grid> */}
      </Grid>
      {/*  
      {isScreen1200 && (
        <Grid
          item
          container
          sx={
            {
              // backgroundColor: `${theme.palette.primary.main}`,
              // backgroundColor: "rgba(255, 84, 0, 0.5)",
              // backgroundImage:
              //   "linear-gradient(to bottom, rgba(255, 84, 0, 0.3), rgba(255, 84, 0, 0.7))",
              // display: "flex",
              // justifyContent: "space-evenly",
            }
          }
        >
          {["1", "2", "3", "4"].map((v, i) => {
            return (
              <Card
                v={v}
                i={i}
                product={titleMenu.toLowerCase().split(" ").join("")}
              />
            );
          })}
        </Grid>
      )}
      {!isScreen1200 && isScreen1024 && (
        <Grid item container sx={{}}>
          {["1", "2", "3"].map((v, i) => {
            return (
              <Card
                v={v}
                i={i}
                product={titleMenu.toLowerCase().split(" ").join("")}
              />
            );
          })}
        </Grid>
      )}
      {!isScreen1024 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            overflowX: "scroll",
            overflow: "-moz-scrollbars-none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": { width: "0px !important" },
          }}
        >
          {["1", "2", "3", "4"].map((v, i) => {
            return (
              <Card
                v={v}
                i={i}
                product={titleMenu.toLowerCase().split(" ").join("")}
              />
            );
          })}
        </Box>
      )} */}
    </Grid>
  );
};

// const BoxTitle = ({ objContent, userRole, handleMore }) => {
//   const theme = useTheme();
//   return (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         width: "100%",
//         alignItems:
//           objContent.alignTitle === "left"
//             ? "flex-start"
//             : objContent.alignTitle === "right"
//             ? "flex-end"
//             : "center",
//       }}
//     >
//       <Typography
//         variant={userRole === "5" ? "caption" : "h4"}
//         sx={{
//           fontWeight: 700,
//           textAlign:
//             objContent.alignTitle === "left"
//               ? "start"
//               : objContent.alignTitle === "right"
//               ? "end"
//               : "center",
//         }}
//       >
//         {objContent.title}
//       </Typography>
//       <Box
//         sx={{
//           mb: userRole === "5" ? null : "1rem",
//           color: `${theme.palette.primary.main}`,
//           "&:hover": {
//             textDecoration: "underline",
//             cursor: "pointer",
//           },
//         }}
//         onClick={userRole === "5" ? null : handleMore}
//       >
//         <Typography
//           variant={userRole === "5" ? "caption2" : "body2"}
//           sx={{
//             textAlign:
//               objContent.alignTitle === "left"
//                 ? "start"
//                 : objContent.alignTitle === "right"
//                 ? "end"
//                 : "center",

//             display: "flex",
//             alignItems: "center",
//           }}
//         >
//           Lihat lebih banyak
//           <KeyboardDoubleArrowRight
//             sx={{ ml: userRole === "5" ? "0.25rem" : 1 }}
//           />
//         </Typography>
//       </Box>
//       {/* <Typography
//         variant="span"
//         sx={{
//           width: userRole === "5" ? "25px" : "50px",
//           backgroundColor: `${theme.palette.primary.main}`,
//           height: userRole === "5" ? "4px" : "7.5px",
//           borderRadius: "1rem",
//           mt: "1rem",
//           mb: "1rem",
//         }}
//       ></Typography> */}
//     </Box>
//   );
// };

const mapStateToProps = (state) => ({
  libPages: state.nav.libPages,
  contentPreview: state.preview.contentPreview,
  contentHoverId: state.preview.contentHoverId,
});

export default connect(mapStateToProps)(SectionProduct);
