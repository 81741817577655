import React, { useState, useEffect } from "react";
import localforage from "localforage";
import { connect } from "react-redux";
import {
  setBtnPreview,
  setContentPreview,
  updateInfoInstitution,
  uploadFoto,
} from "src/redux/actions";
import {
  Box,
  Button,
  Stack,
  Typography,
  styled,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import {
  ComponentHero01,
  ComponentHero02,
  ComponentDes,
  ComponentCard,
  InputButtons,
} from "./ComponentGroup";
import { domain } from "src/components/Layout/data";
import { snackbarFunc } from "src/functions/snackbarFunc";
import "src/App.css";

const CustomCircularProgress = styled(CircularProgress)(({ theme }) => ({
  "&.MuiCircularProgress-root": {
    height: "15px !important",
    width: "15px !important",
    display: "flex",
    color: `${theme.palette.blue.main}`,
  },
}));

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const ModalContent = ({ open, onClose, dataId, contentPreview }) => {
  const [form, setForm] = useState({});
  const [buttons, setButtons] = useState({});
  const [image, setImage] = useState(null);
  const [bgImage, setBgImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [bgImagePreview, setBgImagePreview] = useState("");
  const [validateSize, setValidateSize] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setImagePreview("");
    dataId !== "btn" && getData();
    dataId === "btn" && getDataButtons();
  }, [dataId, contentPreview]);

  const getData = () => {
    const arr = contentPreview.filter((d) => d.id === dataId);
    setForm({ ...arr[0] });
  };

  const getDataButtons = async () => {
    const resButtons = await localforage.getItem("buttons");
    setButtons(resButtons);
  };

  const handleChange = (e) => {
    const copyObject = dataId === "btn" ? { ...buttons } : { ...form };
    copyObject[e.target.name] = e.target.value;
    dataId === "btn" ? setButtons(copyObject) : setForm(copyObject);
  };

  const handleChangeImage = (e) => {
    if (e.target.files[0].size >= 1000000) {
      setValidateSize("img");
      return;
    }
    setValidateSize(false);
    setImage(e.target.files[0]);
    setTimeout(() => {
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    }, 500);
  };

  const handleChangeBgImage = (e) => {
    if (e.target.files[0].size >= 1000000) {
      setValidateSize("bg");
      return;
    }
    setValidateSize(false);
    setBgImage(e.target.files[0]);
    setTimeout(() => {
      setBgImagePreview(URL.createObjectURL(e.target.files[0]));
    }, 500);
  };

  const handleDelete = (str) => {
    if (str === "img") {
      setImagePreview("");
      setImage(null);
    } else {
      setBgImagePreview("");
      setBgImage(null);
    }
    const arr = [...contentPreview];
    let idx = arr.findIndex((obj) => obj.id === dataId);
    str === "img" ? (form.img = "") : (form.bgImg = "");
    arr[idx] = form;
    // console.log(arr);
    setContentPreview(arr);
  };

  const handleUploadImage = async () => {
    const arr = [...contentPreview];
    let idx = arr.findIndex((obj) => obj.id === dataId);
    const token = await localforage.getItem("token");

    if (image) {
      let formData = new FormData();
      formData.append("token", token);
      formData.append("image", image);
      formData.append("domain", domain);
      const resImage = await uploadFoto(formData);

      if (resImage.code === 200) {
        form.img = resImage.data.path;
        arr[idx] = form;
      }
    }
    if (bgImage) {
      let formData = new FormData();
      formData.append("token", token);
      formData.append("image", bgImage);
      formData.append("domain", domain);
      const resBg = await uploadFoto(formData);

      if (resBg.code === 200) {
        form.bgImg = resBg.data.path;
        arr[idx] = form;
      }
    }
    setContentPreview(arr);
    setLoading(false);
    onClose();
  };

  const handleUploadButtons = async () => {
    const token = await localforage.getItem("token");
    const jsonBtn = JSON.stringify(buttons);
    const resBtn = await updateInfoInstitution("buttons", {
      token,
      domain,
      buttons: jsonBtn,
    });
    setBtnPreview(buttons);
    snackbarFunc(resBtn, setLoading, "Updated");
    setLoading(false);
    onClose();
  };

  const handleChangeContent = () => {
    setLoading(true);
    if (image || bgImage) {
      handleUploadImage();
      return;
    }
    if (buttons.btnCard) {
      handleUploadButtons();
      return;
    }
    const arr = [...contentPreview];
    let idx = arr.findIndex((obj) => obj.id === dataId);
    arr[idx] = form;
    setContentPreview(arr);
    setLoading(false);
    onClose();
  };

  return (
    <CustomDialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth={"xs"}
      scroll={"paper"}
    >
      <DialogContent>
        <Typography
          variant="body1"
          sx={{
            m: 1,
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          {`Edit ${dataId === "btn" ? "Buttons" : "Content"}`}
        </Typography>
        <Box sx={{ p: 1 }}>
          {dataId === "hero01" && (
            <ComponentHero01 form={form} handleChange={handleChange} />
          )}
          {dataId === "hero02" && (
            <ComponentHero02
              form={form}
              handleChange={handleChange}
              imagePreview={imagePreview}
              bgImagePreview={bgImagePreview}
              handleChangeImage={handleChangeImage}
              handleChangeBgImage={handleChangeBgImage}
              handleDelete={handleDelete}
              validateSize={validateSize}
            />
          )}
          {dataId.indexOf("des") !== -1 && (
            <ComponentDes
              form={form}
              handleChange={handleChange}
              imagePreview={imagePreview}
              handleChangeImage={handleChangeImage}
              validateSize={validateSize}
            />
          )}
          {dataId.indexOf("c") !== -1 && (
            <ComponentCard
              dataId={dataId}
              form={form}
              handleChange={handleChange}
            />
          )}
          {dataId === "btn" && (
            <InputButtons buttons={buttons} handleChange={handleChange} />
          )}

          <Stack direction="row">
            <Button
              size="normal"
              variant="outlined"
              color="blue"
              disabled={loading ? true : false}
              startIcon={loading ? <CustomCircularProgress /> : null}
              sx={{
                width: "175px",
                m: "auto",
                mt: 3,
                fontWeight: 600,
                borderRadius: "0.75rem",
              }}
              onClick={handleChangeContent}
            >
              Apply
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};

const mapStateToProps = (state) => ({
  contentPreview: state.preview.contentPreview,
});

export default connect(mapStateToProps)(ModalContent);
