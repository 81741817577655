import React, { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  useTheme,
  styled,
  TextField,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Tooltip,
  Autocomplete,
  Grid,
  Button,
  Paper,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Delete,
  Edit,
  Close,
  Person,
  AddPhotoAlternate,
  Upload,
  UnfoldMore,
} from "@mui/icons-material";
import "src/App.css";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "20px !important",
  },
  rootPerson: {
    fontSize: "4rem !important",
    color: "#f5f5f5",
    m: "auto",
  },
  rootPhoto: {
    fontSize: "3rem !important",
    color: "lightgrey",
    m: "auto",
  },
}));

const CustomTextField = styled(TextField)(({ theme, register }) => ({
  ".MuiOutlinedInput-root": {
    borderColor: register ? `${theme.palette.primary.main}` : "#212121",
    borderWidth: "0.1px",
  },
  "&.Mui-focused": {
    borderColor: register ? `${theme.palette.primary.main}` : "#212121",
    borderWidth: "0.1px",
  },
  "&:hover fieldset": {
    borderColor: register
      ? `${theme.palette.primary.main} !important`
      : "#212121",
    borderWidth: "0.1px",
  },
}));

export const ComponentHero01 = ({ form, handleChange }) => {
  return (
    <>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Label data="Title" />
        <Input
          value={form.title}
          handleChange={handleChange}
          placeholder="Type your content title"
          name="title"
          rows={2}
        />
      </Stack>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Label data="Subtitle" />
        <Input
          value={form.subtitle}
          handleChange={handleChange}
          placeholder="Type your content subtitle"
          name="subtitle"
          rows={2}
        />
      </Stack>
    </>
  );
};

export const InputButtons = ({ buttons, handleChange }) => {
  return (
    <Stack direction="column" sx={{ width: "100%" }}>
      <Stack direction="column" sx={{ pl: 1, pr: 1 }}>
        {["btnCard", "btnCard01", "btnCard02", "btnCard03"].map((v, i) => {
          const val =
            i === 0
              ? buttons.btnCard
              : i === 1
              ? buttons.btnCard01
              : i === 2
              ? buttons.btnCard02
              : buttons.btnCard03;
          return (
            <Box key={i}>
              <Typography variant="caption" sx={{ pt: i === 0 ? 1 : null }}>
                {i === 0
                  ? "Normal"
                  : i === 1
                  ? "Sebelum melakukan tes"
                  : i === 2
                  ? "Sedang melakukan tes"
                  : "Sudah melakukan tes"}
              </Typography>
              <Input
                value={val}
                handleChange={handleChange}
                placeholder="Type your content button card"
                name={v}
                rows={1}
              />
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
};

export const ComponentCard = ({ dataId, form, handleChange }) => {
  const alignTitle = ["left", "right", "center", "side-by-side"];
  const con = dataId.indexOf("tes") !== -1 ? true : false;

  return (
    <>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Label data="Title" />
          {!con && (
            <SelectFormControl
              name="alignTitle"
              value={form.alignTitle}
              handleChange={handleChange}
              data={alignTitle}
            />
          )}
        </Stack>

        <Input
          value={form.title}
          handleChange={handleChange}
          placeholder="Type your content title"
          name="title"
          rows={2}
        />
      </Stack>
      {!con && (
        <Stack direction="column" sx={{ width: "100%" }}>
          <Label data="Subtitle" />
          <Input
            value={form.subtitle}
            handleChange={handleChange}
            placeholder="Type your content subtitle"
            name="subtitle"
            rows={1}
          />
        </Stack>
      )}
    </>
  );
};

export const ComponentHero02 = ({
  form,
  handleChange,
  imagePreview,
  bgImagePreview,
  handleChangeImage,
  handleChangeBgImage,
  handleDelete,
  validateSize,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const alignText = ["left", "right", "center", "justify"];
  const posImg = ["left", "right", "center-top", "center-bottom"];

  return (
    <>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Label data="Title" />
          <SelectFormControl
            name="alignText"
            value={form.alignText}
            handleChange={handleChange}
            data={alignText}
          />
        </Stack>

        <Input
          value={form.title}
          handleChange={handleChange}
          placeholder="Type your content title"
          name="title"
          rows={2}
        />
      </Stack>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Label data="Subtitle" />
        <Input
          value={form.subtitle}
          handleChange={handleChange}
          placeholder="Type your content subtitle"
          name="subtitle"
          rows={3}
        />
      </Stack>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Label data="Button Text" />
        <Input
          value={form.buttonText}
          handleChange={handleChange}
          placeholder="Type your content button text"
          name="buttonText"
          rows={1}
        />
      </Stack>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Label data="Image" />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Delete Image">
              <IconButton
                sx={{ mr: 1, color: `${theme.palette.red.main}` }}
                onClick={() => handleDelete("img")}
              >
                <Delete className={classes.root} />
              </IconButton>
            </Tooltip>
            <SelectFormControl
              name="posImg"
              value={form.posImg}
              handleChange={handleChange}
              data={posImg}
            />
          </Box>
        </Stack>
        <BoxInputImage
          preview={imagePreview}
          value={form.img}
          name="img"
          handleChangeImage={handleChangeImage}
        />
        {validateSize === "img" && (
          <Typography
            component="p"
            variant="caption"
            sx={{ color: `${theme.palette.red.main}` }}
          >
            * maximum image size: 1MB
          </Typography>
        )}
      </Stack>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Label data="Background Image" />
          <Tooltip title="Delete Background image">
            <IconButton
              sx={{ color: `${theme.palette.red.main}` }}
              onClick={() => handleDelete("bg")}
            >
              <Delete className={classes.root} />
            </IconButton>
          </Tooltip>
        </Stack>
        <BoxInputImage
          preview={bgImagePreview}
          value={form.bgImg}
          name="bg"
          handleChangeBgImage={handleChangeBgImage}
        />
        {validateSize === "bg" && (
          <Typography
            component="p"
            variant="caption"
            sx={{ color: `${theme.palette.red.main}` }}
          >
            * maximum background image size: 1MB
          </Typography>
        )}
      </Stack>
    </>
  );
};

export const ComponentDes = ({
  form,
  handleChange,
  imagePreview,
  handleChangeImage,
  validateSize,
}) => {
  const theme = useTheme();
  const alignTitle = ["left", "right", "center"];
  const posTitle = ["img-side", "center-top"];
  const alignText = ["left", "right", "center", "justify"];
  const posImg = ["left", "right"];

  return (
    <>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Label data="Title" />
          <Box>
            {form.posTitle === "img-side" && (
              <SelectFormControl
                name="alignTitle"
                value={form.alignTitle}
                handleChange={handleChange}
                data={alignTitle}
              />
            )}
            <SelectFormControl
              name="posTitle"
              value={form.posTitle}
              handleChange={handleChange}
              data={posTitle}
            />
          </Box>
        </Stack>
        <Input
          value={form.title}
          handleChange={handleChange}
          placeholder="Type your content title"
          name="title"
          rows={2}
        />
      </Stack>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Label data="Subtitle" />
          <SelectFormControl
            name="alignTitle"
            value={form.alignTitle}
            handleChange={handleChange}
            data={alignTitle}
          />
        </Stack>
        <Input
          value={form.subtitle}
          handleChange={handleChange}
          placeholder="Type your content subtitle"
          name="subtitle"
          rows={2}
        />
      </Stack>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Label data="Paragraph" />
          <SelectFormControl
            name="alignText"
            value={form.alignText}
            handleChange={handleChange}
            data={alignText}
          />
        </Stack>
        <Input
          value={form.paragraph}
          handleChange={handleChange}
          placeholder="Type your content paragraph"
          name="paragraph"
          rows={5}
        />
      </Stack>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Label data="Image" />
          <SelectFormControl
            name="posImg"
            value={form.posImg}
            handleChange={handleChange}
            data={posImg}
          />
        </Stack>
        <BoxInputImage
          preview={imagePreview}
          value={form.img}
          name="img"
          handleChangeImage={handleChangeImage}
        />
        {validateSize === "img" && (
          <Typography
            component="p"
            variant="caption"
            sx={{ color: `${theme.palette.red.main}` }}
          >
            * maximum image size: 1MB
          </Typography>
        )}
      </Stack>
    </>
  );
};

export const InputFieldGalery = ({
  form,
  validate,
  validateSize,
  handleChange,
  handleChangeImage,
  handleDeleteTag,
  handleSelectTags,
  optionsArr,
  fetchData,
  loadingTags,
}) => {
  const theme = useTheme();

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ maxWidth: "50%", m: "auto", mb: 2 }}
      >
        <CircleInputImage
          name="photo"
          shape="rectangle"
          namePanel="Galery"
          text="image"
          value={form.photo}
          handleChangeImage={handleChangeImage}
        />
        {validateSize && (
          <Typography
            component="p"
            variant="caption"
            sx={{
              mt: 1,
              textAlign: "center",
              color: `${theme.palette.red.main}`,
            }}
          >
            * maximum image size: 1MB
          </Typography>
        )}
      </Stack>

      <Stack direction="column" sx={{ maxWidth: "100%", m: "auto", mb: 1 }}>
        <Label data="Title" panel="Article" />

        <Input
          value={form.name}
          handleChange={handleChange}
          validate={validate}
          placeholder="Type photo's title"
          name="name"
          label="tittle"
          rows={1}
        />
      </Stack>
      <Stack direction="column" sx={{ maxWidth: "100%", m: "auto" }}>
        <Label data="Tags" panel="Article" />
        <AppliedTags value={form.tags} handleDeleteTag={handleDeleteTag} />

        <CustomAutoComplete
          name="tags"
          optionsArr={optionsArr}
          fetchData={fetchData}
          keyLabel={"tag_name"}
          handleSelect={handleSelectTags}
          loadingTags={loadingTags}
          value={form.tags}
          placeholder="Add New Tag"
        />
      </Stack>
    </>
  );
};

export const InputFieldArticle = ({
  form,
  validate,
  validateSize,
  fetchData,
  loadingTags,
  handleChange,
  handleSelectTags,
  handleDeleteTag,
  handleChangeImage,
  optionsArr,
}) => {
  const theme = useTheme();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Stack direction="column" sx={{ width: "100%", mb: 1 }}>
            <Label data="Tags" panel="Article" />
            <AppliedTags value={form.tags} handleDeleteTag={handleDeleteTag} />

            <CustomAutoComplete
              name="tags"
              optionsArr={optionsArr}
              fetchData={fetchData}
              keyLabel={"tag_name"}
              handleSelect={handleSelectTags}
              loadingTags={loadingTags}
              value={form.tags}
              placeholder="Add New Tag"
            />
          </Stack>

          <Stack direction="column" sx={{ width: "100%", mb: 1 }}>
            <Label data="Title" panel="Article" />
            <Input
              value={form.title}
              handleChange={handleChange}
              placeholder="Type your article title"
              name="title"
              validate={validate}
              label="title"
              rows={2}
            />
          </Stack>
        </Grid>
        <Grid item md={6}>
          <Stack direction="column" sx={{ width: "100%", mb: 1 }}>
            <Label data="Cover" panel="Article" />
            <Stack sx={{ mt: 1, mb: "0.25rem" }}>
              <Label
                data="Pilih atau upload gambar yang menunjukkan isi artikel Anda. Maksimal ukuran gambar 1MB."
                caption="true"
              />
            </Stack>
            <CircleInputImage
              name="cover"
              shape="rectangle"
              text="cover"
              value={form.cover}
              handleChangeImage={handleChangeImage}
            />
            {validateSize && (
              <Typography
                component="p"
                variant="caption"
                sx={{
                  mt: 1,
                  color: `${theme.palette.red.main}`,
                }}
              >
                * maximum image size: 1MB
              </Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Stack direction="column" sx={{ width: "100%", mb: 1 }}>
        <Label data="Writer" panel="Article" />
        <Input
          value={form.creator}
          handleChange={handleChange}
          placeholder="Writer's name"
          name="creator"
          rows={1}
        />
      </Stack>
      <Stack direction="column" sx={{ mb: 1, width: "100%" }}>
        <Label data="Content" panel="Article" />
      </Stack>
    </>
  );
};

export const InputFieldTesti = ({
  form,
  handleChange,
  handleChangeImage,
  validateSize,
  validate,
}) => {
  const theme = useTheme();
  return (
    <>
      <Stack direction="column" sx={{ mt: 2, mb: 1 }}>
        <CircleInputImage
          name="photo"
          shape="circle"
          value={form.photo}
          handleChangeImage={handleChangeImage}
        />
        {validateSize && (
          <Typography
            component="p"
            variant="caption"
            sx={{
              mt: 1,
              textAlign: "center",
              color: `${theme.palette.red.main}`,
            }}
          >
            * maximum image size: 1MB
          </Typography>
        )}
      </Stack>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Label data="Name" />
        <Input
          value={form.name}
          handleChange={handleChange}
          validate={validate}
          placeholder="Person's name"
          label="name"
          name="name"
          rows={1}
        />
      </Stack>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Label data="Position" />
        <Input
          value={form.position}
          handleChange={handleChange}
          placeholder="Person's position"
          name="position"
          rows={1}
        />
      </Stack>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Label data="Content" />
        <Input
          value={form.content}
          handleChange={handleChange}
          placeholder="Person's content"
          name="content"
          rows={5}
        />
      </Stack>
    </>
  );
};

export const Label = ({ data, panel, caption }) => {
  return (
    <Typography
      variant={caption ? "caption" : "body2"}
      color={caption ? "text.tertiary" : "text.primary"}
      sx={{
        fontWeight: !panel ? 600 : null,
      }}
    >
      {data}
    </Typography>
  );
};

export const Input = ({
  value,
  handleChange,
  placeholder,
  name,
  rows,
  label,
  validate,
}) => {
  const theme = useTheme();
  return (
    <CustomTextField
      placeholder={placeholder}
      name={name}
      variant="outlined"
      color="black"
      hiddenLabel
      fullWidth
      multiline
      error={validate && !value}
      helperText={validate && !value && `* ${label} cannot be null`}
      value={value}
      rows={rows}
      size="small"
      sx={{ m: `${theme.spacing(1)} 0` }}
      onChange={handleChange}
    />
  );
};

export const SelectFormControl = ({ name, value, handleChange, data }) => {
  const theme = useTheme();
  return (
    <FormControl
      variant="outlined"
      hiddenLabel
      size="small"
      color="black"
      sx={{ m: `${theme.spacing(1)} 0`, ml: name === "posTitle" ? 1 : null }}
    >
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        inputProps={{
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: "#ffff",
              },
            },
          },
        }}
      >
        {data.map((v) => (
          <MenuItem key={v} value={v}>
            {v}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const BoxInputImage = ({
  preview,
  value,
  handleChangeImage,
  handleChangeBgImage,
  name,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Box
      sx={{
        height: "150px",
        borderRadius: "0.5rem",
        border: "1px solid lightgrey",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        backgroundColor: "#ffff",
        m: `${theme.spacing(1)} 0`,
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <input
        type="file"
        id={name}
        name={name}
        accept="image/png, image/jpg, image/jpeg"
        style={{
          opacity: 0,
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
        onInput={
          name === "img"
            ? (e) => handleChangeImage(e)
            : (e) => handleChangeBgImage(e)
        }
      />
      {preview || value ? (
        <img
          alt="preview"
          src={preview ? preview : value}
          style={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
          }}
        />
      ) : (
        <Typography
          component="label"
          // for="input-logo"
          variant="body2"
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            color: "lightgrey",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Edit className={classes.root} sx={{ mr: 1 }} />
          Edit Image
        </Typography>
      )}
    </Box>
  );
};

const CircleInputImage = ({
  shape,
  value,
  name,
  handleChangeImage,
  namePanel,
  text,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={
        shape === "circle" ? "shape-style-circle" : "shape-style-rectangular"
      }
      sx={{
        position: "relative",
        aspectRatio: namePanel ? "16/9" : null,
        height:
          !namePanel && shape !== "circle" ? "7.5rem" : namePanel ? "" : "4rem",
        mt: shape !== "circle" ? 1 : null,
        backgroundColor: value ? "#ffff" : "#e9e9e9",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      {shape === "circle" && !value && (
        <Person className={classes.rootPerson} />
      )}

      {shape !== "circle" && !value && (
        <>
          {namePanel ? (
            <Upload className={classes.rootPhoto} />
          ) : (
            <AddPhotoAlternate className={classes.rootPhoto} />
          )}
          <Typography variant="body2" sx={{ color: "#909090" }}>
            {`Upload ${text}`}
          </Typography>
        </>
      )}

      {value && (
        <img
          src={value}
          alt="img"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: shape === "circle" ? "4rem" : "0.75rem",
            objectFit: !namePanel && shape !== "circle" ? "cover" : "cover", // contain true
            objectPosition: "center",
            boxShadow:
              "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px",
          }}
        />
      )}

      <input
        type="file"
        id={name}
        name={name}
        accept="image/png, image/jpg, image/jpeg"
        style={{
          opacity: 0,
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
        onInput={(e) => handleChangeImage(e)}
      />
    </Box>
  );
};

const AppliedTags = ({ value, handleDeleteTag }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Typography
        variant="caption"
        sx={{
          mt: 1,
          mr: 1,
          color: "text.tertiary",
          fontWeight: 500,
          width: "25%",
        }}
      >
        Applied Tags
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        className="custom-scrollbar x"
        spacing={1}
        sx={{
          maxWidth: "75%",
          pb: "0.25rem",
        }}
      >
        {value?.map((v, i) => (
          <Chip
            key={v.tag_id}
            label={v.tag_name}
            color="primary"
            onDelete={() => handleDeleteTag(i)}
          />
        ))}
      </Stack>
    </Box>
  );
};

export const CustomAutoComplete = ({
  name,
  optionsArr,
  handleSelect,
  validate,
  value,
  placeholder,
  register,
  fetchData,
  loadingTags,
  keyLabel,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Autocomplete
      selectOnFocus
      clearOnBlur
      disabled={loadingTags ? true : false}
      disableClearable={!register ? true : false}
      id={name}
      options={optionsArr}
      fullWidth
      size="small"
      getOptionLabel={(option) => option[keyLabel]}
      onInputChange={!fetchData ? null : (e, val) => fetchData(val)}
      onChange={(e, val) => handleSelect(val, name)}
      value={value}
      renderInput={(params) => (
        <CustomTextField
          hiddenLabel
          placeholder={placeholder}
          error={validate && !value}
          helperText={validate && !value && `* ${placeholder} harap diisi`}
          variant="outlined"
          register={register}
          color={register ? "primary" : "black"}
          sx={{ m: register ? null : `${theme.spacing(1)} 0` }}
          multiline={!register ? true : false}
          rows={!register ? 1 : 0}
          {...params}
        />
      )}
      {...rest}
    />
  );
};
