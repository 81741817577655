import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import {
  enrollCourse,
  getTokenPayment,
  paidChecker,
  setBtnPreview,
  setOpenLogin,
  setSnackbarOpen,
} from "src/redux/actions";
import localforage from "localforage";
import {
  Box,
  Typography,
  useMediaQuery,
  Grid,
  useTheme,
  Paper,
  Stack,
  Button,
  styled,
  CircularProgress,
  Chip,
  Skeleton,
} from "@mui/material";
import { Home, PersonOutline, SellOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
// import image from "src/assets/img/images7.jpg";
import image from "src/assets/img/card02.png";
import { domain } from "src/components/Layout/data";
import "src/App.css";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "16px !important",
  },
  root2: {
    fontSize: "8px !important",
  },
}));

const CustomCircularProgress = styled(CircularProgress)(() => ({
  "&.MuiCircularProgress-root": {
    height: "15px !important",
    width: "15px !important",
    display: "flex",
    color: "#ffff",
    mr: 1,
  },
}));

const CardPro02 = ({
  v,
  i,
  z,
  userRole,
  home,
  loading,
  objContent,
  isAuthenticated,
  libPages,
  buttons,
  handleSelectCard,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const isScreen1536 = useMediaQuery("(min-width:1536px)");
  const isScreen500 = useMediaQuery("(min-width:500px)");
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen700 = useMediaQuery("(min-width:700px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [btn, setBtn] = useState({});

  useEffect(() => {
    getButtonText();
  }, [buttons]);

  const getButtonText = async () => {
    const btn = buttons.btnCard
      ? buttons
      : await localforage.getItem("buttons");
    setBtn(btn);
  };

  const handleSelect = async (v) => {
    if (!isAuthenticated) {
      setOpenLogin(true);
      return;
    }
    const token = await localforage.getItem("token");
    setLoadingBtn(true);

    if (home) {
      const resEnroll = await enrollCourse({
        token,
        publishId: v.publish_id,
      });
      if (resEnroll.code === 200) {
        navigate(`/v2/user/dashboard/${libPages[1]}`);
        setSnackbarOpen("success", "Enrolled successfully");
      } else if (resEnroll.status === 400) {
        if (resEnroll.data.message.indexOf("sudah terdaftar") !== -1) {
          navigate(`/v2/user/dashboard/${libPages[1]}`);
        }
        setSnackbarOpen("error", resEnroll.data.message);
      }
      setLoadingBtn(false);
    }

    if (!home) {
      const resPaidChecker = await paidChecker({
        token,
        publishId: v.publish_id,
      });

      if (v.payment_type === "in-advance" && resPaidChecker.status === 400) {
        const resToken = await getTokenPayment({
          token,
          publishId: v.publish_id,
          domain,
        });
        window.snap.pay(resToken.transactionToken);
        setLoadingBtn(false);
        return;
      }

      setLoadingBtn(false);
      const newWindow = window.open(
        `${window.location.origin}/entrance/pages/login?email=${v.participantId}&password=${v.password}&ecommerceUrl=${window.location.hostname}`,
        // `https://entrance.shratech.com/entrance/pages/login?email=${v.participantId}&password=${v.password}&ecommerceUrl=${window.location.hostname}`,
        "_self",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  const buttonText = () => {
    if (loadingBtn) {
      return <CustomCircularProgress />;
    } else if (!home && v.status_code === "01") {
      return `${btn.btnCard01}`;
    } else if (!home && v.status_code === "02") {
      return `${btn.btnCard02}`;
    } else if (!home && v.status_code === "03") {
      return `${btn.btnCard03}`;
    } else {
      return `${btn.btnCard}`;
    }
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        maxWidth:
          isScreen1536 && userRole !== "5"
            ? "20% !important"
            : isScreen1200
            ? "25% !important"
            : isScreen700
            ? "33.3% !important"
            : isScreen500
            ? "50% !important"
            : "100% !important",
        pl: !isScreen700 && i === 0 && home ? 2 : null,
        pr: !isScreen700 && i === z && home ? 2 : null,
      }}
    >
      <Paper
        elevation={1} // isScreen1024 ? 2 : null
        sx={{
          backgroundColor: "transparent",
          borderRadius: userRole === "5" ? "0.5rem" : "1rem",
          // border: "1px solid lightgrey",

          border: isScreen500 ? "none" : "0.1px solid lightgrey",
          "&:hover": {
            boxShadow: isScreen1024
              ? "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px"
              : "none",
          },
        }}
      >
        {loading && (
          <Skeleton
            variant="rectangular"
            sx={{
              height: userRole === "5" ? "7.5rem" : "10rem",
              borderRadius: userRole === "5" ? "0.5rem" : "1rem",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />
        )}

        {loading && (
          <Stack sx={{ p: 2 }}>
            <Skeleton variant="rectangular" sx={{ height: "1rem" }} />
            <Skeleton variant="rectangular" sx={{ height: "1rem", mt: 1 }} />
          </Stack>
        )}
        {!loading && (
          <>
            <Box
              sx={{
                backgroundColor: "#f5f5f5",
                backgroundImage: `url(${image})`, //v.image
                aspectRatio: "16/9",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: userRole === "5" ? "0.5rem" : "1rem",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: "1px solid lightgrey",
                position: "relative",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  width: "100%",
                  position: "absolute",
                  bottom: 0,
                  background:
                    // v.image
                    // ?
                    `${theme.palette.background.paper3}`,
                  // : null,
                  p:
                    userRole === "5"
                      ? `0.25rem ${theme.spacing(1)}`
                      : `0.5rem ${theme.spacing(2)}`,
                }}
              >
                <Chip
                  icon={
                    <PersonOutline
                      className={
                        userRole === "5" ? classes.root2 : classes.root
                      }
                    />
                  }
                  label={v?.participant_count}
                  color="secondary"
                  sx={{
                    height: userRole === "5" ? "15px" : "25px",
                    fontSize: userRole === "5" ? 6 : 12,
                  }}
                />
                <Chip
                  icon={
                    !v.price ? null : (
                      <SellOutlined
                        className={
                          userRole === "5" ? classes.root2 : classes.root
                        }
                      />
                    )
                  }
                  label={!v.price ? "" : `Rp ${v.price}`}
                  color="secondary"
                  sx={{
                    height: userRole === "5" ? "15px" : "25px",
                    fontSize: userRole === "5" ? 6 : 12,
                  }}
                />
              </Stack>
            </Box>
            <Stack
              direction="column"
              sx={{
                width: "100%",
                p:
                  userRole === "5"
                    ? `0.25rem ${theme.spacing(1)}`
                    : `0.75rem ${theme.spacing(2)}`,
                mt: 0,
              }}
            >
              <Typography
                noWrap={true}
                variant={userRole === "5" ? "caption2" : "caption"}
                sx={{
                  fontSize: userRole === "5" ? 6 : null,
                  color: "text.tertiary",
                  fontWeight: "500",
                }}
              >
                {v?.inst_name}
              </Typography>
              <Typography
                variant={userRole === "5" ? "caption2" : "body2"}
                className={
                  userRole === "5" ? "multi-line-text2-adm" : "multi-line-text2"
                }
                sx={{
                  fontSize: userRole === "5" ? 8 : null,
                  fontWeight: 600,
                }}
              >
                {v?.publish_name}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  height: userRole === "5" ? "15px" : "30px",
                  border: `0.1px solid ${theme.palette.primary.main}`,
                  backgroundColor: "transparent",
                  borderRadius: "5rem",
                  m: "0 auto",
                  mt: userRole === "5" ? "0.5rem" : "0.75rem",
                  mb:
                    userRole === "5"
                      ? "0.25rem"
                      : isScreen600
                      ? 1
                      : isScreen500
                      ? null
                      : 1,
                  width: userRole === "5" ? "100%" : "fit-content",
                }}
                onClick={
                  loadingBtn || userRole === "5" ? null : () => handleSelect(v)
                }
              >
                <Typography
                  noWrap={true}
                  variant="body2"
                  sx={{
                    fontSize: userRole === "5" ? 6 : 12,
                    fontWeight: 600,
                  }}
                >
                  {buttonText()}
                </Typography>
              </Button>
            </Stack>
          </>
        )}
      </Paper>
    </Grid>
    // <Grid
    //   item
    //   xs={12}
    //   sx={{
    //     maxWidth:
    //       isScreen1536 && userRole !== "5"
    //         ? "20% !important"
    //         : isScreen1200
    //         ? "25% !important"
    //         : "25% !important",
    //   }}
    // >
    //   <Paper
    //     elevation={2}
    //     sx={{
    //       backgroundColor: "#ffff",
    //       borderRadius: userRole === "5" ? "0.5rem" : "1rem",
    //       "&:hover": {
    //         cursor: "pointer",
    //         boxShadow:
    //           "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px",
    //       },
    //     }}
    //   >
    //     <Box sx={{ position: "relative" }}>
    //       {/* <img
    //         alt={v.publish_id}
    //         src={image}
    //         style={{
    //           borderRadius: "1rem",
    //           width: "100%",
    //           objectFit: "cover",
    //           height: "10rem",
    //         }}
    //       /> */}
    //       <Box
    //         sx={{
    //           backgroundImage: `url(${image})`,
    //           backgroundSize: "cover",
    //           height: userRole === "5" ? "4.5rem" : "10rem",
    //           borderTopLeftRadius: userRole === "5" ? "0.5rem" : "1rem",
    //           borderTopRightRadius: userRole === "5" ? "0.5rem" : "1rem",
    //         }}
    //       ></Box>
    //       <Stack
    //         sx={{
    //           position: "absolute",
    //           bottom: 0,
    //           left: 0,
    //           p:
    //             userRole === "5"
    //               ? `0.25rem ${theme.spacing(1)}`
    //               : `0.75rem ${theme.spacing(2)}`,

    //           background: `${theme.palette.background.paper3}`,
    //           width: "100%",
    //           height: userRole === "5" ? "1.5rem" : "3rem",
    //           display: "flex",
    //           flexDirection: "row",
    //           justifyContent: "space-between",
    //           alignItems: "flex-start",
    //         }}
    //       >
    //         {v.participant_count !== "0" && (
    //           <Typography
    //             variant={userRole === "5" ? "caption2" : "caption"}
    //             sx={{
    //               fontSize: userRole === "5" ? 8 : null,
    //               backgroundColor: `${theme.palette.secondary.main}`,
    //               p: userRole === "5" ? "0.1rem 0.2rem" : "0.25rem 0.5rem",
    //               width: "fit-content",

    //               borderRadius: userRole === "5" ? "0.25rem" : "0.5rem",
    //               color: "#ffff",
    //               display: "flex",
    //               alignItems: "center",
    //             }}
    //           >
    //             <PersonOutline
    //               className={userRole === "5" ? null : classes.root}
    //               sx={{ mr: userRole === "5" ? "0.25rem" : 1 }}
    //             />
    //             {v.participant_count}
    //           </Typography>
    //         )}
    //         {v.price > 0 && (
    //           <Typography
    //             variant={userRole === "5" ? "caption2" : "caption"}
    //             noWrap={true}
    //             sx={{
    //               fontSize: userRole === "5" ? 8 : null,
    //               backgroundColor: `${theme.palette.secondary.main}`,
    //               p: userRole === "5" ? "0.1rem 0.2rem" : "0.25rem 0.5rem",

    //               width: "fit-content",
    //               borderRadius: userRole === "5" ? "0.25rem" : "0.5rem",
    //               color: "#ffff",
    //               fontWeight: 700,
    //               display: "flex",
    //               alignItems: "center",
    //             }}
    //           >
    //             <SellOutlined
    //               className={userRole === "5" ? null : classes.root}
    //               sx={{ mr: userRole === "5" ? "0.25rem" : 1 }}
    //             />
    //             Rp {v.price}
    //           </Typography>
    //         )}
    //       </Stack>
    //     </Box>
    //     <Stack
    //       direction="column"
    //       sx={{
    //         width: "100%",
    //         p:
    //           userRole === "5"
    //             ? `0.25rem ${theme.spacing(1)}`
    //             : `0.75rem ${theme.spacing(2)}`,
    //       }}
    //     >
    //       <Typography
    //         noWrap={true}
    //         variant={userRole === "5" ? "caption2" : "caption"}
    //         sx={{
    //           fontSize: userRole === "5" ? 6 : null,
    //           color: "text.tertiary",
    //           fontWeight: "500",
    //         }}
    //       >
    //         {v.inst_name}
    //       </Typography>
    //       <Typography
    //         variant={userRole === "5" ? "caption2" : "body2"}
    //         className={
    //           userRole === "5" ? "multi-line-text2-adm" : "multi-line-text2"
    //         }
    //         sx={{
    //           fontSize: userRole === "5" ? 8 : null,
    //           fontWeight: 600,
    //         }}
    //       >
    //         {v.publish_name}
    //       </Typography>
    //       {/* <Box sx={{ mt: 1 }}>
    //         {[1, 2, 3, 4, 5].map((i) => (
    //           <StarBorderOutlined className={classes.root} />
    //         ))}
    //       </Box> */}
    //       {/* divider
    //       <Stack>(kiri)foto (kanan) nama (knn bwh) jabatan</Stack>
    //       rating divider */}
    //       <Button
    //         size={userRole === "5" ? "small" : "normal"}
    //         variant="contained"
    //         color="primary"
    //         sx={{
    //           height: userRole === "5" ? "20px" : null,
    //           fontSize: userRole === "5" ? 7 : null,
    //           m: userRole === "5" ? `0.25rem auto` : `${theme.spacing(2)} auto`,
    //           mb: userRole === "5" ? "0.25rem" : 1,

    //           borderRadius: "5rem",
    //           transition: "all linear .4s",
    //           width: loading || place === "dashboard" ? "80%" : "auto",
    //         }}
    //         onClick={loading ? null : () => handleSelect(v)}
    //       >
    //         {buttonText()}
    //       </Button>
    //     </Stack>
    //   </Paper>
    // </Grid>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  libPages: state.nav.libPages,
  buttons: state.preview.buttons,
});

export default connect(mapStateToProps)(CardPro02);
