import React from "react";
import { connect } from "react-redux";
import {
  articleAPI,
  setArticlePreview,
  setIsChange,
  setTestiPreview,
  testimonyAPI,
} from "src/redux/actions";
import {
  Box,
  Paper,
  Typography,
  useTheme,
  IconButton,
  Tooltip,
  Button,
  Stack,
} from "@mui/material";
import {
  Add,
  ArrowDropDown,
  ArrowRight,
  Edit,
  Delete,
  PhotoLibrary,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import localforage from "localforage";
import { makeStyles } from "@mui/styles";
import { parsedDate } from "src/functions/parsedDate";
import { snackbarFunc } from "src/functions/snackbarFunc";
import { domain } from "src/components/Layout/data";
import "src/App.css";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "20px !important",
  },
}));

const PanelAddition = ({
  openPanel,
  setOpenPanel,
  namePanel,
  dataPanel,
  handleOpenModal,
  isChange,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleDisable = async (v) => {
    const token = await localforage.getItem("token");
    const obj = { token, domain };

    if (namePanel === "Testimony") {
      const resData = await testimonyAPI("update", {
        ...obj,
        ...v,
        testimonyId: v.testimony_id,
        isDisable: !v.is_disable,
      });
      snackbarFunc(resData, null, "Updated");
      const data = await testimonyAPI("get", { token, domain });
      setTestiPreview(data.data);
      setIsChange(!isChange.testi, "testi");
      window.location.reload();
    }
    if (namePanel === "Article") {
      const resData = await articleAPI("update", {
        ...obj,
        ...v,
        articleId: v.article_id,
        isDisable: !v.is_disable,
      });
      snackbarFunc(resData, null, "Updated");
      const data = await articleAPI("get", { token, domain });
      setArticlePreview(data.data);
      setIsChange(!isChange.art, "art");
    }
  };

  const text = (v) => {
    const pos = v.position ? `, ${v.position}` : "";

    if (namePanel === "Testimony") {
      return v.name ? `${v.name}${pos}` : `Anonym${pos}`;
    } else {
      return v.title ? v.title : "Untitled";
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "325px",
        }}
      >
        <Typography
          component="p"
          variant="body2"
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: 600,
            transition: "all linear .4s",
            pt: 1,
            pb: 1,
            "&:hover": { cursor: "pointer" },
          }}
          onClick={() => setOpenPanel(!openPanel)}
        >
          {openPanel && (
            <ArrowDropDown sx={{ mr: 1 }} className={classes.root} />
          )}
          {!openPanel && <ArrowRight sx={{ mr: 1 }} className={classes.root} />}
          {namePanel}
        </Typography>

        {/* Display Icon Add (without galery) */}
        {openPanel && dataPanel.length !== 0 && namePanel !== "Galery" && (
          <Tooltip title={`Add ${namePanel}`}>
            <IconButton
              sx={{ color: `${theme.palette.blue.main}`, mr: 1 }}
              onClick={() => handleOpenModal(namePanel)}
            >
              <Add className={classes.root} />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      {/* Display Button Add (with galery) */}
      {openPanel && (dataPanel.length === 0 || namePanel === "Galery") && (
        <Stack direction="row" sx={{ width: "300px" }}>
          <Button
            size="normal"
            variant="outlined"
            color="blue"
            startIcon={
              namePanel !== "Galery" ? (
                <Add className={classes.root} />
              ) : (
                <PhotoLibrary className={classes.root} />
              )
            }
            sx={{
              m: `${theme.spacing(1)} auto`,
              p: 3,
              pt: 2,
              pb: 2,
              fontWeight: 600,
              borderRadius: "0.75rem",
            }}
            onClick={() => handleOpenModal(namePanel)}
          >
            {namePanel !== "Galery"
              ? `Add ${namePanel}`
              : `Detail ${namePanel}`}
          </Button>
        </Stack>
      )}

      {openPanel && dataPanel.length !== 0 && namePanel !== "Galery" && (
        <>
          {dataPanel.map((v, i) => {
            const id = namePanel === "Article" ? v.article_id : v.testimony_id;

            return (
              <Paper
                key={i}
                elevation={2}
                sx={{
                  width: "325px",
                  mt: i === 0 ? 1 : 0,
                  mb: 1,
                  p: 1,

                  pl: 2,
                  pr: 2,
                  backgroundColor: "#ffff",
                  borderRadius: "1rem",
                  alignItems: "center",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Typography variant="caption">
                    {`${parsedDate(v.created_at)}`}
                  </Typography>

                  <Box>
                    <Tooltip title={`Edit ${namePanel}`}>
                      <IconButton
                        sx={{ color: `${theme.palette.blue.main}` }}
                        onClick={() => handleOpenModal(namePanel, id, v)}
                      >
                        <Edit className={classes.root} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      title={
                        !v.is_disable
                          ? `Hide ${namePanel}`
                          : `Show ${namePanel}`
                      }
                    >
                      <IconButton
                        sx={{
                          color: v.is_disable
                            ? "lightgrey"
                            : `${theme.palette.blue.main}`,
                        }}
                        onClick={() => handleDisable(v)}
                      >
                        {v.is_disable && (
                          <VisibilityOff className={classes.root} />
                        )}
                        {!v.is_disable && (
                          <Visibility className={classes.root} />
                        )}
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={`Delete ${namePanel}`}>
                      <IconButton
                        sx={{ color: `${theme.palette.red.main}` }}
                        onClick={() =>
                          handleOpenModal(namePanel, id, null, "delete")
                        }
                      >
                        <Delete className={classes.root} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>
                <Typography
                  variant="body2"
                  noWrap={true}
                  sx={{ fontWeight: 500, mt: 1, mb: 1 }}
                >
                  {text(v)}
                </Typography>
              </Paper>
            );
          })}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isChange: state.preview.isChange,
});

export default connect(mapStateToProps)(PanelAddition);
