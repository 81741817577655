export const inputNumberOnly = (e) => {
  const allowedChars = "0123456789";
  function contains(stringValue, charValue) {
    return stringValue.indexOf(charValue) > -1;
  }
  const invalidKey =
    (e.key.length === 1 && !contains(allowedChars, e.key)) ||
    (e.key === "." && contains(e.target.value, "."));
  return invalidKey && e.preventDefault();
};
