import React from "react";
import { connect } from "react-redux";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { ArrowDropDown, ArrowRight, Edit } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import "src/App.css";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "20px !important",
  },
}));

const EditPanel1 = ({
  openPanel1,
  setOpenPanel1,
  logoPreview,
  validateSize,
  handleChangeLogo,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Typography
        component="p"
        variant="body2"
        sx={{
          display: "flex",
          alignItems: "center",
          fontWeight: 600,
          pt: 1,
          pb: 1,
          transition: "all linear .4s",
          "&:hover": { cursor: "pointer" },
        }}
        onClick={() => setOpenPanel1(!openPanel1)}
      >
        {openPanel1 && (
          <ArrowDropDown sx={{ mr: 1 }} className={classes.root} />
        )}
        {!openPanel1 && <ArrowRight sx={{ mr: 1 }} className={classes.root} />}
        Logo
      </Typography>
      {openPanel1 && (
        <Paper
          elevation={2}
          sx={{
            width: "325px",
            p: 2,
            mt: 1,
            mb: 1,
            backgroundColor: "#ffff",
            transition: "all linear .4s",
            borderRadius: "1rem",
          }}
        >
          <Box
            sx={{
              height: "75px",
              borderRadius: "0.5rem",
              border: "1px dashed black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <input
              type="file"
              id="input-logo"
              name="logo"
              accept="image/png, image/jpg, image/jpeg"
              style={{
                opacity: 0,
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              onInput={(e) => handleChangeLogo(e)}
            />
            {logoPreview ? (
              <img
                alt="logo-preview"
                src={logoPreview}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              <Typography
                component="label"
                // for="input-logo"
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                  color: `${theme.palette.blue.main}`,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <Edit className={classes.root} sx={{ mr: 1 }} />
                Edit Logo
              </Typography>
            )}
          </Box>
          {validateSize && (
            <Typography
              component="p"
              variant="caption"
              sx={{ color: `${theme.palette.red.main}`, mt: 1 }}
            >
              * maksimal image size: 1MB
            </Typography>
          )}
        </Paper>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  logoPreview: state.preview.logoPreview,
});

export default connect(mapStateToProps)(EditPanel1);
