import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Typography, Button, CircularProgress } from "@mui/material";
import { myJobs, setSnackbarOpen } from "src/redux/actions";
import localforage from "localforage";
import { domain } from "src/components/Layout/data";
import ModalSelectDateMCU from "../Modal/ModalSelectDateMCU";
import ModalSignContractRequired from "../Modal/ModalSignContractRequired";

const MyCareer = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState("");
  const [dataDetail, setDataDetail] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const resTheme = await localforage.getItem("theme");
    if (resTheme.websiteFor === "recruitment") {
      const resJobs = await myJobs({ domain });
      setData(resJobs.data);
    }
    setLoading(false);
  };

  const handleStart = (d) => {
    const psikotestStartAt = new Date(d?.psikotest?.started_at_ftd);
    const psikotestFinishAt = new Date(d?.psikotest?.finished_at_ftd);
    const dateNow = new Date();
    if (d?.candidate_logs[0]?.ref_pipeline?.pipeline_type === "call") {
      const cnLastLogs = d?.candidate_logs[0];
      if (cnLastLogs?.meeting_eligible) {
        window.open(cnLastLogs?.meeting_room?.meeting_url);
      } else {
        setSnackbarOpen(
          "error",
          "Anda sedang tidak berada pada waktu Interview"
        );
      }
      return;
    }

    if (dateNow.getTime() >= psikotestStartAt.getTime()) {
      if (dateNow.getTime() <= psikotestFinishAt.getTime()) {
        const participantId = d.psikotest?.user_data?.user_id;
        const pass = d.psikotest?.user_data?.password;
        const newWindow = window.open(
          // `https://entrance.shratech.com/entrance/pages/login?email=${participantId}&password=${pass}&ecommerceUrl=${window.location.hostname}`,
          `${window.location.origin}/entrance/pages/login?email=${participantId}&password=${pass}&ecommerceUrl=${window.location.hostname}`,
          "_self",
          "noopener,noreferrer"
        );
        if (newWindow) newWindow.opener = null;
      } else {
        setSnackbarOpen("error", "Waktu psikotest sudah selesai");
      }
    } else {
      setSnackbarOpen("error", "Belum masuk waktu pengerjaan");
    }
  };

  const handleOpenModal = (d, type) => {
    setDataDetail(d);
    setOpenModal(type);
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <MaterialTable
        title=""
        columns={[
          { title: "Job Title", field: "position_group.position_group_name" },
          { title: "Location", field: "job_location_name" },
          {
            title: "Status",
            field: "candidate_logs[0].log_type",
            render: (d) => {
              if (d.extension && d.extension?.length) {
                const lastHistory =
                  d.extension[0]?.history[d.extension[0].history?.length - 1];
                return (
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {lastHistory?.candidate}
                  </Typography>
                );
              } else {
                const progress =
                  d?.candidate_logs &&
                  d?.candidate_logs[0]?.ref_pipeline?.pipeline_name;
                const strStatus =
                  d?.candidate_logs && d?.candidate_logs[0]?.log_type === "0"
                    ? "Gagal"
                    : d?.candidate_logs &&
                      d?.candidate_logs[0]?.log_type === "1"
                    ? "Menunggu"
                    : d?.candidate_logs &&
                      d?.candidate_logs[0]?.log_type === "2"
                    ? "Terjadwal"
                    : "Lulus";
                return (
                  <>
                    {d?.candidate_logs &&
                    d?.candidate_logs[0]?.log_type === "2" ? (
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {progress} {strStatus} <br />
                        {d?.candidate_logs[0]?.ref_pipeline?.pipeline_type ===
                        "call" ? (
                          <span
                            style={{ fontSize: "12px", fontWeight: "normal" }}
                          >
                            {d?.candidate_logs[0]?.started_at_ftd}
                            {/* -{" "} {d?.candidate_logs[0]?.finished_at_ftd} */}
                          </span>
                        ) : (
                          <span
                            style={{ fontSize: "12px", fontWeight: "normal" }}
                          >
                            {d?.psikotest?.started_at_ftd} -{" "}
                            {d?.psikotest?.finished_at_ftd}
                          </span>
                        )}
                      </Typography>
                    ) : (
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {strStatus} {progress}
                      </Typography>
                    )}
                  </>
                );
              }
            },
          },
          {
            title: "Action",
            field: "candidate_logs[0].log_type",
            render: (d) => {
              if (d.extension && d.extension?.length) {
                const lastHistory =
                  d.extension[0]?.history[d.extension[0].history?.length - 1];
                let actionMsg = null;
                if (lastHistory?.actions?.is_action) {
                  if (lastHistory?.actions?.action_type === "mcu-select-date") {
                    actionMsg = "Pilih Tanggal";
                  } else if (
                    lastHistory?.actions?.action_type ===
                    "request-to-complete-data"
                  ) {
                    actionMsg = "Lengkapi Data";
                  }
                }
                return (
                  <Button
                    variant="contained"
                    disabled={!actionMsg}
                    onClick={() =>
                      handleOpenModal(d, lastHistory?.actions?.action_type)
                    }
                  >
                    {actionMsg || "Mulai"}
                  </Button>
                );
              } else {
                let isDisableButton =
                  d?.candidate_logs[0]?.ref_pipeline?.pipeline_type === "call"
                    ? !d?.candidate_logs[0]?.meeting_eligible
                    : d?.candidate_logs[0]?.log_type !== "2";
                return (
                  <>
                    <Button
                      variant="contained"
                      disabled={isDisableButton}
                      onClick={() => handleStart(d)}
                    >
                      Mulai
                    </Button>
                  </>
                );
              }
            },
          },
        ]}
        data={data}
        options={{
          pageSizeOptions: [5, 10, 20],
          showFirstLastPageButtons: false,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          tableLayout: "fixed",
          selection: true,
        }}
        isLoading={loading}
      />
      <ModalSelectDateMCU
        open={openModal === "mcu-select-date"}
        onClose={() => {
          fetchData();
          setOpenModal("");
        }}
        dataDetail={dataDetail}
      />

      <ModalSignContractRequired
        open={openModal === "request-to-complete-data"}
        onClose={() => {
          fetchData();
          setOpenModal("");
        }}
        dataDetail={dataDetail}
      />
    </>
  );
};

export default MyCareer;
