import React, { useState, useEffect } from "react";
import localforage from "localforage";
import SectionHero01 from "src/components/Layout/SectionHero01";
import SectionHero02 from "src/components/Layout/SectionHero02";
import SectionBanner from "src/components/Layout/SectionBanner";
import SectionProduct from "src/components/Layout/SectionProduct";
import SectionDes from "src/components/Layout/SectionDes";
import SectionDes01 from "src/components/Layout/SectionDes01";
import SectionDes02 from "src/components/Layout/SectionDes02";
import SectionDes03 from "src/components/Layout/SectionDes03";
import SectionDes04 from "src/components/Layout/SectionDes04";
import SectionArticle from "src/components/Layout/SectionArticle";
import SectionGalery from "src/components/Layout/SectionGalery";
import SectionTestimony from "src/components/Layout/SectionTestimony";
import { Grid } from "@mui/material";
import { setIsActiveNavbar } from "src/redux/actions";

const Index = () => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    fetchMenu();
  }, []);

  const fetchMenu = async () => {
    const resNav = await localforage.getItem("navigation");
    let idx = resNav.findIndex((obj) => obj.page === "home");
    const title = resNav[idx].title;
    const defaultTitle = resNav[idx].defaultTitle;
    setIsActiveNavbar(title ? title : defaultTitle);

    const resContent = await localforage.getItem("content");
    const arr = resContent.filter((v) => !v.disabled);
    setContent(arr);
  };

  return (
    <Grid component="main">
      {content.map((v) => (
        <>
          {/* will display section banner, hero, or jumbotron according to id */}
          {v.id === "hero01" && <SectionHero01 key={v.id} id={v.id} />}
          {v.id === "hero02" && <SectionHero02 key={v.id} id={v.id} />}
          {v.id === "ban01" && (
            <SectionBanner link={v.link} key={v.id} id={v.id} />
          )}
          {/* will display section that contain few of company products or services */}
          {v.id === "pro02-c" && <SectionProduct key={v.id} id={v.id} />}
          {v.id === "art01-c" && <SectionArticle key={v.id} id={v.id} />}
          {v.id === "gal01-c" && <SectionGalery key={v.id} id={v.id} />}
          {v.id === "tes01-c" && <SectionTestimony key={v.id} id={v.id} />}
          {/* will display descriptive section contain an image and a descriptive text */}
          {/* {v.id === "des01" && <SectionDes01 key={v.id} id={v.id} />}
          {v.id === "des02" && <SectionDes02 key={v.id} id={v.id} />}
          {v.id === "des03" && <SectionDes03 key={v.id} id={v.id} />}
          {v.id === "des04" && <SectionDes04 key={v.id} id={v.id} />} */}
          {v.id.indexOf("des") !== -1 && <SectionDes key={v.id} id={v.id} />}
        </>
      ))}
    </Grid>
  );
};

export default Index;
