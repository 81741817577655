import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import localforage from "localforage";
import { Box } from "@mui/material";
// import bg from "src/assets/img/background.jpg";

const AdminLayout = ({ isActiveNavbar }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkRole = async () => {
      const role = await localforage.getItem("userRole"); 
      if (role !== "5") {
        navigate("/v2/user/");
        window.location.reload()
      }
    };
    checkRole();
  }, []);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          // backgroundImage: isActiveNavbar ? null : `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Outlet />
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  isActiveNavbar: state.nav.isActiveNavbar,
});

export default connect(mapStateToProps)(AdminLayout);
