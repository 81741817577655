import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Divider,
  Card,
  Grid,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  CircularProgress,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CustomDatePicker from "src/components/CustomDatePicker";
import {
  createOrganization,
  getOrganization,
  updateOrganization,
  deleteOrganization,
  setSnackbarOpen,
} from "src/redux/actions";

const dummyData = [
  {
    organization_name: "BEM UI",
    organization_field: "Fakultas",
    started_at: "2017-01-01",
    finished_at: "2017-12-31",
    position: "Leader",
    bio_organization_id: "123123123",
  },
];

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const Organization = () => {
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [form, setForm] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    fetchOrg();
  }, []);

  const fetchOrg = async () => {
    setLoading(true);
    const resOrg = await getOrganization();
    setOrganizations(resOrg.data);
    setLoading(false);
  };

  const handleDateChange = (val, name) => {
    setForm({ ...form, [name]: val });
  };

  const handleChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleEdit = (d) => {
    setForm(d);
    setOpenForm("edit");
  };

  const handleDelete = (d) => {
    setForm(d);
    setAlertOpen(true);
  };

  const handleSubmitDelete = async () => {
    await deleteOrganization(form);
    setForm({});
    setSnackbarOpen("success", "Data berhasil dihapus");
    setAlertOpen(false);
    fetchOrg();
  };

  const handleSave = async () => {
    if (openForm === "new") {
      await createOrganization(form);
    } else {
      await updateOrganization(form);
    }
    setSnackbarOpen("success", "Data disimpan");
    setOpenForm("");
    setForm({});
    fetchOrg();
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 2 }}>
        <b>Pengalaman Organisasi</b>
      </Typography>
      <Button
        variant="contained"
        sx={{ display: "flex", m: "auto 0 1rem auto" }}
        onClick={() => setOpenForm("new")}
      >
        Tambah
      </Button>
      {organizations.map((org) => (
        <Card
          id={org.bio_organization_id}
          sx={{ backgroundColor: "#FFF", p: 2, borderRadius: "16px" }}
        >
          <Grid container>
            <Grid item sm={3} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                {org.organization_name}
              </Typography>
            </Grid>
            <Grid item sm={3} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {org.organization_field}
              </Typography>
            </Grid>
            <Grid item sm={3} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>{org.position}</Typography>
            </Grid>
            <Grid item sm={3} sx={{ m: "auto" }}>
              <Stack direction="row" justifyContent={"end"} spacing={1}>
                <IconButton onClick={() => handleEdit(org)}>
                  <EditRoundedIcon />{" "}
                </IconButton>
                <IconButton onClick={() => handleDelete(org)}>
                  <DeleteRoundedIcon />{" "}
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      ))}
      <Divider sx={{ mt: 2, mb: 2 }} />
      {openForm && (
        <>
          <CustomInput
            label="Nama Organisasi"
            name="organization_name"
            onChange={handleChangeInput}
            value={form.organization_name}
          />
          <CustomInput
            label="Bidang Organisasi"
            name="organization_field"
            onChange={handleChangeInput}
            value={form.organization_field}
          />
          <CustomInput
            label="Jabatan"
            name="position"
            onChange={handleChangeInput}
            value={form.position}
          />
          <CustomDatePicker
            label="Tanggal Mulai"
            onChange={handleDateChange}
            name="started_at"
            value={form.started_at}
          />
          <CustomDatePicker
            label="Tanggal Selesai"
            onChange={handleDateChange}
            name="finished_at"
            value={form.finished_at}
          />
          <Stack direction="row" spacing={2} justifyContent={"end"}>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenForm("");
                setForm({});
              }}
            >
              Batal
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Simpan
            </Button>
          </Stack>
        </>
      )}

      <CustomDialog open={alertOpen} fullWidth maxWidth="xs">
        <DialogTitle>Konfirmasi</DialogTitle>
        <DialogContent>
          Data <b>{form.organization_name}</b> akan dihapus
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setAlertOpen(false);
              setForm({});
            }}
          >
            Batal
          </Button>
          <Button variant="contained" onClick={handleSubmitDelete}>
            Ya, Hapus
          </Button>
        </DialogActions>
      </CustomDialog>
    </Container>
  );
};

export default Organization;
