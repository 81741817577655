import React from "react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "18px !important",
  },
  root2: {
    fontSize: "10px !important",
  },
}));

export const BoxTitle = ({
  objContent,
  userRole,
  handleMore,
  next,
  previous,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen700 = useMediaQuery("(min-width:700px)");
  const isScreen600 = useMediaQuery("(min-width:600px)");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection:
          objContent.alignTitle === "side-by-side" || !handleMore
            ? "row"
            : "column",
        justifyContent:
          objContent.alignTitle === "side-by-side" || !handleMore
            ? "space-between"
            : "center",
        width: "100%",
        alignItems:
          objContent.alignTitle === "left"
            ? "flex-start"
            : objContent.alignTitle === "right"
            ? "flex-end"
            : "center",

        pl: isScreen700 ? null : isScreen600 ? 3 : 2,
        pr: isScreen600 ? null : isScreen600 ? 3 : 2,
        mb: userRole === "5" ? 0 : 2,

        // backgroundColor: "yellow",
        // border: "1px solid black"
      }}
    >
      <Typography
        variant={
          userRole === "5" ? "caption" : isScreen1024 ? "h5" : "subtitle1"
        }
        sx={{
          fontWeight: 700,
          textAlign:
            objContent.alignTitle === "left"
              ? "start"
              : objContent.alignTitle === "right"
              ? "end"
              : objContent.alignTitle === "center"
              ? "center"
              : null,
        }}
      >
        {objContent.title}
      </Typography>

      {objContent.alignTitle === "side-by-side" && handleMore && (
        <Chip
          label={objContent.subtitle}
          sx={{
            fontSize: userRole === "5" ? 7 : 13,
            backgroundColor: `${theme.palette.background.paper4}`,
            color: `${theme.palette.primary.main}`,
            height: userRole === "5" ? "15px" : "30px",

            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
              backgroundColor: `${theme.palette.background.paper4}`,
            },
          }}
          onClick={userRole === "5" ? null : handleMore}
        />
      )}

      {objContent.alignTitle !== "side-by-side" && handleMore && (
        <Typography
          variant={userRole === "5" ? "caption2" : "body2"}
          sx={{
            fontWeight: 500,
            textAlign:
              objContent.alignTitle === "left"
                ? "start"
                : objContent.alignTitle === "right"
                ? "end"
                : objContent.alignTitle === "center"
                ? "center"
                : null,
            color: `${theme.palette.primary.main}`,

            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          }}
          onClick={userRole === "5" ? null : handleMore}
        >
          {objContent.subtitle}
        </Typography>
      )}

      {!handleMore && (
        <Stack direction="row">
          {["previous", "next"].map((v, i) => (
            <IconButton
              sx={{
                color: `${theme.palette.background.paper4} !important`,
                background: `${theme.palette.background.paper4} !important`,
                // backgroundColor: "#ffff",
                // border: `1px solid lightgrey`,
                p: "0.25rem",
                ml: userRole === "5" ? 1 : 2,
              }}
              onClick={() => (i === 0 ? previous() : next())}
            >
              {i === 0 && (
                <ArrowBack
                  color="primary"
                  className={userRole === "5" ? classes.root2 : classes.root}
                />
              )}
              {i === 1 && (
                <ArrowForward
                  color="primary"
                  className={userRole === "5" ? classes.root2 : classes.root}
                />
              )}
            </IconButton>
          ))}
        </Stack>
      )}
    </Box>
  );
};
