import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  styled,
  Stack,
  TextField,
  Button,
  Chip,
  Divider,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import DialogConfirm from "./DialogConfirm";

const CustomInputA = ({
  label,
  name,
  onChange,
  value,
  defaultValue,
  required,
  ...rest
}) => {
  return (
    <TextField
      label={label}
      value={value}
      defaultValue={defaultValue}
      name={name}
      onChange={onChange}
      size="small"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      fullWidth
      style={{ marginBottom: "1rem" }}
      {...rest}
    />
  );
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const customIcon = {
  position: "absolute",
  right: "-4rem",
  bottom: "5%",
  top: "5%",
};

const SignA = () => {
  const [validation, setValidation] = useState(true);
  const [open, setOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({});

  const handleClickOpen = (type) => {
    setOpen(true);
    const props = {};
    if (type === "save") {
      props.text = "Apakah Anda yakin dengan aksi ini?";
      props.btn = "Simpan dan Lanjutkan";
      props.colorBtn = "primary";
    }
    if (type === "cancel") {
      props.text = "Apakah Anda yakin dengan aksi ini?";
      props.btn = "Mengundurkan Diri";
      props.colorBtn = "error";
    }
    setDialogProps(props);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid
      container
      component="main"
      maxWidth={"md"}
      sx={{ m: "auto" }}
      spacing={2}
    >
      <Grid item sm={12}>
        <Typography variant="h5" sx={{ m: "3rem 0 1rem 0" }}>
          Requirement Sign Contract
        </Typography>
        {/* <Stack> */}
        <Chip
          label="Batas Pengisian Rabu, 20 September 2023"
          color="primary"
          sx={{
            mb: "0.75rem",
            "& .MuiChip-label": {
              textAlign: "left",
            },
          }}
        />
        {/* </Stack> */}
      </Grid>
      <Grid item sm={9}>
        <CustomInputA
          // hiddenLabel
          label="NPWP"
          placeholder="NPWP"
          name="npwp"
          // defaultValue={eduData.institution_name}
          // onBlur={handleBlurEdu}
        />
      </Grid>
      <Grid item sm={3}>
        <Stack sx={{ position: "relative" }}>
          <Button component="label" variant="outlined" sx={{ ml: 3 }}>
            Upload
            <VisuallyHiddenInput type="file" />
            {validation && (
              <CheckCircle
                color="success"
                sx={{ fontSize: 30, height: "90%", ...customIcon }}
              />
            )}
          </Button>
        </Stack>
      </Grid>
      <Grid item sm={12}>
        <Typography variant="subtitle1" sx={{ mb: "1rem", fontWeight: "bold" }}>
          Rekening
        </Typography>
        <CustomInputA
          label="Nomor Rekening"
          placeholder="Nomor Rekening"
          name="rekening_number"
          // defaultValue={eduData.institution_name}
          // onBlur={handleBlurEdu}
        />
        <CustomInputA
          label="Pemilik Rekening"
          placeholder="A.n. Pemilik Rekening"
          name="rekening_owner"
          // defaultValue={eduData.institution_name}
          // onBlur={handleBlurEdu}
        />
        <CustomInputA
          label="Nama Bank"
          placeholder="Nama Bank"
          name="bank_name"
          // defaultValue={eduData.institution_name}
          // onBlur={handleBlurEdu}
        />
        <CustomInputA
          label="Nama Cabang"
          placeholder="Nama Cabang"
          name="branch_name"
          // defaultValue={eduData.institution_name}
          // onBlur={handleBlurEdu}
        />
        <Stack sx={{ position: "relative" }}>
          <Button
            component="label"
            variant="outlined"
            fullWidth
            sx={{ my: "1rem" }}
          >
            Upload Digital Scan sesuai Inputan di atas
            <VisuallyHiddenInput type="file" />
          </Button>
          {validation && (
            <CheckCircle
              color="success"
              sx={{ fontSize: 30, height: "90%", ...customIcon }}
            />
          )}
        </Stack>

        <Stack sx={{ width: "100%", my: "2rem", mb: "3rem" }}>
          <Divider variant="middle" sx={{ width: "70%", m: "auto" }} />
        </Stack>

        <Stack sx={{ width: "100%" }}>
          <Button
            variant="contained"
            sx={{ m: "auto", mb: "1rem", width: "fit-content" }}
            onClick={() => handleClickOpen("save")}
          >
            Simpan dan Lanjutkan Proses
          </Button>
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <Button
            variant="contained"
            color="error"
            sx={{ m: "auto", mb: "1rem", width: "fit-content" }}
            onClick={() => handleClickOpen("cancel")}
          >
            Mengundurkan Diri
          </Button>
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <Button
            variant="outlined"
            sx={{ m: "auto", mb: "1rem", width: "fit-content" }}
          >
            Tutup
          </Button>
        </Stack>
      </Grid>
      <DialogConfirm open={open} handleClose={handleClose} {...dialogProps} />
    </Grid>
  );
};
export default SignA;
