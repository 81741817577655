import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import localforage from "localforage";
import {
  Box,
  Typography,
  useMediaQuery,
  Grid,
  useTheme,
  Paper,
  Button,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router";
import imgDefault from "src/assets/img/images5.png";
import "src/App.css";

const SectionHero02 = ({ id, libPages, contentPreview, contentHoverId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const [objContent, setObjContent] = useState({});
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    getInfo();
  }, [contentPreview]);

  const getInfo = async () => {
    const resRole = await localforage.getItem("userRole");
    setUserRole(resRole);

    const resContent = contentPreview.length
      ? contentPreview
      : await localforage.getItem("content");
    const arr = resContent.filter((d) => d.id === id);
    setObjContent(arr[0]);
  };

  const handleClick = () => {
    navigate(`/v2/user/${libPages[0]}`);
  };

  return (
    <Stack
      component="section"
      className="slide-in-top"
      sx={{
        backgroundImage: objContent.bgImg ? `url(${objContent.bgImg})` : null,
        backgroundSize: "cover",
        marginBottom:
          userRole === "5" && objContent.bgImg
            ? "2.5rem !important"
            : userRole !== "5" && objContent.bgImg
            ? "3.5rem !important"
            : 0,
        // p: "3rem auto !important",
        // border: "1px solid red",
        // backgroundColor: "yellow",
      }}
    >
      <Grid
        container
        sx={{
          maxWidth:
            userRole === "5"
              ? "500px"
              : isScreen2000
              ? "1536px"
              : isScreen1400
              ? "1200px"
              : "1024px",
          m:
            userRole === "5" && objContent.img
              ? "2rem auto"
              : userRole !== "5" && objContent.img
              ? "3rem auto"
              : "0 auto",
          border: contentHoverId === id ? "1px dashed black" : null,
          // p: isScreen1024
          //   ? "2rem 0 1rem 0"
          //   : isScreen600
          //   ? "2rem 2rem 1rem 2rem"
          //   : "1rem 1rem 0 1rem",
          // border: "1px solid #ffff",
          p: 1,
        }}
      >
        <Grid
          container
          item
          spacing={userRole === "5" ? 3 : 5}
          sx={{
            display:
              objContent.posImg === "left" || objContent.posImg === "right"
                ? "flex"
                : "none",
            alignItems: "center",
          }}
        >
          {objContent.posImg === "left" && (
            <Grid item md={7}>
              <Img userRole={userRole} objContent={objContent} />
            </Grid>
          )}
          {(objContent.posImg === "left" || objContent.posImg === "right") && (
            <Grid item md={5}>
              <TextBox
                objContent={objContent}
                userRole={userRole}
                handleClick={handleClick}
              />
            </Grid>
          )}

          {objContent.posImg === "right" && (
            <Grid item md={7}>
              <Img userRole={userRole} objContent={objContent} />
            </Grid>
          )}
        </Grid>

        {/* posImg center */}
        {objContent.posImg === "center-top" && objContent.img && (
          <Stack
            sx={{ mb: userRole === "5" ? "1.25rem" : "2.25rem", width: "100%" }}
          >
            <Img userRole={userRole} objContent={objContent} imgCenter={true} />
          </Stack>
        )}
        {objContent.posImg !== "left" && objContent.posImg !== "right" && (
          <Stack sx={{ m: "0 auto", maxWidth: "70%" }}>
            <TextBox
              objContent={objContent}
              imgCenter={true}
              userRole={userRole}
              handleClick={handleClick}
            />
          </Stack>
        )}
        {objContent.posImg === "center-bottom" && objContent.img && (
          <Stack
            sx={{
              mt: userRole === "5" ? "1.25rem" : "2.25rem",
              width: "100%",
            }}
          >
            <Img userRole={userRole} objContent={objContent} imgCenter={true} />
          </Stack>
        )}
      </Grid>
    </Stack>
  );
};

const Img = ({ userRole, imgCenter, objContent }) => {
  return (
    <img
      alt={`${objContent.id}`}
      src={
        objContent.img
          ? objContent.img
          : !imgCenter && !objContent.img
          ? imgDefault
          : null
      }
      style={{
        // border: "1px solid red",
        opacity: objContent.img ? 1 : 0,
        maxWidth: !imgCenter ? null : "70%",
        width: !imgCenter ? "100%" : null,
        height: !imgCenter ? "auto" : null,
        margin: "0 auto",
        // aspectRatio: "16/9",
        borderRadius: userRole === "5" ? "2.5rem" : "5rem",
        objectPosition: "center",
        objectFit: !imgCenter ? "cover" : "contain",
      }}
    />
  );
};

const TextBox = ({ imgCenter, objContent, userRole, handleClick }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: !objContent.img && imgCenter ? "relative" : null,
      }}
    >
      {!objContent.img && imgCenter && (
        <img src={imgDefault} alt="" style={{ width: "80%", opacity: 0 }} />
      )}
      <Box
        sx={{
          position: !objContent.img && imgCenter ? "absolute" : null,
          left: 0,
          top: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          alignItems:
            objContent.alignText === "left"
              ? "flex-start"
              : objContent.alignText === "right"
              ? "flex-end"
              : "center",
          // backgroundColor: "red",
        }}
      >
        {objContent.title && (
          <Typography
            component="h1"
            variant={
              userRole === "5" && imgCenter
                ? "subtitle1"
                : userRole === "5" && !imgCenter
                ? "body2"
                : userRole !== "5" && imgCenter
                ? "h2"
                : "h3"
            }
            sx={{
              fontWeight: 700,
              textAlign:
                objContent.alignText === "left"
                  ? "start"
                  : objContent.alignText === "right"
                  ? "end"
                  : objContent.alignText === "justify"
                  ? "justify"
                  : "center",
              color: objContent.bgImg
                ? "#ffff"
                : `${theme.palette.primary.main}`,
            }}
          >
            {objContent.title}
          </Typography>
        )}
        {objContent.subtitle && (
          <Typography
            component={userRole === "5" ? "p" : "h2"}
            // variant={imgCenter ? "subtitle1" : "body1"}
            variant={
              userRole === "5" && imgCenter
                ? "caption2"
                : userRole === "5" && !imgCenter
                ? "caption2"
                : userRole !== "5" && imgCenter
                ? "subtitle1"
                : "body1"
            }
            sx={{
              mt: "0.5rem",
              fontSize: userRole === "5" && !imgCenter ? 8 : null,
              fontWeight: 500,
              textAlign:
                objContent.alignText === "left"
                  ? "start"
                  : objContent.alignText === "right"
                  ? "end"
                  : objContent.alignText === "justify"
                  ? "justify"
                  : "center",
              color: objContent.bgImg
                ? "#ffff"
                : `${theme.palette.text.tertiary}`,
            }}
          >
            {objContent.subtitle}
          </Typography>
        )}
        <Button
          size={userRole === "5" ? "small" : "medium"}
          variant="contained"
          color="primary"
          sx={{
            mt: userRole === "5" ? "0.75rem" : "1.5rem",
            fontSize: userRole === "5" ? 8 : null,
            fontWeight: 700,
            transition: "all linear .4s",
            borderRadius: "5rem",
            width: "fit-content",
            "&:hover": {
              backgroundColor: `${theme.palette.primary.main}`,
              transform: "scale(1.05)",
            },
          }}
          onClick={userRole === "5" ? null : handleClick}
        >
          {objContent.buttonText}
        </Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  libPages: state.nav.libPages,
  contentPreview: state.preview.contentPreview,
  contentHoverId: state.preview.contentHoverId,
});

export default connect(mapStateToProps)(SectionHero02);
