import React, { useState } from "react";
import { connect } from "react-redux";
import { setNavPreview } from "src/redux/actions";
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  useTheme,
  styled,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
} from "@mui/material";
import {
  Add,
  ArrowDropDown,
  ArrowRight,
  Delete,
  Edit,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import "src/App.css";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "20px !important",
  },
}));

const CustomTextField = styled(TextField)(() => ({
  ".MuiOutlinedInput-root": {
    height: "40px",
    fontSize: 14,
    borderColor: "#212121",
    borderWidth: "0.1px",
    input: { "&::placeholder": { fontSize: 14 } },
  },
  "&:hover fieldset": {
    borderColor: "#212121",
    borderWidth: "0.1px",
  },
}));

const Panel2 = ({
  openPanel2,
  setOpenPanel2,
  nav,
  navPreview,
  navFiltered,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [validate, setValidate] = useState(false);
  const [editNav, setEditNav] = useState(false);
  const [addNav, setAddNav] = useState(false);
  const [form, setForm] = useState({});
  const [formAdd, setFormAdd] = useState({ id: "", title: "" });
  const [hoverId, setHoverId] = useState("");

  const handleChangeEdit = (e) => {
    const copyForm = { ...form };
    copyForm[e.target.name] = e.target.value;
    setForm(copyForm);
  };

  const handleEditNav = () => {
    setValidate(true);
    if (!form.title) return;

    const arr = handle("edit");
    setNavPreview(arr);
    setEditNav(false);
  };

  const handle = (str) => {
    const x = str === "edit" ? form : formAdd;

    const headLink = "/v2/user/";
    const value = x.title.toLowerCase().split(" ").join("-");

    const arr = [...navPreview];
    let idx = arr.findIndex((obj) => obj.id === x.id);

    if (arr[idx].page === "product") arr[idx]["link"] = `${headLink}${value}`;
    else if (arr[idx].page === "dashboard")
      arr[idx]["link"] = `${headLink}dashboard/${value}`;
    arr[idx]["title"] = x.title;
    return arr;
  };

  const handleDeleteNav = (id) => {
    const arr = [...navPreview];
    let idx = arr.findIndex((obj) => obj.id === id);
    arr[idx].title = "";

    const headLink = "/v2/user/";
    if (arr[idx].page === "product") arr[idx]["link"] = `${headLink}`;
    else if (arr[idx].page === "dashboard")
      arr[idx]["link"] = `${headLink}dashboard/`;
    setNavPreview(arr);
  };

  const handleChangeAdd = (e) => {
    const copyFormAdd = { ...formAdd };
    copyFormAdd[e.target.name] = e.target.value;
    setFormAdd(copyFormAdd);
  };

  const handleAddNav = () => {
    setValidate(true);
    if (!formAdd.title) return;

    const arr = handle("add");
    setNavPreview(arr);
    setAddNav(false);
    setFormAdd({ id: "", title: "" });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "325px",
        }}
      >
        <Typography
          component="p"
          variant="body2"
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: 600,
            transition: "all linear .4s",
            pt: 1,
            pb: 1,
            "&:hover": { cursor: "pointer" },
          }}
          onClick={() => setOpenPanel2(!openPanel2)}
        >
          {openPanel2 && (
            <ArrowDropDown sx={{ mr: 1 }} className={classes.root} />
          )}
          {!openPanel2 && (
            <ArrowRight sx={{ mr: 1 }} className={classes.root} />
          )}
          Header
        </Typography>
        {openPanel2 && navFiltered.length > 0 && (
          <Tooltip title="Add Nav">
            <IconButton
              sx={{ color: `${theme.palette.blue.main}`, mr: 1 }}
              onClick={() => {
                setEditNav(false);
                setAddNav(!addNav);
                setValidate(false);
              }}
            >
              <Add className={classes.root} />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      {openPanel2 && (
        <Box sx={{ transition: "all linear .4s" }}>
          {nav
            .filter((x) => x.title)
            .map((v, i) => (
              <Paper
                key={i}
                elevation={2}
                sx={{
                  width: "325px",
                  mt: i === 0 ? 1 : 0,
                  mb: 1,
                  p: 1,
                  pl: 2,
                  pr: 2,
                  border:
                    hoverId === v.id
                      ? `1px solid ${theme.palette.blue.main}`
                      : "#ffff",
                  backgroundColor: "#ffff",
                  borderRadius: "1rem",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onMouseOver={() => setHoverId(v.id)}
                onMouseOut={() => setHoverId("")}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent:
                      editNav !== `paper${i}` ? "space-between" : "center",
                    alignItems: "center",
                  }}
                >
                  {editNav !== `paper${i}` && (
                    <Typography
                      variant="body2"
                      noWrap={true}
                      sx={{ fontWeight: 500 }}
                    >
                      {v.title}
                    </Typography>
                  )}

                  {editNav === `paper${i}` ? (
                    <Button
                      size="normal"
                      variant="outlined"
                      color="blue"
                      sx={{
                        fontWeight: 600,
                        borderRadius: "5rem",
                        border: "none",
                        "&:hover": {
                          border: "none",
                        },
                      }}
                      onClick={() => handleEditNav(v.id)}
                    >
                      Apply
                    </Button>
                  ) : (
                    <Box>
                      <Tooltip title="Edit Nav">
                        <IconButton
                          sx={{
                            color: `${theme.palette.blue.main}`,
                          }}
                          onClick={() => {
                            setValidate(false);
                            setEditNav(`paper${i}`);
                            setAddNav(false);
                            setForm(v);
                          }}
                        >
                          <Edit className={classes.root} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Nav">
                        <IconButton
                          sx={{
                            color: `${theme.palette.red.main}`,
                          }}
                          onClick={() => handleDeleteNav(v.id)}
                        >
                          <Delete className={classes.root} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                {editNav === `paper${i}` && (
                  <>
                    <CustomTextField
                      placeholder="name"
                      name="title"
                      variant="outlined"
                      color="black"
                      hiddenLabel
                      fullWidth
                      error={validate && !form.title}
                      helperText={
                        validate && !form.title && "* name cannot be null"
                      }
                      value={form.title}
                      size="small"
                      sx={{ m: `${theme.spacing(1)} 0` }}
                      onChange={handleChangeEdit}
                    />
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        navigate to
                      </Typography>
                      <FormControl
                        variant="outlined"
                        hiddenLabel
                        size="small"
                        color="black"
                        sx={{ m: `${theme.spacing(1)} 0` }}
                      >
                        <Select
                          name="id"
                          value={v.id}
                          onChange={handleChangeEdit}
                          inputProps={{
                            MenuProps: {
                              MenuListProps: {
                                sx: {
                                  backgroundColor: "#ffff",
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem value={v.id}>
                            {"Page " + v.defaultTitle}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </>
                )}
              </Paper>
            ))}

          {addNav && (
            <Paper
              elevation={2}
              sx={{
                width: "325px",
                mt: 1,
                p: 1,
                pl: 2,
                pr: 2,
                backgroundColor: "#ffff",
                transition: "all linear .4s",
                borderRadius: "1rem",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  size="normal"
                  variant="outlined"
                  color="blue"
                  sx={{
                    fontWeight: 600,
                    borderRadius: "5rem",
                    border: "none",
                    "&:hover": {
                      border: "none",
                    },
                  }}
                  onClick={() => handleAddNav()}
                >
                  Apply
                </Button>
              </Box>
              <CustomTextField
                placeholder="name"
                name="title"
                variant="outlined"
                color="black"
                hiddenLabel
                fullWidth
                error={validate && !formAdd.title}
                helperText={
                  validate && !formAdd.title && "* name cannot be null"
                }
                value={formAdd.title}
                size="small"
                sx={{ m: `${theme.spacing(1)} 0` }}
                onChange={handleChangeAdd}
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2" sx={{ ml: 1 }}>
                  navigate to
                </Typography>
                <FormControl
                  variant="outlined"
                  hiddenLabel
                  size="small"
                  color="black"
                  sx={{ m: `${theme.spacing(1)} 0` }}
                >
                  <Select
                    name="id"
                    value={formAdd.id}
                    onChange={handleChangeAdd}
                    inputProps={{
                      MenuProps: {
                        MenuListProps: {
                          sx: {
                            backgroundColor: "#ffff",
                          },
                        },
                      },
                    }}
                  >
                    {navFiltered.map((v) => (
                      <MenuItem key={v.id} value={v.id}>
                        {"Page " + v.defaultTitle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Paper>
          )}
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  navPreview: state.preview.navPreview,
});

export default connect(mapStateToProps)(Panel2);
