import React, { useState, useEffect } from "react";
import localforage from "localforage";
import { connect } from "react-redux";
import {
  setArticlePreview,
  setTestiPreview,
  setGalPreview,
  refTags,
  uploadFoto,
  galleryAPI,
  testimonyAPI,
  articleAPI,
  setIsChange,
} from "src/redux/actions";
import {
  Box,
  useTheme,
  Button,
  Stack,
  Typography,
  styled,
  Dialog,
  DialogContent,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Chip,
} from "@mui/material";
import RichEditor from "src/functions/editor";
import {
  InputFieldArticle,
  InputFieldGalery,
  InputFieldTesti,
} from "./ComponentGroup";
import {
  formAddArt,
  formAddGalery,
  formAddTesti,
} from "src/components/Layout/data";
import ReactHtmlParser from "react-html-parser";
import { snackbarFunc } from "src/functions/snackbarFunc";
import { parsedDate } from "src/functions/parsedDate";
import { Delete } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { domain } from "src/components/Layout/data";
import "src/App.css";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "18px !important",
  },
}));

const CustomCircularProgress = styled(CircularProgress)(({ theme }) => ({
  "&.MuiCircularProgress-root": {
    height: "15px !important",
    width: "15px !important",
    display: "flex",
    color: `${theme.palette.blue.main}`,
  },
}));

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const ModalEditor = ({
  open,
  onClose,
  namePanel,
  dataId,
  dataModal,
  themePreview,
  galPreview,
  isChange,
  handleOpenModal,
}) => {
  const theme = useTheme();
  const [active, setActive] = useState("edit");
  const [websiteFor, setWebsiteFor] = useState("");
  const [form, setForm] = useState({});
  const [content, setContent] = useState("");
  const [validate, setValidate] = useState(false);
  const [validateSize, setValidateSize] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [initialTagOptions, setInitialTagOptions] = useState([]);
  const [loadingTags, setLoadingTags] = useState(false);
  const [displayHover, setDisplayHover] = useState("");
  const [date, setDate] = useState(new Date().toISOString());
  const [token, setToken] = useState("");

  useEffect(() => {
    setContent("");
    setActive("edit");
    setValidate(false);
    setValidateSize(false);
    fetchDataTags();
    getInfo();
  }, [open]);

  const getInfo = async () => {
    const resTheme = themePreview.length
      ? themePreview
      : await localforage.getItem("theme");
    setWebsiteFor(resTheme.websiteFor);
    const resToken = await localforage.getItem("token");
    setToken(resToken);

    // get all data for gallery
    if (namePanel === "Galery") {
      const data = await galleryAPI("get", { token, domain });
      setGalPreview(data.data);
    }

    if (dataId) {
      if (namePanel === "Article") {
        const resArticle = await articleAPI("get/detail", {
          articleId: dataId,
        });
        if (resArticle.data) setForm(resArticle.data[0]);
        setContent(resArticle.data[0].content);
        return;
      }
      setForm(dataModal);
    } else {
      setForm(
        namePanel === "Article"
          ? formAddArt
          : namePanel === "Testimony"
          ? formAddTesti
          : formAddGalery
      );
    }
  };

  const handleChangeImage = async (e) => {
    if (e.target.files[0].size >= 1000000) {
      setValidateSize(true);
      return;
    }
    setValidateSize(false);
    let formData = new FormData();
    formData.append("token", token);
    formData.append("image", e.target.files[0]);
    formData.append("domain", domain);

    const resImage = await uploadFoto(formData);
    if (resImage.code === 200) {
      const copyForm = { ...form };
      const x = namePanel === "Article" ? "cover" : "photo";
      copyForm[x] = resImage.data.path;
      setForm(copyForm);
    }
  };

  const onEditorStateChange = (e) => {
    setContent(e);
  };

  const handleChange = (e) => {
    const copyForm = { ...form };
    copyForm[e.target.name] = e.target.value;
    setForm(copyForm);
  };

  const fetchDataTags = async (name) => {
    if (!name) {
      const resTags = await refTags("get", { domain });
      setTagOptions(resTags.data);
      setInitialTagOptions([...resTags.data]);
      return;
    }

    const filtered = initialTagOptions.filter(
      (v) => v.tag_name.toLowerCase().match(name.toLowerCase()) !== null
    );
    const isExisting = initialTagOptions.some((v) => name === v.tag_name);

    if (!isExisting) {
      let y = {};
      y.tag_id = 999;
      y.tag_name = `Create "${name}"`;
      y.tag_name_new = name;
      setTagOptions([...filtered, y]);
    } else {
      setTagOptions([...filtered]);
    }
  };

  const handleCreateTags = async (inputValue) => {
    setLoadingTags(true);
    const newOption = await refTags("add", {
      token,
      tagName: inputValue.tag_name,
      domain,
    });
    const copyForm = { ...form };
    const copyTags = [...form.tags, newOption.data];
    copyForm["tags"] = copyTags;
    setForm(copyForm);
    setLoadingTags(false);

    fetchDataTags();
  };

  const handleSelectTags = (d) => {
    const isExisting = [...form.tags].some((v) => v.tag_id === d.tag_id);
    if (isExisting) return;

    if (d.tag_id === 999) {
      d.tag_name = d.tag_name_new;
      handleCreateTags(d);
    }
    const copyForm = { ...form };
    const copyTags = [...form.tags, d];
    copyForm["tags"] = copyTags;
    setForm(copyForm);
  };

  const handleDeleteTag = (i) => {
    const copyForm = { ...form };
    const copyTags = [...form.tags];
    copyTags.splice(i, 1);
    copyForm["tags"] = copyTags;
    setForm(copyForm);
  };

  const handleApply = async () => {
    setValidate(true);
    let defaultData = namePanel === "Article" ? form.title : form.name;
    if (!defaultData) return;

    setLoading(true);
    setValidate(false);
    const obj = { token, domain };

    if (namePanel === "Galery") {
      const resData = await galleryAPI("add", { ...obj, ...form });
      snackbarFunc(resData, setLoading, "Added");
      const data = await galleryAPI("get", { token, domain });
      setGalPreview(data.data);
      setIsChange(!isChange.gal, "gal");
      setForm({});
      setLoading(false);
      return;
    }

    if (namePanel === "Testimony") {
      const dataParams = dataId
        ? { ...obj, testimonyId: dataId, ...form }
        : { ...obj, ...form };

      const resData = await testimonyAPI(dataId ? "update" : "add", dataParams);
      snackbarFunc(resData, setLoading, dataId ? "Updated" : "Added");
      const data = await testimonyAPI("get", { token, domain });
      setTestiPreview(data.data);
      setIsChange(!isChange.testi, "testi");
      window.location.reload();
    }

    if (namePanel === "Article") {
      const copyForm = { ...form };
      copyForm["content"] = content;

      const dataParams = dataId
        ? { ...obj, articleId: dataId, ...copyForm }
        : { ...obj, ...copyForm };

      const resData = await articleAPI(dataId ? "update" : "add", dataParams);
      snackbarFunc(resData, setLoading, dataId ? "Updated" : "Added");
      const data = await articleAPI("get", { token, domain });
      setArticlePreview(data.data);
      setIsChange(!isChange.art, "art");
    }
    setForm({});
    setLoading(false);
    onClose();
  };

  const text = (v) => {
    if (namePanel === "Article") {
      return v === "edit" ? "Write" : "Preview";
    } else {
      return v === "edit" ? "Add" : "List Image";
    }
  };

  return (
    <CustomDialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth={
        namePanel === "Article" ? "md" : namePanel === "Galery" ? "sm" : "xs"
      }
      scroll={"paper"}
    >
      <DialogContent>
        <Typography
          variant="body1"
          sx={{
            m: 1,
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          {`${
            !dataId && namePanel === "Galery"
              ? "Detail"
              : dataId
              ? "Edit"
              : "Add"
          } ${namePanel}`}
        </Typography>

        {namePanel === "Testimony" && (
          <>
            <InputFieldTesti
              form={form}
              handleChange={handleChange}
              handleChangeImage={handleChangeImage}
              validateSize={validateSize}
              validate={validate}
            />
            <ButtonApply
              loading={loading}
              handleApply={handleApply}
              active={active}
            />
          </>
        )}

        {namePanel !== "Testimony" && (
          <Stack direction="column" sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "fit-content",
              }}
            >
              {["edit", "preview"].map((v) => (
                <Typography
                  key={v}
                  variant="body2"
                  sx={{
                    ml: v === "edit" ? 2 : 1,
                    mb: 0,
                    transition: "all linear .3s",
                    p: `${theme.spacing(1)} ${theme.spacing(2)}`,
                    borderRadius: "0.75rem",
                    pb: active === v ? 0 : 1,

                    borderBottomLeftRadius: active === v ? 0 : "0.75rem",
                    borderBottomRightRadius: active === v ? 0 : "0.75rem",

                    fontWeight: active === v ? 600 : 500,
                    backgroundColor: active === v ? "#FAFAFA" : "transparent",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => setActive(v)}
                >
                  {text(v)}
                </Typography>
              ))}
            </Box>
            <Box
              sx={{
                m: 2,
                mt: 0,
                mb: 0,
                p: 2,
                transition: "all linear .4s",
                backgroundColor: "#F6F8FD",
                borderRadius: "1rem",
                borderTopLeftRadius: active === "edit" ? 0 : "1rem",
              }}
            >
              {active === "edit" && (
                <>
                  {namePanel === "Article" && (
                    <EditArticle
                      onEditorStateChange={onEditorStateChange}
                      form={form}
                      validate={validate}
                      validateSize={validateSize}
                      optionsArr={tagOptions}
                      loadingTags={loadingTags}
                      content={content}
                      websiteFor={websiteFor}
                      namePanel={namePanel}
                      fetchData={fetchDataTags}
                      handleChange={handleChange}
                      handleSelectTags={handleSelectTags}
                      handleDeleteTag={handleDeleteTag}
                      handleChangeImage={handleChangeImage}
                    />
                  )}
                  {namePanel === "Galery" && (
                    <InputFieldGalery
                      form={form}
                      validate={validate}
                      validateSize={validateSize}
                      handleChange={handleChange}
                      handleChangeImage={handleChangeImage}
                      handleDeleteTag={handleDeleteTag}
                      handleSelectTags={handleSelectTags}
                      fetchData={fetchDataTags}
                      loadingTags={loadingTags}
                      optionsArr={tagOptions}
                    />
                  )}
                </>
              )}
              {active === "preview" && (
                <>
                  {namePanel === "Article" && (
                    <PreviewArticle
                      websiteFor={websiteFor}
                      form={form}
                      dataId={dataId}
                      date={date}
                      content={content}
                    />
                  )}
                  {namePanel === "Galery" && (
                    <PreviewGalery
                      galPreview={galPreview}
                      websiteFor={websiteFor}
                      displayHover={displayHover}
                      setDisplayHover={setDisplayHover}
                      handleOpenModal={handleOpenModal}
                      namePanel={namePanel}
                    />
                  )}
                </>
              )}
            </Box>
            <ButtonApply
              loading={loading}
              handleApply={handleApply}
              active={active}
            />
          </Stack>
        )}
      </DialogContent>
    </CustomDialog>
  );
};

const ButtonApply = ({ loading, handleApply, active }) => {
  return (
    <Stack
      direction="row"
      sx={{ display: active === "edit" ? "flex" : "none" }}
    >
      <Button
        size="normal"
        variant="outlined"
        color="blue"
        disabled={loading ? true : false}
        startIcon={loading ? <CustomCircularProgress /> : null}
        sx={{
          width: "175px",
          m: "auto",
          mt: 3,
          mb: 1,
          fontWeight: 600,
          borderRadius: "0.75rem",
        }}
        onClick={handleApply}
      >
        Apply
      </Button>
    </Stack>
  );
};

const EditArticle = ({
  onEditorStateChange,
  form,
  validate,
  validateSize,
  optionsArr,
  loadingTags,
  content,
  websiteFor,
  namePanel,
  imgPreview,
  fetchData,
  handleChange,
  handleSelectTags,
  handleDeleteTag,
  handleChangeImage,
}) => {
  return (
    <>
      <InputFieldArticle
        form={form}
        validate={validate}
        validateSize={validateSize}
        handleChange={handleChange}
        optionsArr={optionsArr}
        loadingTags={loadingTags}
        imgPreview={imgPreview}
        fetchData={fetchData}
        handleSelectTags={handleSelectTags}
        handleDeleteTag={handleDeleteTag}
        handleChangeImage={handleChangeImage}
      />

      <RichEditor
        onChange={onEditorStateChange}
        value={content}
        websiteFor={websiteFor}
        namePanel={namePanel}
      />
    </>
  );
};

const PreviewArticle = ({ websiteFor, form, dataId, date, content }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: 2,
        fontFamily: websiteFor === "recruitment" ? "Montserrat" : "Poppins",
      }}
      className="box-preview"
    >
      <Typography component="h2" variant="h3" sx={{ fontWeight: 600 }}>
        {form.title}
      </Typography>
      <Stack
        direction="row"
        sx={{
          mt: 2,
          mb: 2,
          display: form.title ? "flex" : "none",
        }}
      >
        {/* <Typography
          variant="body2"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          {form.creator}
        </Typography>
        <Typography variant="body2" sx={{ ml: "0.25rem" }}>
          - {parsedDate(dataId ? form.created_at : date, "detail-article")}
        </Typography> */}
        <Typography
          variant="body2"
          sx={{ color: `${theme.palette.primary.main}` }}
        >
          {form.creator}{" "}
          <span style={{ color: "#212121" }}>
            - {parsedDate(form.created_at, "detail-article")}
          </span>
        </Typography>
      </Stack>
      {ReactHtmlParser(content)}
      <Typography
        variant="h5"
        sx={{
          mt: 3,
          color: `${theme.palette.primary.main}`,
          fontStyle: "italic",
        }}
      >
        TAGS
      </Typography>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {form.tags.map((v) => (
          <Grid item key={v.tag_id}>
            <Chip label={v.tag_name} color="primary" />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const PreviewGalery = ({
  websiteFor,
  displayHover,
  setDisplayHover,
  galPreview,
  handleOpenModal,
  namePanel,
}) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        p: 2,
        fontFamily: websiteFor === "recruitment" ? "Montserrat" : "Poppins",
      }}
      className="box-preview"
    >
      <Grid container spacing={2}>
        {galPreview.map((v) => (
          <Grid item md={6} key={v.gallery_id}>
            <Paper
              elevation={2}
              sx={{
                backgroundColor: "#ffff",
                borderRadius: "0.75rem",
                position: "relative",
                transition: "all linear .4s",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onMouseOver={() => setDisplayHover(v.gallery_id)}
              onMouseOut={() => setDisplayHover("")}
            >
              <Box
                sx={{
                  backgroundImage: `url(${v.photo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  aspectRatio: "16/9",
                  width: "100%",
                  borderRadius: "0.75rem",
                  position: "relative",
                }}
              ></Box>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  display: displayHover === v.gallery_id ? "flex" : "none",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  borderRadius: "0.75rem",
                  width: "100%",
                  height: "100%",
                  p: 2,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1,
                }}
              ></Stack>
              <Tooltip title="Delete this image">
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    top: 0,
                    right: 0,
                    backgroundColor: "#ffff",
                    borderRadius: "15px",
                    p: 2,
                    width: "15px",
                    height: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    handleOpenModal(namePanel, v.gallery_id, null, "delete")
                  }
                >
                  <Delete color="red" className={classes.root} />
                </Box>
              </Tooltip>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  themePreview: state.preview.themePreview,
  galPreview: state.preview.galPreview,
  isChange: state.preview.isChange,
});

export default connect(mapStateToProps)(ModalEditor);
