import React from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  useTheme,
  Paper,
  Stack,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import image from "src/assets/img/images7.jpg";
import "src/App.css";
import { FaQuoteLeft } from "react-icons/fa";

const CardTes01 = ({ v, loading, userRole, objContent }) => {
  const theme = useTheme();
  const isScreen1536 = useMediaQuery("(min-width:1536px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");

  return (
    <Grid
      item
      xs={12}
      sx={{
        p: userRole === "5" ? "0.65rem" : "0.85rem",
        pt: userRole === "5" ? "0.95rem" : "1rem",
        pb: userRole === "5" ? "0.95rem" : "1rem",
      }}
    >
      <Paper
        sx={{
          backgroundColor: "#ffff",
          p: userRole === "5" ? 1 : 2,
          borderRadius: userRole === "5" ? "0.5rem" : "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FaQuoteLeft
          style={{
            color: `${theme.palette.primary.main}`,
            width: userRole === "5" ? "8px" : null,
            marginBottom: userRole === "5" ? 0 : "0.25rem",
          }}
        />
        {loading && (
          <>
            {[1, 2, 3].map((i) => (
              <Skeleton
                key={i}
                variant="rectangular"
                sx={{
                  height: "0.75rem",
                  mt: 1,
                }}
              />
            ))}
          </>
        )}

        {!loading && (
          <>
            <Typography
              className={userRole === "5" ? "multi-line-tes-adm" : null}
              variant={userRole === "5" ? "caption2" : "body2"}
              sx={{
                fontSize: userRole === "5" ? 8 : null,
                overflow: "auto",
                height: userRole === "5" ? null : "12rem",
              }}
            >
              {v.content}
            </Typography>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 1 }}
            >
              <Grid
                item
                xs={userRole === "5" ? 9 : 8}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                  variant={userRole === "5" ? "caption2" : "body2"}
                  sx={{
                    fontWeight: userRole === "5" ? 600 : 700,
                    fontSize: userRole === "5" ? 8 : null,
                  }}
                >
                  {v.name}
                </Typography>
                <Typography
                  color="primary"
                  variant={userRole === "5" ? "caption2" : "body2"}
                  sx={{ fontSize: userRole === "5" ? 8 : null }}
                >
                  {v.position}
                </Typography>
              </Grid>
              <Grid
                item
                xs={userRole === "5" ? 3 : 4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <img
                  src={v.photo}
                  alt={v.testimony_id}
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    height: userRole === "5" ? "1.5rem" : "3rem",
                    width: userRole === "5" ? "1.5rem" : "3rem",
                    borderRadius: userRole === "5" ? "1rem" : "3rem",
                    border: `1px solid ${theme.palette.primary.main}`,
                  }}
                />
              </Grid>
            </Stack>
          </>
        )}
      </Paper>
    </Grid>
    // <Grid
    //   item
    //   xs={12}
    //   sx={{
    //     mt: userRole === "5" ? "1rem" : "2rem",
    //     mb: userRole === "5" ? "1rem" : "2rem",
    //     ml: userRole === "5" ? 1 : 2,
    //     // mr: i % 2 === 0 ? 1 : 2,
    //     mr: userRole === "5" ? 1 : 2,
    //     // position: "relative",
    //   }}
    // >
    //   <Paper
    //     sx={{
    //       backgroundColor: "#ffff",
    //       borderRadius: userRole === "5" ? "0.5rem" : "1rem",
    //       position: "relative",
    //       transition: "all linear .2s",
    //       "&:hover": {
    //         color: "#ffff",
    //         cursor: "pointer",
    //         background: `${theme.palette.primary.main}`,
    //       },
    //     }}
    //   >
    //     <Box
    //       sx={{
    //         position: "absolute",
    //         top: userRole === "5" ? "-1rem" : "-2rem",
    //         display: "flex",
    //         justifyContent:
    //           objContent.alignInnerCard === "center"
    //             ? "center"
    //             : objContent.alignInnerCard === "left"
    //             ? "left"
    //             : "right",
    //         alignItems: "center",
    //         width: "100%",
    //         pl: 2,
    //         pr: 2,
    //         // backgroundColor: "red",
    //       }}
    //     >
    //       <img
    //         src={image}
    //         alt="img"
    //         style={{
    //           width: userRole === "5" ? "2rem" : "4rem",
    //           height: userRole === "5" ? "2rem" : "4rem",
    //           borderRadius: userRole === "5" ? "2rem" : "4rem",
    //           objectFit: "cover",
    //           boxShadow:
    //             "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px",
    //         }}
    //       />
    //       {/* <Box
    //         sx={{
    //           backgroundImage: `url(${image})`,
    //           backgroundSize: "cover",
    //           width: "4rem",
    //           height: "4rem",
    //           borderRadius: "4rem",
    //           top: "0",
    //           right: "2.5rem",
    //           zIndex: 4,
    //           position: "absolute",
    //           boxShadow:
    //             "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px",
    //         }}
    //       ></Box> */}
    //     </Box>
    //     {/* <Stack></Stack> */}
    //     {/* <Grid item container sx={{ border: "1px solid red" }}> */}
    //     {/* <Grid
    //         item
    //         xs={4}
    //         sx={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //         }}
    //       >
    //         <Box
    //           sx={{
    //             backgroundImage: `url(${image})`,
    //             backgroundSize: "cover",
    //             height: "6.5rem",
    //             width: "6.5rem",
    //             borderRadius: "25rem",
    //           }}
    //         ></Box>
    //       </Grid> */}
    //     {/* <Grid item xs={8} sx={{ pl: 2, textAlign: "center" }}> */}
    //     <Stack
    //       sx={{
    //         width: "100%",
    //         pt: userRole === "5" ? "1rem" : "2rem",
    //         pr: userRole === "5" ? 2 : 3,
    //         pl: userRole === "5" ? 2 : 3,
    //         pb: userRole === "5" ? 1 : 2,
    //       }}
    //     >
    //       <Typography
    //         component="p"
    //         variant={userRole === "5" ? "caption2" : "body2"}
    //         className={userRole === "5" ? "multi-line-tes-adm" : null}
    //         sx={{
    //           // border: "1px solid red",
    //           mt: userRole === "5" ? 1 : 2,
    //           height: userRole === "5" ? null : "9rem", //2.75 4
    //           // overflowY: "auto",
    //           fontSize: userRole === "5" ? 8 : null,
    //           textAlign:
    //             objContent.alignInnerCard === "center"
    //               ? "center"
    //               : objContent.alignInnerCard === "left"
    //               ? "left"
    //               : "right",
    //         }}
    //       >
    //         {v.text}
    //       </Typography>
    //       <Typography
    //         component="p"
    //         variant={userRole === "5" ? "caption2" : "body2"}
    //         sx={{
    //           // border: "1px solid red",
    //           mt: userRole === "5" ? 1 : 2,
    //           pt: userRole === "5" ? 1 : 2,
    //           fontWeight: 600,
    //           fontSize: userRole === "5" ? 8 : null,
    //           borderTop: "1.5px solid lightgrey",
    //           textAlign:
    //             objContent.alignInnerCard === "center"
    //               ? "center"
    //               : objContent.alignInnerCard === "left"
    //               ? "left"
    //               : "right",
    //         }}
    //       >
    //         {v.name} <br />
    //         <span
    //           style={{
    //             fontWeight: "normal",
    //             fontSize: userRole === "5" ? 6 : 12,
    //           }}
    //         >
    //           {v.position}
    //         </span>
    //       </Typography>
    //     </Stack>
    //     {/* <Typography
    //       component="p"
    //       variant="body2"
    //       sx={{
    //         mt: 2,
    //         mb: 2,
    //         pb: 2,
    //         textAlign: "right",
    //         borderBottom: "1.5px solid lightgrey",
    //       }}
    //     >
    //       Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo,
    //       magnam, ipsam vel suscipit quae debitis consectetur natus nihil, nulla
    //       repellendus doloribus quasi. Eius vel dolorem sit hic. Illum, nemo
    //       iste.
    //     </Typography>
    //     <Typography
    //       component="p"
    //       variant="body2"
    //       sx={{ fontWeight: 600, textAlign: "right" }}
    //     >
    //       Jane Eyre <br />
    //       <span style={{ fontWeight: "normal" }}>Head of Lorem Ipsum</span>
    //     </Typography> */}
    //     {/* </Grid> */}
    //     {/* </Grid> */}
    //   </Paper>
    // </Grid>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(CardTes01);
