import store from "../store";
import {
  AUTHENTICATED,
  DEAUTHENTICATED,
  SET_OPEN_LOGIN,
  SET_MIDDLEWARE_APPS,
} from "../events";
import axiosGlobal from "../../axios-global";
import { config } from "src/configs/firebaseConfig";
import { initializeApp } from "firebase/app";
import localforage from "localforage";

import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";

initializeApp(config);
const auth = getAuth();

const { dispatch } = store;

export const loginForm = async (form) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/login/cms", form);
    if (data) {
      const authStatus = data.data?.data?.authStatus;
      const userStatus = data.data?.data?.userStatus;
      const userRoles = data.data?.data?.userRoles;

      if (authStatus) {
        if (userRoles.length > 1) {
          setMiddlewareApps(true, userRoles, form, data.data);
        } else {
          console.log({ userStatus });
          // kalau ga multi role, masuk kayak biasa aja
          if (userStatus === "1" || userStatus === "3") {
            const dataLoginInst = await loginInst(data.data.data.token);
            return dataLoginInst;
          } else {
            // 5, 4
            await localforage.setItem("userRole", userStatus);
            return data.data;
          }
        }
      }
    }
  } catch (err) {
    console.log(err.response);
    return err.response.data;
  }
};

export const loginInst = async (token) => {
  const bio = await localforage.getItem("bio");
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/institute/${bio.inst_id}`, { token });
    if (data) {
      window.open(
        `https://sentinel.shratech.com/sentinel/pages/login?token=${token}&tokenInst=${data?.data?.data?.tokenInst}&ecommerceUrl=${window.location.href}`,
        // `${window.location.origin}/sentinel/pages/login?token=${token}&tokenInst=${data?.data?.data?.tokenInst}&ecommerceUrl=${window.location.href}`,
        "_self"
      );
    }
  } catch (err) {
    console.log(err.response);
    return err.response.data;
  }
};

export const loginJp = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/jp/login", params);
    if (data) {
      const { token, fullname, email, photo } = data?.data?.data;
      localStorage.setItem("tokenJobPosting", token);
      dispatch({
        type: AUTHENTICATED,
        payload: {
          isAuth: true,
          token,
          profile: {
            fullname,
            email,
            photo,
          },
        },
      });
      return data.data;
    }
  } catch (err) {
    console.log(err.response);
    return err.response.data;
  }
};

export const registerForm = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/junior/register", params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err.response);
    return err.response.data;
  }
};

export const setOpenLogin = (openLogin) => {
  dispatch({
    type: SET_OPEN_LOGIN,
    payload: openLogin,
  });
};

export const setMiddlewareApps = (isOpen, data, form, resLogin) => {
  dispatch({
    type: SET_MIDDLEWARE_APPS,
    payload: { open: isOpen, data, form, resLogin },
  });
};

export const setAuthenticated = async (token) => {
  await localforage.setItem("token", token);
  dispatch({
    type: AUTHENTICATED,
    payload: {
      isAuthenticated: true,
      token,
    },
  });
};

export const setDeauthenticated = async () => {
  await localforage.removeItem("token");
  await localforage.removeItem("userRole");
  await localforage.removeItem("fullname");
  await localforage.removeItem("email");
  dispatch({
    type: DEAUTHENTICATED,
    payload: {
      isAuthenticated: false,
      token: null,
    },
  });
};

export const loginGoogle = async () => {
  try {
    const axios = await axiosGlobal();
    const provider = new GoogleAuthProvider();
    const resGoogle = await signInWithPopup(auth, provider);
    const email = resGoogle.user.email;
    await localforage.setItem("fullname", resGoogle.user.displayName);
    await localforage.setItem("email", email);

    const resAuthEmail = await axios.post("/emailAuth/cms", { email });
    console.log(resAuthEmail);
    if (resAuthEmail) {
      const authStatus = resAuthEmail.data.data.authStatus;
      const userStatus = resAuthEmail.data.data.userStatus;

      if (authStatus) {
        if (userStatus === "1" || userStatus === "3") {
          const dataLoginInst = await loginInst(resAuthEmail.data.data.token);
          return dataLoginInst;
        } else {
          // 5, 4
          await localforage.setItem("userRole", userStatus);
          return resAuthEmail.data;
        }
      }
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};
