import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import localforage from "localforage";
// import b1 from "src/assets/img/header-bg.png"; // 1280 x 525 110kb
// import b2 from "src/assets/img/background.jpg"; // 4244 x 2830 1.43mb
// import b3 from "src/assets/img/regis.svg"; // 1024 x 768
 
import b13 from "src/assets/img/polygon-scatter-haikei (1).png"; // 1280 x 320
import b14 from "src/assets/img/polygon-scatter-haikei.png"; // 1280 x 640
import b15 from "src/assets/img/low-poly-grid-haikei (1).svg"; // 1280 x 640
import b16 from "src/assets/img/low-poly-grid-haikei.svg"; // 1280 x 320

import { Box, Typography, useMediaQuery, Grid } from "@mui/material";
// import { getInfoInstitution } from "src/redux/actions";
import "src/App.css";

const Component001 = ({ link }) => {
  // const isScreen1024 = useMediaQuery("(min-width:1024px)");
  // const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    getBanner();
  }, [banner]);

  const getBanner = async () => {
    const carousel = [
      {
        urlDesktop:
          "https://colagio.shratech.com/assets/images/cover/prohelp_1_1_1.png",
        urlMobile: b14,
      }, //b13,
      {
        urlDesktop:
          "https://colagio.shratech.com/assets/images/cover/prohelp_2_1_1.png",
        urlMobile: b15,
      }, //b16
      {
        urlDesktop:
          "https://recruitment.pamapersada.com/assets/assets/images/home/Rev%201.png",
        urlMobile: b15,
      }, //b16
      {
        urlDesktop:
          "https://recruitment.pamapersada.com/assets/assets/images/home/Rev%202.png",
        urlMobile: b15,
      }, //b16
    ];

    // const res = await getInfoInstitution();
    setBanner(carousel); //res.data[0].carousel
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 5000, min: 1200 },
      items: 1,
      slidesToSlide: 1,
      // partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1199, min: 600 },
      items: 1,
      slidesToSlide: 1,
      // partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: 1,
      slidesToSlide: 1,
      // partialVisibilityGutter: 30,
    },
  };

  return (
    <Grid
      container
      component="section"
      id={link.split("#")[1]} //id={link.split("#")[1]}
      sx={{
        transition: "all linear .5s",
        // maxWidth: isScreen2000 ? "1536px" : null,
        // m: "auto",

        // maxWidth: isScreen2000 ? "1536px" : isScreen1400 ? "1200px" : "1024px",
        // m: "auto",
        // p: isScreen1024
        //   ? "2rem 0 1rem 0"
        //   : isScreen600
        //   ? "2rem 2rem 1rem 2rem"
        //   : "1rem",
      }}
    >
      <Carousel
        // partialVisible={true}
        swipeable={true}
        // autoPlaySpeed={15000}
        infinite={true} //
        // autoPlay={true}
        responsive={responsive}
        containerClass="carousel-q-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
      >
        {banner.map((v, i) => {
          return (
            <Box
              sx={{
                // background: `linear-gradient(to bottom right,  rgb(200,29,119,0.5), #AE98D6), url(${v.urlDesktop}) `, // beda arah aja ama yg bawah

                // background: `linear-gradient(to top, rgba(161, 140, 209, 0.3) 0%, rgba(251, 194, 235, 0.9) 100%), url(${v.urlDesktop}) `, // soft color fade night kalo gasalah

                // background: `linear-gradient(to right, rgba(184, 203, 184, 0.5) 0%, rgba(184, 203, 184, 0.5) 0%, rgba(180, 101, 218, 0.5) 0%, rgba(207, 108, 201, 0.5) 33%, rgba(238, 96, 156, 0.5) 66%, rgba(238, 96, 156, 0.5) 100%), url(${"https://colagio.shratech.com/assets/images/cover/prohelp_1_1_1.png"}) `, // warna banyak bener

                // background: `linear-gradient(to right, rgba(73, 28, 199, 0.75), rgba(200,29,119, 0.75)), url(${"https://colagio.shratech.com/assets/images/cover/prohelp_1_1_1.png"}) `, // ungu pink dari coolors co

                // background: `linear-gradient(to right, rgba(138,43,226, 0.75) ,rgba(4,98,174, 0.75)), url(${v.urlDesktop}) `, // ungu biru shratech

                // backgroundImage: `radial-gradient( rgba(255, 49, 3, 0.896), rgba(251, 255, 0, 0.75)), url('https://colagio.shratech.com/assets/images/cover/prohelp_1_1_1.png')`, // radial kuning oren ditengah

                // background: `linear-gradient(to right, rgba(153,0,145, 0.3), rgba(0,49,153, 0.3) ),   url(${v.urlDesktop}) `, // bagus ini 4 kanan kiri

                // background: `linear-gradient(to bottom right, rgba(30,0,153, 0.5), rgba(82,0,153, 0.5)),   url(${v.urlDesktop}) `, // kurang jauh maennya jd kek ungu beda 1 kanan kirishade

                // background: `linear-gradient(to bottom right, rgba(4,0,153,0.25), rgba(153,0,145, 0.5)),   url(${v.urlDesktop}) `, //
                backgroundImage: `url(${v.urlDesktop})`,
                width: "auto",
                // backgroundImage: `url(${
                //   isScreen1024
                //     ? v.urlDesktop
                //     : !isScreen1024 && v.urlMobile
                //     ? v.urlMobile
                //     : v.urlDesktop
                // })`,
                // height: "100%",
                height: "auto",
                backgroundSize: "cover",
                aspectRatio: isScreen1024 ? "2/1" : "2/1",
                // m: "0.5rem",
                mb: "0.5rem",
                backgroundPosition: "55% 65%",
                // borderRadius: isScreen2000
                //   ? "1.75rem"
                //   : isScreen1024
                //   ? "1.5rem"
                //   : "1.25rem",

                position: "relative",
                // "&::after": {
                //   content: '""',
                //   top: "0",
                //   left: "0",
                //   position: "absolute",
                //   width: "100%",
                //   height: "100%",
                //   backgroundImage: `linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%)`,
                //   backgroundImage:
                //     "linear-gradient(180deg, #2af598 0%, #009efd 100%)",
                //   backgroundImage:
                //     "linear-gradient( 135deg, #FFF5C3 10%, #9452A5 100%)",
                //   backgroundImage:
                //     "linear-gradient(to top, rgba(200,29,119, 1),rgba(161,28,199, 1))",
                // },
              }}
            >
              {/* <img
                key={i}
                // src={
                //   isScreen1024
                //     ? v.urlDesktop
                //     : !isScreen1024 && v.urlMobile
                //     ? v.urlMobile
                //     : v.urlDesktop
                // }
                alt="banner"
                style={{
                  padding: "0.5rem",
                  borderRadius: isScreen2000
                    ? "1.75rem"
                    : isScreen1024
                    ? "1.5rem"
                    : "1.25rem",
                  width: "100%",
                  // height: "600px",

                  // height: `calc(  100%  / 2  )`,
                  // height: "auto",
                  aspectRatio: isScreen1024 ? "4/1" : "2/1",
                  objectFit: "cover",
                  zIndex: 2,
                  // objectPosition: "50% 65%",
                  // mixBlendMode: "color-burn",
                  position: "relative",
                }}
              /> */}
            </Box>
          );
        })}
        {/* <Box
          sx={{
            backgroundImage: `url(${b11})`,
            width: "100%",
            // backgroundSize: "contain",
            aspectRatio: "4/1",
            // height: "auto",
            // height: "400px",
            // backgroundPosition: "center center",
            backgroundBlendMode: "overlay",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            // height: "320px",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            // display: flex;
            // align-items: center;
            // justify-content: center;
            // background-image: url(https://source.unsplash.com/random/1600x900?iran), linear-gradient(#151321, #2c2a44);
          }}
        ></Box> */}
        {/* <img
          // key={i}
          src={b12}
          alt="alt"
          style={{
            width: "100%",
            // height: `calc(  100vh  / 4  )`,
            // height: "100%",
            // height: "50%",
            height: "auto",
            // aspectRatio: "3/4",
            // height: '515px',
            backgroundColor: "red",
            // objectFit: "cover",
            objectPosition: "50% 65%",
            mixBlendMode: "color-burn",
          }}
        /> */}
      </Carousel>
      ;
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Component001);
