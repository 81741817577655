import { Typography, TextField } from "@mui/material";

const CustomInput = ({
  label,
  name,
  onChange,
  value,
  defaultValue,
  required,
  ...rest
}) => {
  return (
    <>
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>{`${label}${
        required ? "*" : ""
      }`}</Typography>
      <TextField
        value={value}
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        size="small"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        fullWidth
        style={{ marginBottom: "1rem" }}
        {...rest}
      />
    </>
  );
};

export default CustomInput;
