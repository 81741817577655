import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setIsPreview, setPagePreview } from "src/redux/actions";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import localforage from "localforage";
import PreviewPanel from "./PreviewPanel/index.js";
import EditPanel from "./EditPanel/index.js";
import Article from "src/pages/user/Article/index";
import "src/App.css";

const Index = ({ pagePreview }) => {
  const isScreen1536 = useMediaQuery("(min-width:1536px)");

  useEffect(() => {
    // setIsPreview(true);
  }, []);

  // const dataPage = ["home", "article", "galery"];

  return (
    // <Grid sx={{ backgroundColor: "#ffff" }}>
    //   <Article />
    // </Grid>

    <Grid
      container
      component="main"
      className={isScreen1536 ? "main-adminXL" : "main-admin"}
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        pt: "2rem",
      }}
    >
      <Grid item component="section" className="preview">
        <Stack
          className="preview-text"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: "1rem" }}
        >
          <Typography variant="h4" sx={{ mr: 2 }}>
            Preview
          </Typography>
          {/* <Stack direction="row" alignItems="center">
            <Typography variant="body2" sx={{ mr: 1 }}>
              Page
            </Typography>
            <FormControl
              variant="outlined"
              hiddenLabel
              size="small"
              color="black"
            >
              <Select
                name="page"
                value={pagePreview}
                onChange={(e) => setPagePreview(e.target.value)}
                inputProps={{
                  MenuProps: {
                    MenuListProps: {
                      sx: {
                        backgroundColor: "#ffff",
                      },
                    },
                  },
                }}
              >
                {dataPage.map((v) => (
                  <MenuItem key={v} value={v}>
                    {v}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack> */}
        </Stack>
        <Box
          className="preview-box custom-scrollbar y"
          sx={{
            width: "600px",
            height: `calc( 100vh - 8rem )`,
            border: "1px solid lightgrey",
            borderRadius: "0.5rem",
            position: "relative",
            overflowY: "auto",
            boxShadow:
              "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px",
          }}
        >
          <PreviewPanel />
        </Box>
      </Grid>
      <Grid item component="section">
        <EditPanel />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  pagePreview: state.preview.pagePreview,
});

export default connect(mapStateToProps)(Index);
