import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Header from "src/components/Header";
import Footer from "src/components/Footer";
import localforage from "localforage";
import { Box, useTheme } from "@mui/material";

const UserLayout = ({ libPages, isActiveNavbar }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const params = useParams();
  const [getAppSetting, setAppSetting] = useState({});
  const [isLib, setIsLib] = useState(null);

  useEffect(() => {
    checkRole();
    fetchInfo();
  }, [isActiveNavbar]);

  const checkRole = async () => {
    const role = await localforage.getItem("userRole");
    if (role === "5") {
      navigate("/v2/admin/");
      window.location.reload();
    }
  };

  const fetchInfo = async () => {
    const resTheme = await localforage.getItem("theme");
    setAppSetting(resTheme);
    const lib = isActiveNavbar.toLowerCase().split(" ").join("-");
    setIsLib(libPages.indexOf(lib));
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          background:
            // params.product || params.dashboard
            isLib === -1
              ? `${theme.palette.background.paper}`
              : "#ffff !important",
        }}
      >
        <Header />
        <Outlet />
        <Footer />
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  libPages: state.nav.libPages,
  isActiveNavbar: state.nav.isActiveNavbar,
});

export default connect(mapStateToProps)(UserLayout);
