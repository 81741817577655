import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import localforage from "localforage";
import { Box, useMediaQuery, Grid, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { testimonyAPI } from "src/redux/actions";
import { makeStyles } from "@mui/styles";
import CardTes01 from "../Cards/CardTes01";
import { dataTesti, domain } from "./data";
import { BoxTitle } from "../BoxTitleLanding";

import Slider from "react-slick";
import "src/App.css";

const SectionTestimony = ({ id, contentPreview, contentHoverId, isChange }) => {
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const [objContent, setObjContent] = useState({});
  const [userRole, setUserRole] = useState("");
  const [testimony, setTestimony] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [contentPreview, isChange]);

  const fetchData = async () => {
    const arrTesti = [{ ...dataTesti }, { ...dataTesti }, { ...dataTesti }];
    setTestimony(arrTesti);

    const resRole = await localforage.getItem("userRole");
    setUserRole(resRole);
    const resContent = contentPreview.length
      ? contentPreview
      : await localforage.getItem("content");
    const arr = resContent.filter((d) => d.id === id);
    setObjContent(arr[0]);

    const data = await testimonyAPI("get", { domain });
    if (data.data) {
      setTestimony(data.data);
      setLoading(false);
    }
  };
  const slider = useRef(null);

  const next = () => {
    slider.current.slickNext();
  };
  const previous = () => {
    slider.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: userRole !== "5" ? 4 : 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Grid
      container
      component="section"
      className="slide-in-left"
      // sx={{
      //   border: "1px solid red",
      //   // m: "3rem auto"
      // }}
    >
      <Grid
        item
        container
        sx={{
          maxWidth:
            userRole === "5"
              ? "500px"
              : isScreen2000
              ? "1536px"
              : isScreen1700
              ? "1440px"
              : isScreen1400
              ? "1200px"
              : isScreen1200
              ? "1024px"
              : "900px",
          m: "0 auto",
          p: 2,
          pl: isScreen600 && userRole !== "5" ? 3 : 2,
          pr: isScreen600 && userRole !== "5" ? 3 : 2,
          pb: "0.25rem",
          border: contentHoverId === id ? "1px dashed black" : null,
        }}
      >
        <BoxTitle
          objContent={objContent}
          userRole={userRole}
          next={next}
          previous={previous}
        />

        <Box style={{ width: "100%" }}>
          <Slider ref={slider} {...settings}>
            {testimony.map((v, i) => (
              <CardTes01 key={i} v={v} loading={loading} userRole={userRole} />
            ))}
          </Slider>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  libPages: state.nav.libPages,
  contentPreview: state.preview.contentPreview,
  contentHoverId: state.preview.contentHoverId,
  isChange: state.preview.isChange,
});

export default connect(mapStateToProps)(SectionTestimony);
