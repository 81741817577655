import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { articleAPI, setIsActiveNavbar, setIsPreview } from "src/redux/actions";
import { connect } from "react-redux";
import localforage from "localforage";
import {
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  Stack,
  styled,
  TextField,
  IconButton,
  Divider,
} from "@mui/material";
import "src/App.css";
import { Article, Delete, Edit, Search } from "@mui/icons-material";
import CardArt01 from "src/components/Cards/CardArt01";
import { dataArt } from "src/components/Layout/data";
import { domain } from "src/components/Layout/data";
import { SelectFormControl } from "src/pages/admin/Setting/EditPanel/ComponentGroup";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "&.Mui-focused": {
    border: `0.1px solid ${theme.palette.primary.main}`,
  },
  "&:hover fieldset": {
    border: `0.1px solid ${theme.palette.primary.main} !important`,
  },
}));

const Index = ({ libPages }) => {
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [article, setArticle] = useState([]);
  const [articlePopular, setArticlePopular] = useState([]);
  const [filterAdm, setFilterAdm] = useState("none");

  useEffect(() => {
    checkParamsUrl();
    setIsPreview(false);
    fetchData();
  }, []);

  const checkParamsUrl = async () => {
    const resRole = await localforage.getItem("userRole");
    setUserRole(resRole);
    setIsActiveNavbar("Article");
  };

  const fetchData = async () => {
    // when load the data
    const arr = [{ ...dataArt }, { ...dataArt }, { ...dataArt }];
    setArticle(arr);
    setArticlePopular(arr);

    const data = await articleAPI("get", { domain });
    if (data.data) {
      setArticle(data.data);
      setArticlePopular(
        data.data.length > 1 ? data.data.slice(1, 10) : data.data
      );
      setLoading(false);
    }
  };

  const handleChangeFilter = (e) => {
    setFilterAdm(e.target.value);
  };

  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");

  return (
    <Grid
      container
      component="main"
      sx={{
        maxWidth: isScreen2000
          ? "1536px"
          : isScreen1700
          ? "1440px"
          : isScreen1400
          ? "1200px"
          : isScreen1200
          ? "1024px"
          : "900px",
        m: "auto",
        p: 2,
        pl: isScreen600 ? 3 : 2,
        pr: isScreen600 ? 3 : 2,
      }}
    >
      <InputFilterSection userRole={userRole} searchVal={searchVal} />
      {userRole === "5" && (
        <>
          <ButtonCreateSection name="Create Article" btn="Buat baru" />
          <GridCardSection
            article={article}
            searchVal={searchVal}
            handleChange={handleChangeFilter}
            filterAdm={filterAdm}
          />
        </>
      )}

      {userRole !== "5" && (
        <>
          <PopularSection
            article={article}
            articlePopular={articlePopular}
            libPages={libPages}
            loading={loading}
          />
          <NewestSection
            article={article}
            searchVal={searchVal}
            loading={loading}
          />
        </>
      )}
    </Grid>
  );
};

const InputFilterSection = ({ searchVal }) => {
  const theme = useTheme();
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen768 = useMediaQuery("(min-width:768px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  return (
    <Grid
      item
      container
      sx={{
        m: isScreen600
          ? `${theme.spacing(3)} auto`
          : `${theme.spacing(2)} auto`,
        mb: 3,
      }}
    >
      <Grid item xs={10} sx={{ pr: isScreen768 ? 2 : 1 }}>
        <CustomTextField
          name="value"
          placeholder="Cari artikel berdasarkan judul maupun tag"
          variant="outlined"
          color="primary"
          hiddenLabel
          fullWidth
          value={searchVal}
          size="small"
        />
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          pl: isScreen1024 ? 2 : null,
          display: "flex",
          justifyContent: isScreen1024 ? null : "center",
        }}
      >
        {isScreen768 ? (
          <Button
            variant="contained"
            startIcon={<Search />}
            sx={{
              borderRadius: "5rem",
              transition: "all linear .4s",
              fontWeight: 600,
              // #fafafa, #f5f5f5, #eeeeee, #e0e0e0, #bdbdbd
              color: `${theme.palette.text.tertiary}`,
              backgroundColor: "#f5f5f5",
              "&:hover": {
                backgroundColor: "#f5f5f5",
                fontWeight: 700,
              },
            }}
          >
            Cari
          </Button>
        ) : (
          <IconButton
            sx={{
              transition: "all linear .4s",
              color: `${theme.palette.text.primary}`,
              backgroundColor: "#f5f5f5",
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
          >
            <Search />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

const ButtonCreateSection = ({ name, btn }) => {
  return (
    <Grid
      item
      container
      sx={{ mt: 3, mb: 3, display: "flex", alignItems: "center" }}
    >
      <Grid item xs={9} sx={{ pr: 2 }}>
        <Divider textAlign="left">
          <Typography variant="h5">{name}</Typography>
        </Divider>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{ pl: 2, display: "flex", justifyContent: "center" }}
      >
        {btn && (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Article />}
            sx={{
              borderRadius: "5rem",
              transition: "all linear .4s",
              fontWeight: 600,
              "&:hover": {
                fontWeight: 700,
              },
            }}
          >
            {btn}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const GridCardSection = ({ article, searchVal, handleChange, filterAdm }) => {
  const theme = useTheme();
  const filter = [
    "most views",
    "fewer views",
    "recent posts",
    "oldest posts",
    "none",
  ];

  return (
    <Stack sx={{ m: `${theme.spacing(3)} 0`, width: "100%" }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Semua Artikel</Typography>

        <Stack direction="row" alignItems="center">
          {["Delete", "Edit"].map((v, i) => (
            <Button
              key={i}
              variant="outlined"
              color={i === 0 ? "red" : "blue"}
              startIcon={i === 0 ? <Delete /> : <Edit />}
              sx={{
                border: "none",
                borderRadius: "5rem",
                transition: "all linear .4s",
                fontWeight: 600,
                "&:hover": {
                  fontWeight: 700,
                  border: "none",
                },
              }}
            >
              {i === 0 ? "Delete" : "Edit"}
            </Button>
          ))}

          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography variant="body2" sx={{ mr: 2, ml: 2 }}>
            Sort by
          </Typography>
          <SelectFormControl
            name="filter"
            value={filterAdm}
            handleChange={handleChange}
            data={filter}
          />
        </Stack>
      </Stack>

      <Grid item container spacing={3} sx={{ pt: 3 }}>
        {article.map((v, i) => {
          return (
            <CardArt01 v={v} key={i} searchVal={searchVal} checkbox="true" />
          );
        })}
      </Grid>
    </Stack>
  );
};

const NewestSection = ({ article, searchVal, loading }) => {
  const theme = useTheme();
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen500 = useMediaQuery("(min-width:500px)");
  return (
    <Stack sx={{ m: `${theme.spacing(3)} 0`, width: "100%" }}>
      <Typography
        variant={isScreen1024 ? "h5" : "subtitle1"}
        sx={{ fontWeight: 700 }}
      >
        Artikel Terbaru
      </Typography>

      <Grid
        item
        container
        spacing={isScreen600 ? 3 : isScreen500 ? 2 : 3}
        sx={{ pt: 3 }}
      >
        {article.map((v, i) => {
          return (
            <CardArt01 v={v} key={i} searchVal={searchVal} loading={loading} />
          );
        })}
      </Grid>
    </Stack>
  );
};

const PopularSection = ({ article, articlePopular, loading }) => {
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  return (
    <Stack sx={{ mt: isScreen600 ? 1 : null, width: "100%" }}>
      <Typography
        variant={isScreen1024 ? "h5" : "subtitle1"}
        sx={{ fontWeight: 700 }}
      >
        Artikel Terpopuler
      </Typography>

      {!isScreen900 && (
        <Stack
          direction="row"
          spacing={isScreen600 ? 3 : 2}
          sx={{ pt: 3, overflowY: "auto" }}
        >
          {article.map((v, i) => {
            return <CardArt01 v={v} key={i} list="row" loading={loading} />;
          })}
        </Stack>
      )}

      {isScreen900 && (
        <Grid item container spacing={3} sx={{ pt: 3 }}>
          <Grid item md={8}>
            <CardArt01
              v={article[0]}
              list="row"
              mostPopular="true"
              loading={loading}
            />
          </Grid>

          <Grid
            item
            container
            md={4}
            className="custom-scrollbar y"
            sx={{ aspectRatio: "16/9", pr: 3 }}
          >
            {articlePopular.map((v, i) => {
              return (
                <CardArt01 v={v} key={i} list="column" loading={loading} />
              );
            })}
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  libPages: state.nav.libPages,
});

export default connect(mapStateToProps)(Index);
