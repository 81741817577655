import React, { useState, useEffect } from "react";
import localforage from "localforage";
import { connect } from "react-redux";
import {
  setLogoPreview,
  setContentPreview,
  setNavPreview,
  updateInfoInstitution,
  setArticlePreview,
  setTestiPreview,
  testimonyAPI,
  articleAPI,
  galleryAPI,
  setGalPreview,
} from "src/redux/actions";
import { Box, Stack } from "@mui/material";
import ButtonGroup from "./ButtonGroup";
import ModalContent from "./ModalContent";
import ModalEditor from "./ModalEditor";
import ModalConfirm from "src/components/ModalConfirm";
import "src/App.css";
import Panel1 from "./Panel1";
import Panel2 from "./Panel2";
import Panel3 from "./Panel3";
import Panel4 from "./Panel4";
import PanelAddition from "./PanelAddition";
import { compareArrayOfObjects } from "src/functions/compareArrayOfObjects";
import { snackbarFunc } from "src/functions/snackbarFunc";
import { domain } from "src/components/Layout/data";

const Index = ({
  themePreview,
  navPreview,
  contentPreview,
  articlePreview,
  testiPreview,
  galPreview,
}) => {
  const [logo, setLogo] = useState({});
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalEditor, setOpenModalEditor] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openPanel1, setOpenPanel1] = useState(false);
  const [openPanel2, setOpenPanel2] = useState(false);
  const [openPanel3, setOpenPanel3] = useState(false);
  const [openPanel4, setOpenPanel4] = useState(false);
  const [openPanel5, setOpenPanel5] = useState(false);
  const [openPanel6, setOpenPanel6] = useState(false);
  const [openPanel7, setOpenPanel7] = useState(false);
  const [namePanel, setNamePanel] = useState("");
  const [validateSize, setValidateSize] = useState(false);
  const [dataId, setDataId] = useState("");
  const [dataIdConfirm, setDataIdConfirm] = useState("");
  const [dataModal, setDataModal] = useState({});
  const [nav, setNav] = useState([]);
  const [navFiltered, setNavFiltered] = useState([]);

  useEffect(() => {
    fetchData();
    fetchDataAddition();
  }, [navPreview]);

  const fetchData = async () => {
    const resNav = navPreview.length
      ? navPreview
      : await localforage.getItem("navigation");
    const resContent = contentPreview.length
      ? contentPreview
      : await localforage.getItem("content");
    setContentPreview(resContent);
    setNavPreview(resNav);
    setNav(resNav);

    const arr = resNav.filter((x) => !x.title);
    setNavFiltered(arr);
  };

  const fetchDataAddition = async () => {
    const token = await localforage.getItem("token");
    const resTesti = await testimonyAPI("get", { token, domain });
    setTestiPreview(resTesti.data);
    const resArt = await articleAPI("get", { token, domain });
    setArticlePreview(resArt.data);
  };

  const handleOpenModal = (namePanel, id, data, confirm) => {
    setNamePanel(namePanel);
    if (confirm === "delete") {
      setDataIdConfirm(id);
      setOpenModalConfirm(true);
    } else {
      setDataId(id ? id : "");
      setDataModal(data ? data : {});
      setOpenModalEditor(true);
    }
  };

  const handleChangeLogo = (e) => {
    if (e.target.files[0].size >= 1000000) {
      setValidateSize(true);
      return;
    }
    setValidateSize(false);
    setLogo(e.target.files[0]);
    setTimeout(() => {
      setLogoPreview(URL.createObjectURL(e.target.files[0]));
    }, 500);
  };

  const handleSave = async () => {
    setLoading(true);
    const resNav = await localforage.getItem("navigation");
    const compareNav = compareArrayOfObjects(navPreview, resNav);
    const resContent = await localforage.getItem("content");
    const compareContent = compareArrayOfObjects(contentPreview, resContent);

    const token = await localforage.getItem("token");
    const obj = { token, domain };
    if (logo["size"]) {
      let formData = new FormData();
      formData.append("token", token);
      formData.append("logo", logo);
      formData.append("domain", domain);
      const resLogo = await updateInfoInstitution("logo", formData);
      snackbarFunc(resLogo, setLoading, "Updated");
    }
    if (!compareNav) {
      const jsonNav = JSON.stringify(navPreview);
      const resNav = await updateInfoInstitution("header-menu", {
        ...obj,
        headerMenu: jsonNav,
      });
      snackbarFunc(resNav, setLoading, "Updated");
    }
    if (!compareContent) {
      const jsonContent = JSON.stringify(contentPreview);
      const resContent = await updateInfoInstitution("content", {
        ...obj,
        content: jsonContent,
      });
      snackbarFunc(resContent, setLoading, "Updated");
    }
    if (themePreview["colorPrimary"]) {
      const jsonTheme = JSON.stringify(themePreview);
      const resTheme = await updateInfoInstitution("theme", {
        ...obj,
        theme: jsonTheme,
      });
      snackbarFunc(resTheme, setLoading, "Updated");
    }
    setLoading(false);
  };

  return (
    <Box className="edit-panel" sx={{}}>
      <Stack direction="row" justifyContent="flex-end" sx={{ width: "100%" }}>
        <ButtonGroup handleSave={handleSave} loading={loading} />
      </Stack>
      <Stack
        direction="column"
        alignItems="flex-start"
        className="custom-scrollbar y"
        sx={{
          width: "100%",
          mt: 1,
          p: 1,
          pt: 0,
          height: `calc( 100vh - 8rem )`,
        }}
      >
        <Panel1
          openPanel1={openPanel1}
          setOpenPanel1={setOpenPanel1}
          validateSize={validateSize}
          handleChangeLogo={handleChangeLogo}
        />

        <Panel2
          openPanel2={openPanel2}
          setOpenPanel2={setOpenPanel2}
          nav={nav}
          navFiltered={navFiltered}
        />

        <Panel3
          openPanel3={openPanel3}
          setOpenPanel3={setOpenPanel3}
          setOpenModal={setOpenModal}
          setDataId={setDataId}
        />

        <Panel4
          openPanel4={openPanel4}
          setOpenPanel4={setOpenPanel4}
          setOpenModal={setOpenModal}
          setDataId={setDataId}
        />

        <PanelAddition
          openPanel={openPanel5}
          setOpenPanel={setOpenPanel5}
          namePanel="Article"
          dataPanel={articlePreview}
          handleOpenModal={handleOpenModal}
        />

        <PanelAddition
          openPanel={openPanel6}
          setOpenPanel={setOpenPanel6}
          namePanel="Testimony"
          dataPanel={testiPreview}
          handleOpenModal={handleOpenModal}
        />

        <PanelAddition
          openPanel={openPanel7}
          setOpenPanel={setOpenPanel7}
          namePanel="Galery"
          dataPanel={galPreview}
          handleOpenModal={handleOpenModal}
        />
      </Stack>
      <ModalContent
        open={openModal}
        onClose={() => setOpenModal(false)}
        dataId={dataId}
      />

      <ModalEditor
        open={openModalEditor}
        onClose={() => setOpenModalEditor(false)}
        namePanel={namePanel}
        dataId={dataId}
        dataModal={dataModal}
        handleOpenModal={handleOpenModal}
      />

      <ModalConfirm
        open={openModalConfirm}
        onClose={() => setOpenModalConfirm(false)}
        namePanel={namePanel}
        dataId={dataIdConfirm}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  themePreview: state.preview.themePreview,
  navPreview: state.preview.navPreview,
  contentPreview: state.preview.contentPreview,
  articlePreview: state.preview.articlePreview,
  testiPreview: state.preview.testiPreview,
  galPreview: state.preview.galPreview,
});

export default connect(mapStateToProps)(Index);
