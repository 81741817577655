import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Divider,
  Card,
  Grid,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  getRefEdu,
  getRefRegion,
  getEducation,
  createEducation,
  updateEducation,
  deleteEducation,
  setSnackbarOpen,
  getRef,
  getRefUniSP,
} from "src/redux/actions";
import { CustomAutoComplete } from "src/pages/admin/Setting/EditPanel/ComponentGroup";
import CustomDatePicker from "src/components/CustomDatePicker";
import EducationInformal from "./EducationInformal";
import EducationAchievement from "./EducationAchievement";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const refDomesticInter = [
  { domestic_international: "domestic", name: "Dalam Negeri" },
  { domestic_international: "international", name: "Luar Negeri" },
];

const Education = () => {
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState("");
  const [eduStage, setEduStage] = useState([]);
  const [cities, setCities] = useState([]);
  const [form, setForm] = useState({
    started_at: new Date(),
    finished_at: new Date(),
  });
  const [educations, setEducations] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [university, setUniversity] = useState([]);
  const [uniSp, setUniSp] = useState([]);
  const [loadingReFetch, setLoadingReFetch] = useState(false);
  const [loadingEdu, setLoadingEdu] = useState(false);

  useEffect(() => {
    fetchRef();
    fetchEdu();
  }, []);

  const fetchRef = async () => {
    const resRef = await Promise.all([
      getRefEdu(),
      getRefRegion({ province: "all" }),
      getRef({ type: "refUniversity" }),
    ]);
    setEduStage(resRef[0].data);
    setCities(resRef[1].data);
    setUniversity(resRef[2].data);
  };

  const fetchEdu = async () => {
    setLoading(true);
    const resEdu = await getEducation();
    setEducations(resEdu.data);
    setLoading(false);
  };

  const handleSelectAuto = async (val, key) => {
    console.log({ [key]: val[key] });
    console.log(val[key]);
    setForm({ ...form, [key]: val[key] });
    if (key === "city") {
      setForm({ ...form, [key]: val });
    } else if (key === "university_id") {
      const res = await getRefUniSP({
        university_id: val[key],
        educational_code: form.educational_code,
      });
      setUniSp(res.data);
    } else if (key === "university_study_program_id") {
      setLoadingEdu(true);
      setForm({
        ...form,
        [key]: val[key],
        educational_code: val.educational_code,
        educational_stage: eduStage.filter(
          (edu) => edu.educational_code === val.educational_code
        )[0],
      });
      setTimeout(() => {
        setLoadingEdu(false);
      }, 500);
    }
  };

  const handleReFetchUniSp = async (val) => {
    setLoadingReFetch(true);
    const res = await getRefUniSP({
      university_id: form.university_id,
      study_program_name: val,
      educational_code: form.educational_code,
    });
    setUniSp(res.data);
    setLoadingReFetch(false);
  };

  const handleDateChange = (val, name) => {
    setForm({ ...form, [name]: val });
  };

  const handleChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCheck = (e) => {
    setForm({ ...form, is_still_studying: e.target.checked });
  };

  const handleEdit = (d) => {
    setForm(d);
    setOpenForm("edit");
  };

  const handleDelete = (d) => {
    setForm(d);
    setAlertOpen(true);
  };

  const handleSubmitDelete = async () => {
    await deleteEducation(form);
    setForm({});
    setSnackbarOpen("success", "Data berhasil dihapus");
    setAlertOpen(false);
    fetchEdu();
  };

  const handleSave = async () => {
    const copyForm = { ...form };
    copyForm.city = form.city?.city;
    if (openForm === "new") {
      await createEducation(copyForm);
    } else {
      await updateEducation(copyForm);
    }
    setSnackbarOpen("success", "Data disimpan");
    setOpenForm("");
    setForm({});
    fetchEdu();
  };

  console.log(form.domestic_international);

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 2 }}>
        <b>Data Pendidikan</b>
      </Typography>

      <Stack
        direction="row"
        justifyContent={"space-between"}
        sx={{ m: "1rem 0" }}
      >
        <Typography sx={{ fontSize: "20px", m: "auto 0" }}>
          Pendidikan Formal
        </Typography>
        <Button variant="contained" onClick={() => setOpenForm("new")}>
          Tambah
        </Button>
      </Stack>
      <Grid container>
        <Grid item sm={1} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>Jenjang Pendidikan</Typography>
        </Grid>
        <Grid item sm={12 / 5} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>
            Nama Sekolah/Universitas
          </Typography>
        </Grid>
        <Grid item sm={12 / 5} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>Kota</Typography>
        </Grid>
        <Grid item sm={12 / 5} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>Jurusan</Typography>
        </Grid>
        <Grid item sm={12 / 5} sx={{ m: "auto" }}></Grid>
      </Grid>
      {educations.map((edu) => (
        <Card
          id={edu.bio_edu_id}
          sx={{ backgroundColor: "#FFF", p: 2, borderRadius: "16px" }}
        >
          <Grid container>
            <Grid item sm={1} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                {edu.educational_stage?.educational_name}
              </Typography>
            </Grid>
            <Grid item sm={12 / 5} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {edu?.university?.university_name}
              </Typography>
            </Grid>
            <Grid item sm={12 / 5} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {edu.city?.city}
              </Typography>
            </Grid>
            <Grid item sm={12 / 5} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {edu?.study_program?.study_program_name}
              </Typography>
            </Grid>
            <Grid item sm={12 / 5} sx={{ m: "auto" }}>
              <Stack direction="row" justifyContent={"end"} spacing={1}>
                <IconButton onClick={() => handleEdit(edu)}>
                  <EditRoundedIcon />{" "}
                </IconButton>
                <IconButton onClick={() => handleDelete(edu)}>
                  <DeleteRoundedIcon />{" "}
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      ))}
      <Divider sx={{ mt: 2, mb: 2 }} />
      {openForm && (
        <Stack
          sx={{
            border: "1px solid",
            p: "1rem",
            borderRadius: "6px",
            borderColor: "divider",
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Pendidikan Dalam/Luar Negeri
          </Typography>
          <CustomAutoComplete
            name="domestic_international"
            optionsArr={refDomesticInter}
            keyLabel={"name"}
            handleSelect={handleSelectAuto}
            register="true"
            style={{ marginBottom: "1rem" }}
            // value={form.domestic_international}
            value={
              refDomesticInter.filter(
                (u) => u.domestic_international === form.domestic_international
              )[0]
            }
          />
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Jenjang Pendidikan
          </Typography>
          <CustomAutoComplete
            name="educational_code"
            optionsArr={eduStage}
            keyLabel={"educational_name"}
            handleSelect={handleSelectAuto}
            register="true"
            style={{ marginBottom: "1rem" }}
            value={form.educational_stage}
          />
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Nama Sekolah / Universitas
          </Typography>
          {form.domestic_international === "international" ? (
            <CustomInput
              label=""
              name="school"
              onChange={handleChangeInput}
              value={form.school}
            />
          ) : (
            <CustomAutoComplete
              name="university_id"
              optionsArr={university}
              keyLabel={"university_name"}
              handleSelect={handleSelectAuto}
              register="true"
              style={{ marginBottom: "1rem" }}
              value={
                university.filter(
                  (u) => u.university_id === form.university_id
                )[0]
              }
            />
          )}
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Kota
          </Typography>
          {form.domestic_international === "international" ? (
            <CustomInput
              label=""
              name="city"
              onChange={handleChangeInput}
              value={form.city}
            />
          ) : (
            <CustomAutoComplete
              name="city"
              optionsArr={cities}
              keyLabel={"city"}
              handleSelect={handleSelectAuto}
              register="true"
              style={{ marginBottom: "1rem" }}
              value={form.city}
            />
          )}
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Jurusan/Fakultas
          </Typography>
          {form.domestic_international === "international" ? (
            <CustomInput
              label=""
              name="study_program_text"
              onChange={handleChangeInput}
              value={form.study_program_text}
            />
          ) : (
            <CustomAutoComplete
              name="university_study_program_id"
              optionsArr={loadingReFetch ? [] : uniSp}
              loading={loadingReFetch}
              keyLabel={"sp_stage_name"}
              handleSelect={handleSelectAuto}
              fetchData={handleReFetchUniSp}
              register="true"
              style={{ marginBottom: "1rem" }}
              value={
                uniSp.filter(
                  (u) =>
                    u.university_study_program_id ===
                    form.university_study_program_id
                )[0]
              }
            />
          )}
          <CustomInput
            label="IPK/Nilai Ijazah"
            name="ipk"
            onChange={handleChangeInput}
            value={form.ipk}
          />
          <CustomDatePicker
            label="Tanggal Mulai"
            onChange={handleDateChange}
            name="started_at"
            value={form.started_at}
          />
          <Stack direction="row" spacing={1} sx={{ mb: "1rem" }}>
            <FormGroup sx={{ m: "auto 0" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.is_still_studying}
                    onChange={handleCheck}
                  />
                }
                label="Masih Studi"
              />
            </FormGroup>
            {!form.is_still_studying && (
              <CustomDatePicker
                label="Tanggal Lulus"
                onChange={handleDateChange}
                name="finished_at"
                value={form.finished_at}
              />
            )}
          </Stack>
          <CustomInput
            label="Judul TA/Skripsi"
            name="thesis_title"
            onChange={handleChangeInput}
            value={form.thesis_title}
            multiline
            rows={2}
          />
          <Stack direction="row" spacing={2} justifyContent={"end"}>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenForm("");
                setForm({});
              }}
            >
              Batal
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Simpan
            </Button>
          </Stack>
        </Stack>
      )}

      <CustomDialog open={alertOpen} fullWidth maxWidth="xs">
        <DialogTitle>Konfirmasi</DialogTitle>
        <DialogContent>
          Data {form.school} - {form.educational_stage?.educational_name} akan
          dihapus
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setAlertOpen(false);
              setForm({});
            }}
          >
            Batal
          </Button>
          <Button variant="contained" onClick={handleSubmitDelete}>
            Ya, Hapus
          </Button>
        </DialogActions>
      </CustomDialog>

      {/* -------------------- Prestasi semasa studi --------------- */}
      <EducationAchievement />

      {/* -------------------- INFORMAL EDUCATION ------------------- */}
      <EducationInformal />
    </Container>
  );
};

export default Education;
