import { SET_IS_ACTIVE_NAVBAR } from "../events";
import { SET_LIB_PAGES } from "../events";

const initialState = {
  isActiveNavbar: "",
  libPages: [],
};

const nav = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_ACTIVE_NAVBAR: {
      return { ...state, isActiveNavbar: action.payload };
    }
    case SET_LIB_PAGES: {
      return { ...state, libPages: action.payload };
    }
    default:
      return state;
  }
};

export default nav;
