import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  Avatar,
  Stack,
  Chip,
  Button,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  styled,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

const CustomInputA = ({
  label,
  name,
  onChange,
  value,
  defaultValue,
  required,
  ...rest
}) => {
  return (
    <>
      <TextField
        label={label}
        value={value}
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        size="small"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        fullWidth
        style={{ marginBottom: "1rem" }}
        {...rest}
      />
    </>
  );
};

const CustomSelect = ({ label, value, data, handleChange, ...rest }) => {
  return (
    <FormControl variant="outlined" fullWidth sx={{ mb: "1rem" }}>
      {console.log(value)}
      <InputLabel id="select-label" shrink>
        {label}
      </InputLabel>
      <Select
        labelId="select-label"
        value={value}
        label={label}
        displayEmpty
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        inputProps={{
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: "#ffff",
              },
            },
          },
        }}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Typography variant="body2" sx={{ color: "lightgrey" }}>
                {label}
              </Typography>
            );
          }
          return selected;
        }}
        {...rest}
      >
        <MenuItem disabled value="">
          <Typography variant="body2" sx={{ color: "grey" }}>
            {label}
          </Typography>
        </MenuItem>
        {data.map((v) => (
          <MenuItem key={v.key} value={v.key}>
            {v.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const customIcon = {
  position: "absolute",
  right: "-4rem",
  bottom: "5%",
  top: "5%",
};

const SignB = () => {
  const [form, setForm] = useState({});
  const [validation, setValidation] = useState(true);

  const clothingSize = [
    { key: "S", value: "S" },
    { key: "M", value: "M" },
    { key: "L", value: "L" },
    { key: "XL", value: "XL" },
    { key: "XXL", value: "XXL" },
  ];

  const shoesSize = [
    { key: "36", value: "36" },
    { key: "37", value: "37" },
    { key: "38", value: "38" },
    { key: "39", value: "39" },
    { key: "40", value: "40" },
    { key: "41", value: "41" },
    { key: "42", value: "42" },
    { key: "43", value: "43" },
  ];

  const handleChange = (key, value) => {
    const copyForm = { ...form };
    copyForm[key] = value;
    console.log(copyForm);
    setForm(copyForm);
  };

  return (
    <Grid
      container
      component="main"
      maxWidth={"md"}
      sx={{ m: "auto" }}
      spacing={2}
    >
      <Grid item sm={12}>
        <Typography variant="h5" sx={{ m: "3rem 0 1rem 0" }}>
          Requirement Sign Contract
        </Typography>
        <Chip
          label="Batas Pengisian Minggu, 24 September 2023"
          color="primary"
          sx={{
            mb: "0.75rem",
            "& .MuiChip-label": {
              textAlign: "left",
            },
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <Typography variant="subtitle1" sx={{ mb: "1rem", fontWeight: "bold" }}>
          Pakaian
        </Typography>
        <CustomSelect
          label="Ukuran Baju"
          placeholder="Ukuran Baju"
          value={form.shirt_size}
          handleChange={handleChange}
          data={clothingSize}
          name="shirt_size"
        />
        <CustomSelect
          label="Ukuran Celana"
          placeholder="Ukuran Celana"
          value={form.pant_size}
          handleChange={handleChange}
          data={clothingSize}
          name="pant_size"
        />
        <CustomSelect
          label="Ukuran Sepatu"
          placeholder="Ukuran Sepatu"
          value={form.shoes_size}
          handleChange={handleChange}
          data={shoesSize}
          name="shoes_size"
        />

        <Typography variant="subtitle1" sx={{ mb: "1rem", fontWeight: "bold" }}>
          Dokumen
        </Typography>
        <Stack sx={{ width: "100%", mb: "1rem" }}>
          <Button
            component="label"
            variant="outlined"
            sx={{ width: "fit-content", position: "relative" }}
          >
            Upload Akta Lahir
            <VisuallyHiddenInput type="file" />
            {validation && (
              <CheckCircle
                color="success"
                sx={{ fontSize: 30, height: "90%", ...customIcon }}
              />
            )}
          </Button>
        </Stack>
        <Stack sx={{ width: "100%", mb: "1rem" }}>
          <Button
            component="label"
            variant="outlined"
            sx={{ width: "fit-content", position: "relative" }}
          >
            Upload Buku Nikah
            <VisuallyHiddenInput type="file" />
            {validation && (
              <CheckCircle
                color="success"
                sx={{ fontSize: 30, height: "90%", ...customIcon }}
              />
            )}
          </Button>
        </Stack>
        <Stack sx={{ width: "100%", mb: "1rem" }}>
          <Button
            component="label"
            variant="outlined"
            sx={{ width: "fit-content", position: "relative" }}
          >
            Upload Kartu Keluarga
            <VisuallyHiddenInput type="file" />
            {validation && (
              <CheckCircle
                color="success"
                sx={{ fontSize: 30, height: "90%", ...customIcon }}
              />
            )}
          </Button>
        </Stack>
        <Stack sx={{ width: "100%", mb: "1rem" }}>
          <Button
            component="label"
            variant="outlined"
            sx={{ width: "fit-content", position: "relative" }}
          >
            Upload Pakta Integritas
            <VisuallyHiddenInput type="file" />
            {validation && (
              <CheckCircle
                color="success"
                sx={{ fontSize: 30, height: "90%", ...customIcon }}
              />
            )}
          </Button>
        </Stack>
        <Typography
          component="a"
          variant="body2"
          color="primary"
          sx={{
            mb: "1rem",
            textDecoration: "underline",
            "&:hover": { cursor: "pointer" },
          }}
        >
          Download Format Pakta Integritas
        </Typography>
      </Grid>

      <Grid item sm={12}>
        <Typography variant="subtitle1" sx={{ my: "1rem", fontWeight: "bold" }}>
          BPJS
        </Typography>
      </Grid>
      <Grid item sm={9}>
        <CustomInputA
          label="No. BPJS Kesehatan"
          placeholder="No. BPJS Kesehatan"
          name="npwp"
        />
      </Grid>
      <Grid item sm={3}>
        <Stack sx={{ position: "relative" }}>
          <Button component="label" variant="outlined" sx={{ ml: 3 }}>
            Upload
            <VisuallyHiddenInput type="file" />
          </Button>
          {validation && (
            <CheckCircle
              color="success"
              sx={{ fontSize: 30, height: "90%", ...customIcon }}
            />
          )}
        </Stack>
      </Grid>
      <Grid item sm={9}>
        <CustomInputA
          label="No. BPJS Ketenagakerjaan"
          placeholder="No. BPJS Ketenagakerjaan"
          name="npwp"
        />
      </Grid>
      <Grid item sm={3}>
        <Stack sx={{ position: "relative" }}>
          <Button component="label" variant="outlined" sx={{ ml: 3 }}>
            Upload
            <VisuallyHiddenInput type="file" />
          </Button>
          {validation && (
            <CheckCircle
              color="success"
              sx={{ fontSize: 30, height: "90%", ...customIcon }}
            />
          )}
        </Stack>
      </Grid>

      <Grid item sm={12}>
        <Stack sx={{ width: "100%", my: "2rem", mb: "3rem" }}>
          <Divider variant="middle" sx={{ width: "70%", m: "auto" }} />
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="center"
          sx={{ width: "100%" }}
        >
          <Button variant="outlined">Tutup</Button>
          <Button variant="contained" sx={{ ml: 2 }}>
            Simpan dan Kirim
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default SignB;
