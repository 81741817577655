import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  Stack,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomInput from "src/components/CustomInput";
import { setSnackbarOpen, getIllness, createIllness } from "src/redux/actions";
import { VaccinesOutlined } from "@mui/icons-material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const NewConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0",
    borderTopWidth: 4,
    borderRadius: 5,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <VaccinesOutlined />,
    2: <VaccinesOutlined />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ["Orang Tua", "Pribadi"];

const Illness = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataFactor, setDataFactor] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [loadingCheck, setLoadingCheck] = useState({});

  useEffect(() => {
    fetchInfo();
  }, [activeStep]);

  const fetchInfo = async () => {
    const resIllness = await getIllness();
    let newIllness = [];
    if (activeStep === 0) {
      newIllness = resIllness.data?.filter((i) => i.is_illness_parent);
    } else {
      newIllness = resIllness.data?.filter((i) => i.is_illness_personal);
      const factor = resIllness.data?.filter((i) => i.is_illness_factor);
      setDataFactor(factor);
    }
    setData(newIllness);
    setLoading(false);
  };

  const handleStep = (step) => () => {
    setLoading(true);
    setActiveStep(step);
  };

  const handleCheck = async (val, key, d) => {
    setLoadingCheck(d);
    d[key] = val;
    await createIllness(d);
    setLoadingCheck({});
    setSnackbarOpen("success", "Berhasil Update Data");
    fetchInfo();
  };

  const handleBlur = async (val, key, d) => {
    // console.log({ val, d });
    d[key] = val;
    await createIllness(d);
    setSnackbarOpen("success", "Berhasil Update Data");
    fetchInfo();
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 2 }}>
        <b>Riwayat Kesehatan</b>
      </Typography>
      <Stack sx={{ m: "auto", maxWidth: "460px" }}>
        <Stepper nonLinear activeStep={activeStep} connector={<NewConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Stack>

      {activeStep === 0 && (
        <Stack sx={{ mt: "2rem" }}>
          {data.map((d) => (
            <Grid container sx={{ mb: "1rem" }}>
              <Grid item xs={3} sx={{ m: "auto 0" }}>
                <Typography>{d.illness_name}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ m: "auto 0", textAlign: "center" }}>
                {["is_father", "is_mother"].map((p) => (
                  <FormControlLabel
                    control={
                      loadingCheck.illness_id === d.illness_id ? (
                        <CircularProgress size={24} sx={{ mr: "1rem" }} />
                      ) : (
                        <Checkbox
                          checked={d[p]}
                          onClick={(e) => handleCheck(e.target.checked, p, d)}
                        />
                      )
                    }
                    label={p === "is_father" ? "Ayah" : "Ibu"}
                  />
                ))}
              </Grid>
              {d.is_desc && (
                <Grid item xs={6} sx={{ m: "auto 0" }}>
                  <CustomInput
                    label=""
                    multiline
                    rows={2}
                    style={{ marginBottom: "0px" }}
                    onBlur={(e) => handleBlur(e.target.value, "parent_desc", d)}
                    defaultValue={d.parent_desc}
                  />
                </Grid>
              )}
            </Grid>
          ))}
          <Stack direction="row" justifyContent={"end"}>
            <Button variant="contained" onClick={handleStep(1)}>
              Lanjut
            </Button>
          </Stack>
        </Stack>
      )}

      {activeStep === 1 && (
        <Stack sx={{ mt: "2rem" }}>
          {data.map((p) => (
            <Grid container spacing={2} sx={{ mb: "1rem" }}>
              <Grid item xs={4} sx={{ m: "auto 0" }}>
                <FormControlLabel
                  control={
                    loadingCheck.illness_id === p.illness_id ? (
                      <CircularProgress size={24} sx={{ mr: "1rem" }} />
                    ) : (
                      <Checkbox
                        checked={p.is_personal}
                        onClick={(e) =>
                          handleCheck(e.target.checked, "is_personal", p)
                        }
                      />
                    )
                  }
                  label={p.illness_name}
                />
              </Grid>
              {p.is_desc && (
                <Grid item xs={6} sx={{ m: "auto 0" }}>
                  <CustomInput
                    label=""
                    multiline
                    rows={2}
                    style={{ marginBottom: "0px" }}
                    onBlur={(e) =>
                      handleBlur(e.target.value, "personal_desc", p)
                    }
                    defaultValue={p.personal_desc}
                  />
                </Grid>
              )}
            </Grid>
          ))}

          <Typography variant="h5" sx={{ mt: "1rem" }}>
            Faktor Kebiasaan
          </Typography>
          {dataFactor.map((p) => (
            <Grid container spacing={2} sx={{ mb: "1rem" }}>
              <Grid item xs={4} sx={{ m: "auto 0" }}>
                <FormControlLabel
                  control={
                    loadingCheck.illness_id === p.illness_id ? (
                      <CircularProgress size={24} sx={{ mr: "1rem" }} />
                    ) : (
                      <Checkbox
                        checked={p.is_personal}
                        onClick={(e) =>
                          handleCheck(e.target.checked, "is_personal", p)
                        }
                      />
                    )
                  }
                  label={p.illness_name}
                />
              </Grid>
              {p.is_desc && (
                <Grid item xs={6} sx={{ m: "auto 0" }}>
                  <CustomInput
                    label=""
                    multiline
                    rows={2}
                    style={{ marginBottom: "0px" }}
                    onBlur={(e) =>
                      handleBlur(e.target.value, "personal_desc", p)
                    }
                    defaultValue={p.personal_desc}
                  />
                </Grid>
              )}
            </Grid>
          ))}
        </Stack>
      )}
    </Container>
  );
};

export default Illness;
