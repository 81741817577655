import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import localforage from "localforage";
import {
  Box,
  Typography,
  useMediaQuery,
  Grid,
  useTheme,
  Stack,
} from "@mui/material";
import "src/App.css";

const SectionDes = ({ id, contentPreview, contentHoverId }) => {
  const theme = useTheme();
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen700 = useMediaQuery("(min-width:700px)");
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const [colWidth, setColWidth] = useState(6);
  const [objContent, setObjContent] = useState({});
  const [userRole, setUserRole] = useState("");
  const [textLess, setTextLess] = useState("");
  const [textMore, setTextMore] = useState("");

  useEffect(() => {
    getInfo();
  }, [contentPreview]);

  const getInfo = async () => {
    const resRole = await localforage.getItem("userRole");
    setUserRole(resRole);

    const resContent = contentPreview.length
      ? contentPreview
      : await localforage.getItem("content");
    const arr = resContent.filter((d) => d.id === id);
    setObjContent(arr[0]);
    setColWidth(id.indexOf("1") !== -1 ? 6 : 7);

    const limitText = 650;
    setTextLess(arr[0].paragraph.slice(0, limitText));
    if (arr[0].paragraph.length >= limitText)
      setTextMore(arr[0].paragraph.slice(limitText, arr[0].paragraph.length));
  };

  const handleMoreParagraph = () => {
    const dots = document.getElementById("dots");
    const more = document.getElementById("more");
    const btnMore = document.getElementById("btnMore");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnMore.innerHTML = "read more";
      more.style.display = "none";
    } else {
      dots.style.display = "none";
      btnMore.innerHTML = "read less";
      more.style.display = "inline";
    }
  };

  return (
    <Grid
      container
      component="section"
      className="slide-in-left"
      sx={{
        maxWidth:
          userRole === "5"
            ? "500px"
            : // isScreen2000
            // ? "1536px"
            // :
            // isScreen1700
            // ? "1440px"
            // :
            isScreen1400
            ? "1200px"
            : isScreen1200
            ? "1024px"
            : "900px",
        m: `${theme.spacing(2)} auto`,
        p: isScreen900 && userRole !== "5" ? 2 : 0,
        pb: 2,
        // pl: isScreen600 && userRole !== "5" ? 6 : 2,
        // pr: isScreen600 && userRole !== "5" ? 6 : 2,
        pl: isScreen900 ? 3 : isScreen700 ? 6 : 3,
        pr: isScreen900 ? 3 : isScreen700 ? 6 : 3,
        border: contentHoverId === id ? "1px dashed black" : null,
        // backgroundColor: "yellow",
      }}
    >
      {objContent.posTitle === "center-top" && objContent.title && (
        <TitleBox
          title="true"
          underline={objContent.underline}
          content={objContent.title}
          objContent={objContent}
          userRole={userRole}
        />
      )}
      <Grid
        item
        container
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        spacing={isScreen1024 && userRole !== "5" ? 5 : 3}
      >
        {(objContent.posImg === "left" || !isScreen900) && (
          <GridImg
            colWidth={colWidth}
            objContent={objContent}
            userRole={userRole}
          />
        )}
        <GridText
          colWidth={colWidth}
          objContent={objContent}
          userRole={userRole}
          textLess={textLess}
          textMore={textMore}
          handleMoreParagraph={handleMoreParagraph}
        />
        {objContent.posImg === "right" && isScreen900 && (
          <GridImg
            colWidth={colWidth}
            objContent={objContent}
            userRole={userRole}
          />
        )}
      </Grid>
    </Grid>
  );
};

const GridImg = ({ objContent, userRole, colWidth }) => {
  const isScreen1536 = useMediaQuery("(min-width:1536px)");
  const isScreen700 = useMediaQuery("(min-width:700px)");
  const isScreen500 = useMediaQuery("(min-width:500px)");
  return (
    <>
      <Grid item xs={isScreen500 ? 10 : 12} sm={10} md={colWidth}>
        <img
          alt={`${objContent.id}`}
          src={objContent.img}
          style={{
            objectFit: "cover",
            objectPosition: "center",
            width: "100%",
            borderRadius: userRole === "5" || !isScreen700 ? "2.5rem" : "5rem",
            // border: "1px solid red",
            boxShadow:
              "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
          }}
        />
      </Grid>
      {/* {isScreen1536 && <Grid item xl={1}></Grid>} */}
    </>
  );
};

const GridText = ({
  objContent,
  userRole,
  colWidth,
  textLess,
  textMore,
  handleMoreParagraph,
}) => {
  const isScreen500 = useMediaQuery("(min-width:500px)");
  return (
    <>
      <Grid item xs={isScreen500 ? 10 : 12} sm={10} md={12 - colWidth}>
        {objContent.posTitle === "img-side" && objContent.title && (
          <TitleBox
            title="true"
            underline={objContent.underline}
            content={objContent.title}
            objContent={objContent}
            posTitle={objContent.posTitle}
            userRole={userRole}
          />
        )}
        {objContent.subtitle && (
          <TitleBox
            content={objContent.subtitle}
            objContent={objContent}
            posTitle={objContent.posTitle}
            userRole={userRole}
          />
        )}
        <Typography
          component="p"
          variant={userRole === "5" ? "caption2" : "body2"}
          sx={{
            m: userRole === "5" ? "0.25rem auto" : "1rem auto",
            fontSize: userRole === "5" ? 8 : null,
            textAlign:
              objContent.alignText === "center"
                ? "center"
                : objContent.alignText === "justify"
                ? "justify"
                : objContent.alignText === "left"
                ? "start"
                : "end",
          }}
        >
          {/* {objContent.paragraph} */}
          {textLess}
          {textMore && (
            <>
              <span id="dots" style={{ display: "inline" }}>
                {" ... "}
              </span>
              <span id="more" style={{ display: "none" }}>
                {textMore}
              </span>
              <Typography
                id="btnMore"
                component="span"
                color="primary"
                variant={userRole === "5" ? "caption2" : "body2"}
                sx={{
                  textDecoration: "underline",
                  fontSize: userRole === "5" ? 8 : null,
                  fontWeight: "bold",
                  ml: 1,
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => handleMoreParagraph()}
              >
                read more
              </Typography>
            </>
          )}
        </Typography>
      </Grid>
    </>
  );
};

const TitleBox = ({
  title,
  content,
  objContent,
  posTitle,
  userRole,
  underline,
}) => {
  const isScreen700 = useMediaQuery("(min-width:700px)");
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const theme = useTheme();
  const styleStack = {
    // backgroundColor: "yellow",
    width: "100%",
    mb:
      !posTitle && isScreen900 && userRole !== "5"
        ? "3rem"
        : !posTitle && (!isScreen900 || userRole === "5")
        ? `${theme.spacing(3)}`
        : null,
    display: "flex",
    alignItems:
      objContent.alignTitle === "center" || !posTitle
        ? "center"
        : objContent.alignTitle === "left"
        ? "flex-start"
        : "flex-end",
  };
  const styleTitle = {
    fontWeight: 700,
    textAlign:
      objContent.alignTitle === "center" || !posTitle
        ? "center"
        : objContent.alignTitle === "justify"
        ? "justify"
        : objContent.alignTitle === "left"
        ? "start"
        : "end",
  };

  return (
    <Stack direction="column" sx={styleStack}>
      {title && (
        <Typography
          variant={userRole === "5" ? "body2" : isScreen700 ? "h3" : "h5"}
          sx={styleTitle}
        >
          {content}
        </Typography>
      )}
      {!title && (
        <Typography
          variant={
            userRole === "5" ? "caption" : isScreen700 ? "h4" : "subtitle1"
          }
          sx={styleTitle}
        >
          {content}
        </Typography>
      )}
      {title && ( //&& underline
        <Typography
          variant="span"
          sx={{
            width: userRole === "5" ? "25px" : "50px",
            backgroundColor: `${theme.palette.primary.main}`,
            height: userRole === "5" ? "4px" : "7.5px",
            borderRadius: "1rem",
            mt:
              isScreen900 && userRole !== "5"
                ? "0.5rem"
                : `${theme.spacing(0)}`,
          }}
        ></Typography>
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  contentPreview: state.preview.contentPreview,
  contentHoverId: state.preview.contentHoverId,
});

export default connect(mapStateToProps)(SectionDes);
