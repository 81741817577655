import React from "react";
import { connect } from "react-redux";
import { Button, useTheme, Grid, Autocomplete, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Place, School, Search } from "@mui/icons-material";
import "src/App.css";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "&.Mui-focused": {
    border: `0.1px solid ${theme.palette.primary.main}`,
  },
  "&:hover fieldset": {
    border: `0.1px solid ${theme.palette.primary.main} !important`,
  },
}));

const FilterInput = ({ handleSearch }) => {
  const theme = useTheme();

  return (
    <Grid
      item
      container
      sx={{
        // mt: theme.spacing(1),
        mb: theme.spacing(5), // 3
        mt: -7,
      }}
      spacing={3}
    >
      <Grid item md={3}>
        <Autocomplete
          disablePortal
          id="location"
          // options={refEdu}
          // fullWidth
          size="small"
          // getOptionLabel={(option) => option.educational_name}
          // onChange={(e, val) => handleSelectEdu(val)}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              hiddenLabel
              placeholder="Location"
              variant="outlined"
              color="primary"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Place sx={{ color: `${theme.palette.primary.main}` }} />
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item md={3}>
        <Autocomplete
          disablePortal
          id="education"
          // options={refEdu}
          // fullWidth
          size="small"
          // getOptionLabel={(option) => option.educational_name}
          // onChange={(e, val) => handleSelectEdu(val)}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              hiddenLabel
              placeholder="Education"
              variant="outlined"
              color="primary"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <School sx={{ color: `${theme.palette.primary.main}` }} />
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item md={4}>
        <Autocomplete
          disablePortal
          id="position"
          // options={refEdu}
          // fullWidth
          size="small"
          // getOptionLabel={(option) => option.educational_name}
          // onChange={(e, val) => handleSelectEdu(val)}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              hiddenLabel
              placeholder="Position"
              variant="outlined"
              color="primary"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Search sx={{ color: `${theme.palette.primary.main}` }} />
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item md={2}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            background: `${theme.palette.primaryButton.main}`,
            borderRadius: "5rem",
            ml: 1,
            mr: 1,
          }}
          onClick={handleSearch}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(FilterInput);
