import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import localforage from "localforage";
import {
  Box,
  Typography,
  useMediaQuery,
  Grid,
  useTheme,
  Stack,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { FaFacebookF, FaInstagram, FaPhoneAlt } from "react-icons/fa";

const Footer = ({ libPages, isActiveNavbar }) => {
  const params = useParams();
  const theme = useTheme();
  const [getAppSetting, setAppSetting] = useState({});
  const [userRole, setUserRole] = useState("");
  const [bio, setBio] = useState({});
  const [logo, setLogo] = useState("");
  const [isLib, setIsLib] = useState(null);

  useEffect(() => {
    getInfo();
  }, [isActiveNavbar]);

  const getInfo = async () => {
    const resRole = await localforage.getItem("userRole");
    const resBio = await localforage.getItem("bio");
    const resTheme = await localforage.getItem("theme");
    setUserRole(resRole);
    setBio(resBio);
    setAppSetting(resTheme);
    setLogo(resBio.logo);
    setIsLib(libPages.indexOf(isActiveNavbar.toLowerCase()));
  };

  return (
    <Grid
      container
      component="footer"
      // id={titleMenu.toLowerCase().split(" ").join("")}
      sx={{
        // p: isScreen1024
        //   ? "2rem 0 1rem 0"
        //   : isScreen600
        //   ? "2rem 2rem 1rem 2rem"
        //   : "1rem 1rem 0 1rem",
        mt: userRole === "5" ? "1rem" : "2rem",
        backgroundColor: "rgb(255,255,255)",
        // background:
        //   params.product || params.dashboard
        //     ? `${theme.palette.background.paper2}`
        //     : null,
      }}
    >
      {getAppSetting.websiteFor === "recruitment" && (
        <JobSeekerFooter bio={bio} userRole={userRole} />
      )}
      {getAppSetting.websiteFor !== "recruitment" && (
        <EdutechFooter
          bio={bio}
          userRole={userRole}
          logo={logo}
          isLib={isLib}
        />
      )}
    </Grid>
  );
};

const EdutechFooter = ({ bio, userRole, logo, isLib }) => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");

  return (
    <Grid
      container
      sx={{
        maxWidth:
          userRole === "5"
            ? "500px"
            : isScreen2000
            ? "1536px"
            : isScreen1700
            ? "1440px"
            : isScreen1400
            ? "1200px"
            : isScreen1200
            ? "1024px"
            : "900px",
        m: "0 auto",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderTop: isLib === -1 ? null : "1px solid lightgrey",
      }}
    >
      <Grid
        item
        container
        spacing={userRole === "5" ? 1 : isScreen900 ? 3 : 3}
        sx={{
          p: userRole === "5" ? 1 : 2,
          pt: userRole === "5" ? "1rem" : "1.5rem",
          // pb: userRole === "5" ? "1rem" : "1.5rem",
          // border: "1px solid black",
        }}
      >
        <Grid item xs={12} sm={6} md={5}>
          <img
            src={logo}
            alt="logo"
            style={{
              height: userRole === "5" ? "2.25rem" : "3.5rem",
              opacity: logo ? "1" : "0",
              paddingTop: "0.05rem",
              paddingBottom: "0.05rem",
            }}
            className="logo"
            onClick={userRole === "5" ? null : () => navigate("/v2/user/")}
          />
          <Typography
            color="primary"
            component="p"
            variant={userRole === "5" ? "caption" : "body1"}
            sx={{ fontSize: userRole === "5" ? 10 : "", fontWeight: 700 }}
          >
            {bio.inst_name}
          </Typography>
          <Typography
            component="p"
            variant={userRole === "5" ? "caption2" : "body2"}
            sx={{ fontSize: userRole === "5" ? 8 : "" }}
          >
            {bio.address}
          </Typography>
        </Grid>

        {isScreen900 && <Grid item md={3}></Grid>}

        <Grid
          item
          xs={6}
          sm={3}
          md={2}
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <Typography
            color="primary"
            component="p"
            variant={userRole === "5" ? "caption2" : "body2"}
            sx={{ fontSize: userRole === "5" ? 8 : "", fontWeight: 700 }}
          >
            Contact Us
          </Typography>
          <Typography
            component="p"
            variant={userRole === "5" ? "caption2" : "body2"}
            sx={{ fontSize: userRole === "5" ? 8 : "" }}
          >
            Email
          </Typography>
          <Typography
            component="p"
            variant={userRole === "5" ? "caption2" : "body2"}
            sx={{ fontSize: userRole === "5" ? 8 : "" }}
          >
            Website
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          md={2}
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <Typography
            color="primary"
            component="p"
            variant={userRole === "5" ? "caption2" : "body2"}
            sx={{
              fontSize: userRole === "5" ? 8 : "",
              fontWeight: 700, 
            }}
          >
            Company
          </Typography>
          <Typography
            component="p"
            variant={userRole === "5" ? "caption2" : "body2"}
            sx={{ fontSize: userRole === "5" ? 8 : "" }}
          >
            Article
          </Typography>
          <Typography
            component="p"
            variant={userRole === "5" ? "caption2" : "body2"}
            sx={{ fontSize: userRole === "5" ? 8 : "" }}
          >
            Galery
          </Typography>
        </Grid>
      </Grid>
      <Stack
        direction={isScreen900 ? "row" : "column"}
        justifyContent={isScreen900 ? "flex-end" : "center"}
        alignItems="center"
        sx={{
          width: "100%",
          mt: userRole === "5" ? "1rem" : "2rem",
          mb: userRole === "5" ? "1rem" : "1rem",
          mr: userRole === "5" ? 1 : isScreen900 ? 3 : "auto",
          ml: userRole === "5" ? 1 : isScreen900 ? 3 : "auto",
        }}
      >
        <Stack direction="row" sx={{ mr: userRole === "5" ? 1 : 3 }}>
          {/* {bio.instagram && ( */}
          <Box
            sx={{
              ml: userRole === "5" ? 1 : 2,
              backgroundColor: "#DF63B1",
              width: userRole === "5" ? "15px" : "30px",
              height: userRole === "5" ? "15px" : "30px",
              borderRadius: userRole === "5" ? "0.5rem" : "1rem",
              display: "flex",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => navigate(bio.instagram)}
          >
            <FaInstagram
              style={{
                width: userRole === "5" ? "10px" : "20px",
                height: userRole === "5" ? "10px" : "20px",
                margin: "auto",
                color: "#ffff",
              }}
            />
          </Box>
          {/* )} */}
          {/* {bio.facebook && ( */}
          <Box
            sx={{
              ml: userRole === "5" ? 1 : 2,
              backgroundColor: "#3A589B",
              width: userRole === "5" ? "15px" : "30px",
              height: userRole === "5" ? "15px" : "30px",
              borderRadius: userRole === "5" ? "0.5rem" : "1rem",
              display: "flex",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => navigate(bio.facebook)}
          >
            <FaFacebookF
              style={{
                width: userRole === "5" ? "8.5px" : "17.5px",
                height: userRole === "5" ? "8.5px" : "17.5px",
                margin: "auto",
                color: "#ffff",
              }}
            />
          </Box>
          {/* )} */}
          {bio.phone1 && (
            <Tooltip title={bio.phone1} arrow placement="top-start">
              <Box
                sx={{
                  ml: userRole === "5" ? 1 : 2,
                  background: `${theme.palette.primaryButton.main}`,
                  width: userRole === "5" ? "15px" : "30px",
                  height: userRole === "5" ? "15px" : "30px",
                  borderRadius:
                    userRole === "5" && !isScreen900 ? "0.5rem" : "1rem",
                  display: "flex",
                }}
              >
                <FaPhoneAlt
                  style={{
                    width: userRole === "5" ? "7.5px" : "15px",
                    height: userRole === "5" ? "7.5px" : "15px",
                    margin: "auto",
                    color: "#ffff",
                  }}
                />
              </Box>
            </Tooltip>
          )}
        </Stack>
        <Typography
          component="p"
          variant={userRole === "5" || !isScreen900 ? "caption2" : "body2"}
          sx={{
            fontSize: userRole === "5" ? 8 : "",
            textAlign: isScreen900 ? null : "center",
            mt: !isScreen900 ? "2rem" : null,
          }}
        >
          &copy; {new Date().getFullYear()} {`${bio.inst_name}.`} All Rights
          Reserved
        </Typography>
      </Stack>
    </Grid>
  );
};

const JobSeekerFooter = ({ bio, userRole }) => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  return (
    <Grid
      item
      container
      sx={{
        maxWidth:
          userRole === "5"
            ? "500px"
            : isScreen2000
            ? "1536px"
            : isScreen1700
            ? "1440px"
            : isScreen1400
            ? "1200px"
            : "1024px",
        m: "0 auto",
        p: userRole === "5" ? 1 : 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderTop:
          params.product || params.dashboard ? "1px solid lightgrey" : null,
      }}
    >
      <Typography
        component="p"
        variant={userRole === "5" ? "caption2" : "body2"}
        sx={{ fontSize: userRole === "5" ? 8 : "" }}
      >
        &copy; {new Date().getFullYear()} {`${bio.inst_name}.`} All Rights
        Reserved
      </Typography>
      <Stack direction="row">
        {/* {bio.instagram && ( */}
        <Box
          sx={{
            ml: userRole === "5" ? 1 : 2,
            backgroundColor: "#DF63B1",
            width: userRole === "5" ? "15px" : "30px",
            height: userRole === "5" ? "15px" : "30px",
            borderRadius: userRole === "5" ? "0.5rem" : "1rem",
            display: "flex",
            "&:hover": { cursor: "pointer" },
          }}
          onClick={() => navigate(bio.instagram)}
        >
          <FaInstagram
            style={{
              width: userRole === "5" ? "10px" : "20px",
              height: userRole === "5" ? "10px" : "20px",
              margin: "auto",
              color: "#ffff",
            }}
          />
        </Box>
        {/* )} */}
        {/* {bio.facebook && ( */}
        <Box
          sx={{
            ml: userRole === "5" ? 1 : 2,
            backgroundColor: "#3A589B",
            width: userRole === "5" ? "15px" : "30px",
            height: userRole === "5" ? "15px" : "30px",
            borderRadius: userRole === "5" ? "0.5rem" : "1rem",
            display: "flex",
            "&:hover": { cursor: "pointer" },
          }}
          onClick={() => navigate(bio.facebook)}
        >
          <FaFacebookF
            style={{
              width: userRole === "5" ? "8.5px" : "17.5px",
              height: userRole === "5" ? "8.5px" : "17.5px",
              margin: "auto",
              color: "#ffff",
            }}
          />
        </Box>
        {/* )} */}
        {bio.phone1 && (
          <Tooltip title={bio.phone1} arrow placement="top-start">
            <Box
              sx={{
                ml: userRole === "5" ? 1 : 2,
                background: `${theme.palette.primaryButton.main}`,
                width: userRole === "5" ? "15px" : "30px",
                height: userRole === "5" ? "15px" : "30px",
                borderRadius: userRole === "5" ? "0.5rem" : "1rem",
                display: "flex",
              }}
            >
              <FaPhoneAlt
                style={{
                  width: userRole === "5" ? "7.5px" : "15px",
                  height: userRole === "5" ? "7.5px" : "15px",
                  margin: "auto",
                  color: "#ffff",
                }}
              />
            </Box>
          </Tooltip>
        )}
      </Stack>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  libPages: state.nav.libPages,
  isActiveNavbar: state.nav.isActiveNavbar,
});

export default connect(mapStateToProps)(Footer);
