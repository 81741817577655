import React, { useState, useEffect } from "react";
import localforage from "localforage";
import { connect } from "react-redux";
import { setThemePreview, setDeauthenticated } from "src/redux/actions";
import {
  Button,
  Grid,
  Stack,
  Typography,
  Popover,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import {
  red,
  pink,
  purple,
  indigo,
  blue,
  teal,
  yellow,
  deepOrange,
} from "src/theme/colors";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Palette, SaveAs, Check } from "@mui/icons-material";
import { ImExit } from "react-icons/im";
import shadows from "src/theme/shadow";

const CustomButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    boxShadow: "none",
    border: "1px solid lightgrey",
    backgroundColor: "#F6F8FD",
    fontWeight: 700,
    borderRadius: "5rem",
    display: "flex",
    alignItems: "center",
    color: `${theme.palette.text.primary}`,
  },
  "&:hover": {
    backgroundColor: "#F6F8FD",
    boxShadow: `${shadows[4]}`,
  },
}));

const CustomCircularProgress = styled(CircularProgress)(({ theme }) => ({
  "&.MuiCircularProgress-root": {
    height: "15px !important",
    width: "15px !important",
    display: "flex",
    color: `${theme.palette.black.main}`,
  },
}));

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 14,
  },
}));

const ButtonGroup = ({ handleSave, loading }) => {
  const colorTheme = [
    { name: "red", hex: red[500] },
    { name: "pink", hex: pink[500] },
    { name: "purple", hex: purple[500] },
    { name: "indigo", hex: indigo[500] },
    { name: "blue", hex: blue[500] },
    { name: "teal", hex: teal[500] },
    { name: "yellow", hex: yellow[500] },
    { name: "deepOrange", hex: deepOrange[500] },
  ];

  const [getAppSetting, setAppSetting] = useState({});
  const [colorPrimary, setColorPrimary] = useState("");
  const [colorSecondary, setColorSecondary] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "color-popover" : undefined;
  const classes = useStyles();

  useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    const resTheme = await localforage.getItem("theme");
    setAppSetting(resTheme);
    setColorPrimary(resTheme.colorPrimary);
    setColorSecondary(resTheme.colorSecondary);
  };

  const handleColorTheme = () => {
    let theme = {
      colorPrimary: colorPrimary ? colorPrimary : getAppSetting.colorPrimary,
      colorSecondary: colorSecondary
        ? colorSecondary
        : getAppSetting.colorSecondary,
      websiteFor: getAppSetting.websiteFor,
    };
    setThemePreview(theme);
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    await setDeauthenticated();
    window.location.reload();
  };

  return (
    <>
      <CustomButton
        size="medium"
        variant="contained"
        startIcon={<Palette />}
        sx={{ mr: 1 }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Typography variant="span">Theme</Typography>
      </CustomButton>
      <CustomButton
        size="medium"
        variant="contained"
        disabled={loading ? true : false}
        startIcon={loading ? <CustomCircularProgress /> : <SaveAs />}
        sx={{ mr: 1 }}
        onClick={handleSave}
      >
        <Typography variant="span">Save</Typography>
      </CustomButton>
      <CustomButton size="medium" variant="contained" onClick={handleLogout}>
        <ImExit
          style={{
            height: "40px !important",
            marginRight: "0.5rem",
          }}
        />
        <Typography variant="span">Logout</Typography>
      </CustomButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          ".MuiPopover-paper": {
            width: "175px",
            backgroundColor: "#ffff",
            borderRadius: "1rem",
          },
        }}
      >
        <Grid container sx={{ p: 1, pl: 2, pr: 2 }}>
          <Stack direction="column" sx={{ pt: 1, pb: 1, width: "100%" }}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Primary
            </Typography>
            <Grid
              item
              container
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {colorTheme.map((v, i) => {
                return (
                  <Tooltip key={i} title={v.name} arrow>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        maxWidth:
                          colorPrimary === v.name
                            ? "26px !important"
                            : "20px !important",
                        height: "20px",
                        borderRadius: "1rem",
                        backgroundColor: v.hex,
                        m: "6px",
                        mb: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&:hover": { cursor: "pointer" },
                      }}
                      onClick={() => setColorPrimary(v.name)}
                    >
                      {colorPrimary === v.name && (
                        <Check
                          className={classes.root}
                          sx={{ color: "#ffff" }}
                        />
                      )}
                    </Grid>
                  </Tooltip>
                );
              })}
            </Grid>
          </Stack>
          <Stack direction="column" sx={{ pt: 1, pb: 1, width: "100%" }}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Secondary
            </Typography>
            <Grid
              item
              container
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {colorTheme.map((v, i) => {
                return (
                  <Tooltip
                    key={i}
                    title={v.name}
                    arrow
                    className={classes.root}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        maxWidth:
                          colorSecondary === v.name
                            ? "26px !important"
                            : "20px !important",
                        height: "20px",
                        borderRadius: "1rem",
                        backgroundColor: v.hex,
                        m: "6px",
                        mb: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&:hover": { cursor: "pointer" },
                      }}
                      onClick={() => setColorSecondary(v.name)}
                    >
                      {colorSecondary === v.name && (
                        <Check
                          className={classes.root}
                          sx={{ color: "#ffff" }}
                        />
                      )}
                    </Grid>
                  </Tooltip>
                );
              })}
            </Grid>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ width: "100%" }}
          >
            <Button
              size="normal"
              variant="outlined"
              color="blue"
              sx={{
                fontWeight: 600,
                borderRadius: "5rem",
                border: "none",
                "&:hover": {
                  border: "none",
                },
              }}
              onClick={handleColorTheme}
            >
              Apply
            </Button>
          </Stack>
        </Grid>
      </Popover>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ButtonGroup);
