import { setSnackbarOpen } from "src/redux/actions";

export const snackbarFunc = (res, setLoading, txt) => {
  if (res.code === 200) {
    setSnackbarOpen("success", `${txt} successfully!`);
  } else if (res.status === 400) {
    setLoading && setLoading(false);
    setSnackbarOpen("error", res.data.message);
    return;
  }
};
