import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import localforage from "localforage";
import {
  Typography,
  useMediaQuery,
  Grid,
  Autocomplete,
  TextField,
  useTheme,
  Button,
} from "@mui/material";
import { Place, School, Search } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";

import "src/App.css";

const CustomTextField = styled(TextField)(({ theme, userRole }) => ({
  "&.Mui-focused": {
    border: `0.1px solid ${theme.palette.primary.main}`,
  },
  "&:hover fieldset": {
    border: `0.1px solid ${theme.palette.primary.main} !important`,
  },
}));

const SectionHero01 = ({ id, libPages, contentPreview, contentHoverId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const [objContent, setObjContent] = useState({});
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    getInfo();
  }, [contentPreview]);

  const getInfo = async () => {
    const resRole = await localforage.getItem("userRole");
    setUserRole(resRole);

    const resContent = contentPreview.length
      ? contentPreview
      : await localforage.getItem("content");
    const arr = resContent.filter((d) => d.id === id);
    setObjContent(arr[0]);
  };

  const handleSearch = () => {
    navigate(`/v2/user/${libPages[0]}`);
  };

  return (
    <Grid
      container
      component="section"
      className="slide-in-top"
      sx={{
        maxWidth:
          userRole === "5"
            ? "500px"
            : isScreen2000
            ? "1536px"
            : isScreen1400
            ? "1200px"
            : "1024px",
        m: userRole === "5" ? "2rem auto" : "3rem auto",
        // p: isScreen1024
        //   ? "2rem 0 1rem 0"
        //   : isScreen600
        //   ? "2rem 2rem 1rem 2rem"
        //   : "1rem 1rem 0 1rem",
        border: contentHoverId === id ? "1px dashed black" : null,
        display: "flex",
        flexDirection: "column",
        p: userRole === "5" ? 1 : 2,
      }}
    >
      <Typography
        component="h1"
        variant={userRole === "5" ? "body1" : "h3"}
        color="text.primary"
        sx={{ m: "auto", fontWeight: 700 }}
      >
        {objContent.title}
      </Typography>
      <Typography
        component="h2"
        variant={userRole === "5" ? "caption2" : "subtitle1"}
        color="text.tertiary"
        sx={{ m: "0.5rem auto", fontWeight: 600 }}
      >
        {objContent.subtitle}
      </Typography>
      <Grid
        item
        container
        sx={{ mt: theme.spacing(1) }}
        spacing={userRole === "5" ? 1 : 3}
      >
        <Grid item md={2}>
          <Autocomplete
            disablePortal
            id="location"
            freeSolo={userRole === "5" ? true : false}
            // options={refEdu}
            size="small"
            // getOptionLabel={(option) => option.educational_name}
            // options={userRole === "5" ? null : null}
            // onChange={(e, val) => handleSelectEdu(val)}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                hiddenLabel
                placeholder="Location"
                variant="outlined"
                color="primary"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <Place sx={{ color: `${theme.palette.primary.main}` }} />
                  ),
                }}
              />
            )}
          />
        </Grid>

        <Grid item md={2}>
          <Autocomplete
            disablePortal
            id="education"
            freeSolo={userRole === "5" ? true : false}
            // options={refEdu}
            // fullWidth
            size="small"
            // getOptionLabel={(option) => option.educational_name}
            // onChange={(e, val) => handleSelectEdu(val)}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                hiddenLabel
                placeholder="Education"
                variant="outlined"
                color="primary"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <School sx={{ color: `${theme.palette.primary.main}` }} />
                  ),
                }}
              />
            )}
          />
        </Grid>

        <Grid item md={userRole === "5" ? 3 : 4}>
          <Autocomplete
            disablePortal
            id="position"
            freeSolo={userRole === "5" ? true : false}
            // options={refEdu}
            // fullWidth
            size="small"
            // getOptionLabel={(option) => option.educational_name}
            // onChange={(e, val) => handleSelectEdu(val)}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                hiddenLabel
                placeholder="Position"
                variant="outlined"
                color="primary"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <Search sx={{ color: `${theme.palette.primary.main}` }} />
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={userRole === "5" ? 5 : 4}>
          <Button
            size={userRole === "5" ? "small" : "medium"}
            variant="contained"
            color="secondary"
            sx={{
              background: `${theme.palette.secondaryButton.main}`,
              borderRadius: "5rem",
              ml: 1,
              mr: 1,
            }}
            onClick={userRole === "5" ? null : handleSearch}
          >
            Search
          </Button>
          <Button
            size={userRole === "5" ? "small" : "medium"}
            variant="contained"
            color="primary"
            sx={{
              background: `${theme.palette.primaryButton.main}`,
              borderRadius: "5rem",

              ml: userRole === "5" ? 0 : 1,
              mr: 1,
            }}
            onClick={
              userRole === "5"
                ? null
                : () => navigate(`/v2/user/${libPages[0]}`)
            }
          >
            View All Jobs
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  libPages: state.nav.libPages,
  contentPreview: state.preview.contentPreview,
  contentHoverId: state.preview.contentHoverId,
});

export default connect(mapStateToProps)(SectionHero01);
