import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
// import "src/App.css";

export default function RichEditor({
  onChange,
  value,
  websiteFor,
  namePanel,
  minHeight = "300px",
  ...props
}) {
  const buttonListArticle = [
    ["undo", "redo"],
    ["fontSize"], // "font",
    ["bold", "underline", "italic", "strike", "subscript", "superscript"], //, "strike", "subscript", "superscript"
    ["fontColor", "hiliteColor"],
    ["align", "list"], //"lineHeight"
    ["outdent", "indent"],
    ["horizontalRule", "link", "image"], //"table", "image"
    // ["removeFormat"],
  ];

  const buttonListNonArticle = [
    ["undo", "redo"],
    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
    ["fontColor", "hiliteColor"],
  ];

  const sortedFontOptions = [
    "Poppins",
    // ...defaultFonts,
  ].sort();

  return (
    <div className="App">
      <SunEditor
        setContents={value}
        onChange={onChange}
        setOptions={{
          buttonList:
            namePanel === "Article" ? buttonListArticle : buttonListNonArticle,

          defaultTag: "div",
          minHeight: minHeight,
          showPathLabel: false,
          // font: sortedFontOptions,
        }}
        setDefaultStyle={`font-family: ${
          websiteFor === "recruitment" ? "Montserrat" : "Poppins"
        }; line-height: 24px; letter-spacing: 0.02em;`}
        {...props}
      />
    </div>
  );
}
