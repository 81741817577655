export const config = {
  apiKey: "AIzaSyA1jz9GPMjEQyIdP3VEcm9JnZNQfrzPFVk",
  authDomain: "colagio-25706.firebaseapp.com",
  databaseURL: "https://colagio-25706.firebaseio.com",
  projectId: "colagio-25706",
  storageBucket: "colagio-25706.appspot.com",
  messagingSenderId: "623441144044",
  appId: "1:623441144044:web:0f87d1d972ff43a091ddc9",
  measurementId: "G-F32PV1N4ZF",
};
