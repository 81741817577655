import React, { useState, useEffect } from "react";
import { Container, Typography, Button, CircularProgress } from "@mui/material";
import CustomInput from "src/components/CustomInput";
import {
  createPersonalInfo,
  getPersonalInfo,
  updatePersonalInfo,
  setSnackbarOpen,
} from "src/redux/actions";

const PersonalInfo = () => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    setLoading(true);
    const resInfo = await getPersonalInfo();
    setForm(resInfo.data[0] || {});
    setLoading(false);
  };

  const handleChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    if (!form.bio_personal_id) {
      await createPersonalInfo(form);
    } else {
      await updatePersonalInfo(form);
    }
    setSnackbarOpen("success", "Data disimpan");
    setForm({});
    fetchInfo();
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 2 }}>
        <b>Pernyataan Pribadi</b>
      </Typography>
      <CustomInput
        label="Hobi"
        name="hobby"
        onChange={handleChangeInput}
        value={form.hobby}
      />
      <CustomInput
        label="Kekuatan Diri"
        multiline
        rows={4}
        name="strength"
        onChange={handleChangeInput}
        value={form.strength}
      />
      <CustomInput
        label="Kelemahan Diri"
        multiline
        rows={4}
        name="weakness"
        onChange={handleChangeInput}
        value={form.weakness}
      />
      <CustomInput label="Bidang Kerja yang Diminati" />
      <CustomInput
        label="Ekspektasi Gaji"
        name="salary_expectations"
        onChange={handleChangeInput}
        value={form.salary_expectations}
      />
      <CustomInput
        label="Fasilitas/Benefit yang Diharapkan"
        multiline
        rows={4}
        name="expected_benefits"
        onChange={handleChangeInput}
        value={form.expected_benefits}
      />
      <CustomInput
        label="Referensi Karyawan Perusahaan (Nama & Jabatan)"
        name="internal_reference"
        onChange={handleChangeInput}
        value={form.internal_reference}
      />
      <CustomInput
        label="Referensi di Luar Perusahaan (Nama, Jabatan, Nomor telepon)"
        name="outside_reference"
        onChange={handleChangeInput}
        value={form.outside_reference}
      />
      {/* <CustomInput label="Riwayat Penyakit Berat" multiline rows={3} />
      <CustomInput label="Riwayat Penyakit Kambuhan" multiline rows={3} /> */}
      <Button variant="contained" sx={{ float: "right" }} onClick={handleSave}>
        Simpan
      </Button>
    </Container>
  );
};

export default PersonalInfo;
