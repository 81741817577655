import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
  InputAdornment,
  IconButton,
  styled,
  CircularProgress,
  DialogActions,
  Stack,
} from "@mui/material";
import { Email, Lock, Face } from "@mui/icons-material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  loginForm,
  loginGoogle,
  setAuthenticated,
  setOpenLogin,
  setSnackbarOpen,
  getJob,
  uploadFileJp,
  candidateApplyJob,
} from "src/redux/actions";
import { useNavigate } from "react-router";
import { FcGoogle } from "react-icons/fc";
import localforage from "localforage";
import CustomInput from "../CustomInput";
import { DropzoneArea } from "material-ui-dropzone";
import EducationForm from "../Form/EducationForm";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
    // maxWidth: "350px",
  },
}));

const CustomCircularProgress = styled(CircularProgress)(
  ({ theme, loading }) => ({
    "&.MuiCircularProgress-root": {
      height: "20px !important",
      width: "20px !important",
      display: "flex",
      color: loading === "google" ? `${theme.palette.primary.main}` : "#ffff",
      marginRight: loading === "google" ? "0.5rem !important" : null,
    },
  })
);

const ModalApplyJob = ({ open, onClose, defaultBio, jobPostingId }) => {
  const navigate = useNavigate();
  useTheme();
  const [form, setForm] = useState(defaultBio);
  const [loading, setLoading] = useState("content");
  const [jobDetail, setJobDetail] = useState({});
  const [isEduOpen, setIsEduOpen] = useState(false);
  const [eduDatas, setEduDatas] = useState([]);
  const [eduData, setEduData] = useState({});

  useEffect(() => {
    fetchJobDetail();
  }, []);

  const fetchJobDetail = async () => {
    const job = await getJob(jobPostingId);

    setLoading("");
    setJobDetail(job?.data[0]);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleUploadImage = async (files, key) => {
    if (files.length) {
      const res = await uploadFileJp(files[0]);
      setForm({ ...form, [key]: res.data.path });
    }
  };

  const handleOpenEdu = () => {
    setEduData({});
    setIsEduOpen(!isEduOpen);
  };
  const handleEditEdu = (d) => {
    setEduData(d);
    setIsEduOpen(true);
  };
  const handleDeleteEdu = (d) => {
    const copyEdu = [...eduDatas];
    const newEdu = copyEdu.filter((edu) => edu.tempId !== d.tempId);
    setEduDatas(newEdu);
  };
  const handleBlurEdu = (e) => {
    setEduData({ ...eduData, [e.target.name]: e.target.value });
  };
  const handleDateChangeEdu = (d, key) => {
    const year = d.toLocaleString("default", { year: "numeric" });
    const month = d.toLocaleString("default", { month: "2-digit" });
    const day = d.toLocaleString("default", { day: "2-digit" });

    const formattedDate = year + "-" + month + "-" + day;
    setEduData({ ...eduData, [key]: formattedDate });
  };
  const handleSaveEdu = () => {
    const copyEduDatas = [...eduDatas];
    // edit
    if (eduData.tempId) {
      let objectToUpdate = copyEduDatas.find(
        (d) => d.tempId === eduData.tempId
      );
      objectToUpdate = eduData;
      let index = copyEduDatas.findIndex((d) => d.tempId === eduData.tempId);
      copyEduDatas.splice(index, 1, objectToUpdate);
    } else {
      // add
      eduData.tempId = Math.random() * Date.now();
      copyEduDatas.push(eduData);
    }
    setEduDatas(copyEduDatas);
    handleOpenEdu();
  };

  const handleApply = async () => {
    const copyForm = { ...form };
    copyForm.jobPostingId = jobPostingId;
    if (!copyForm.name) copyForm.name = copyForm.fullname;
    if (!copyForm.phone) copyForm.phone = copyForm.mobile_phone;
    if (!copyForm.educations) copyForm.educations = [];
    if (!copyForm.experiences) copyForm.experiences = [];

    const resApply = await candidateApplyJob(copyForm);
    if (resApply.code !== 200) {
      return;
    }
    setSnackbarOpen("success", "Anda berhasil mendaftarkan diri");
    onClose();
  };

  if (loading === "content") return <CustomCircularProgress />;

  return (
    <CustomDialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Apply Job</DialogTitle>
      <DialogContent>
        <CustomInput
          label="Name"
          name="name"
          value={form.name || form.fullname}
          onChange={handleChange}
        />
        <CustomInput
          label="Email"
          name="email"
          value={form.email}
          onChange={handleChange}
          disabled
        />
        {jobDetail.form_regist_jp?.headline !== "off" && (
          <CustomInput
            label="Headline"
            name="headline"
            value={form.headline}
            onChange={handleChange}
          />
        )}
        {jobDetail.form_regist_jp?.phone !== "off" && (
          <CustomInput
            label="Phone"
            name="phone"
            value={form.phone || form.mobile_phone}
            onChange={handleChange}
          />
        )}
        {jobDetail.form_regist_jp?.address !== "off" && (
          <CustomInput
            label="Address"
            name="address"
            multiline
            rows={2}
            onChange={handleChange}
            value={form.address}
          />
        )}
        {jobDetail.form_regist_jp?.photo !== "off" && (
          <Stack sx={{ mb: "2rem" }}>
            <Typography sx={{ fontWeight: 600 }}>Photo</Typography>
            <DropzoneArea
              filesLimit={1}
              dropzoneClass={"dnd-image"}
              onChange={(e) => handleUploadImage(e, "photo")}
              acceptedFiles={["image/*"]}
              showPreviews={true}
              Icon={Face}
            />
          </Stack>
        )}
        {jobDetail.form_regist_jp?.education !== "off" && (
          <EducationForm
            isEduOpen={isEduOpen}
            eduDatas={eduDatas}
            eduData={eduData}
            handleOpenEdu={handleOpenEdu}
            handleEditEdu={handleEditEdu}
            handleDeleteEdu={handleDeleteEdu}
            handleBlurEdu={handleBlurEdu}
            handleDateChangeEdu={handleDateChangeEdu}
            handleSaveEdu={handleSaveEdu}
          />
        )}
        {/* {jobDetail.form_regist_jp?.experience !== "off" && (
            <ExperienceForm
              isExpOpen={isExpOpen}
              expDatas={expDatas}
              expData={expData}
              handleOpenExp={handleOpenExp}
              handleEditExp={handleEditExp}
              handleDeleteExp={handleDeleteExp}
              handleBlurExp={handleBlurExp}
              handleDateChangeExp={handleDateChangeExp}
              handleSaveExp={handleSaveExp}
            />
          )} */}
        {jobDetail.form_regist_jp?.summary !== "off" && (
          <CustomInput
            label="Summary"
            name="summary"
            multiline
            rows={4}
            onChange={handleChange}
          />
        )}
        {jobDetail.form_regist_jp?.resume !== "off" && (
          <Stack sx={{ mb: "2rem" }}>
            <Typography sx={{ fontWeight: 600 }}>Resume</Typography>
            <DropzoneArea
              filesLimit={1}
              dropzoneClass={"dnd-file"}
              onChange={(e) => handleUploadImage(e, "resume")}
              acceptedFiles={["application/pdf"]}
              showPreviews={true}
            />
          </Stack>
        )}
        {jobDetail.form_regist_jp?.cover_letter !== "off" && (
          <CustomInput
            label="Cover letter"
            name="coverLetter"
            multiline
            rows={4}
            onChange={handleChange}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleApply}>
          Apply
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default ModalApplyJob;
