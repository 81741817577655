import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Avatar,
  Button,
  Divider,
  IconButton,
  CircularProgress,
  Stack,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import CustomDatePicker from "src/components/CustomDatePicker";
import { CustomAutoComplete } from "src/pages/admin/Setting/EditPanel/ComponentGroup";
import {
  refEthnic,
  refCountries,
  refReligion,
  getRefRegion,
  getBiodata,
  updateBiodata,
  setSnackbarOpen,
  uploadFileJp,
} from "src/redux/actions";

const baseURL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:5000/"
    : window.location.origin + "/";

const PersonalData = () => {
  const [loading, setLoading] = useState(true);
  const [ethnics, setEthnics] = useState([]);
  const [countries, setCountries] = useState([]);
  const [religions, setReligions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [kecamatan, setKecamatan] = useState([]);
  const [kelurahan, setKelurahan] = useState([]);
  const [zipCode, setZipCode] = useState([]);
  const [provincesKtp, setProvincesKtp] = useState([]);
  const [citiesKtp, setCitiesKtp] = useState([]);
  const [kecamatanKtp, setKecamatanKtp] = useState([]);
  const [kelurahanKtp, setKelurahanKtp] = useState([]);
  const [zipCodeKtp, setZipCodeKtp] = useState([]);
  const [form, setForm] = useState({});

  useEffect(() => {
    fetchRef();
  }, []);

  useEffect(() => {
    fetchBio();
  }, []);

  const fetchRef = async () => {
    const resRef = await Promise.all([
      refEthnic(),
      refCountries(),
      refReligion(),
      getRefRegion(),
    ]);
    setEthnics(resRef[0].data);
    setCountries(resRef[1].data);
    setReligions(resRef[2].data);
    setProvinces(resRef[3].data);
    setProvincesKtp(resRef[3].data);
  };

  const fetchBio = async () => {
    setLoading(true);
    const getBio = await getBiodata();
    setForm(getBio.data[0]);
    setLoading(false);
  };

  const handleChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleDateChange = (val, key) => {
    setForm({ ...form, [key]: val });
  };

  const handleSelectAuto = async (val, key) => {
    setForm({ ...form, [key]: val[key] });
    if (key === "gender") {
      setForm({ ...form, gender_code: val.gender_code, gender: val });
    }
    if (key === "country") {
      setForm({ ...form, country_id: val.code, country: val });
    }
    if (key === "religion") {
      setForm({ ...form, religion_id: val.code, religion: val });
    }
    if (key === "ethnic") {
      setForm({ ...form, ethnic_id: val.ethnic_id, ethnic: val });
    }
    if (key === "kode_pos") {
      setForm({
        ...form,
        zipcode: val.kode_pos,
        id_pos: val.id_pos,
        kodepos: val,
      });
    }
    if (key === "kode_pos_ktp") {
      setForm({
        ...form,
        zipcode_ktp: val.kode_pos,
        id_pos_ktp: val.id_pos,
        kodepos_ktp: val,
      });
    }

    if (
      key === "province" ||
      key === "city" ||
      key === "kecamatan" ||
      key === "kelurahan"
    ) {
      setForm({ ...form, [key]: val });
      const resRegion = await getRefRegion({ [key]: val[key] });
      if (key === "province") setCities(resRegion.data);
      if (key === "city") setKecamatan(resRegion.data);
      if (key === "kecamatan") setKelurahan(resRegion.data);
      if (key === "kelurahan") setZipCode(resRegion.data);
    }

    if (
      key === "province_ktp" ||
      key === "city_ktp" ||
      key === "kecamatan_ktp" ||
      key === "kelurahan_ktp"
    ) {
      const newKey = key.split("_")[0];
      setForm({ ...form, [key]: val });
      const resRegion = await getRefRegion({ [newKey]: val[newKey] });
      if (key === "province_ktp") setCitiesKtp(resRegion.data);
      if (key === "city_ktp") setKecamatanKtp(resRegion.data);
      if (key === "kecamatan_ktp") setKelurahanKtp(resRegion.data);
      if (key === "kelurahan_ktp") setZipCodeKtp(resRegion.data);
    }
  };

  const handleSave = async () => {
    const copyForm = { ...form };
    copyForm.city = copyForm.city?.city;
    copyForm.kecamatan = copyForm.kecamatan?.kecamatan;
    copyForm.kelurahan = copyForm.kelurahan?.kelurahan;
    copyForm.province = copyForm.province?.province;
    copyForm.city_ktp = copyForm.city_ktp?.city;
    copyForm.kecamatan_ktp = copyForm.kecamatan_ktp?.kecamatan;
    copyForm.kelurahan_ktp = copyForm.kelurahan_ktp?.kelurahan;
    copyForm.province_ktp = copyForm.province_ktp?.province;
    copyForm.ethnic = form?.ethnic_id || form?.ethnic?.ethnic_id;
    // console.log(copyForm);
    await updateBiodata(copyForm);
    setSnackbarOpen("success", "Update data berhasil");
    fetchBio();
  };

  const handleUpload = async (files, key) => {
    if (files.length) {
      const resUpload = await uploadFileJp(files[0]);
      const copyForm = { ...form };
      copyForm[key] = resUpload.data?.path;
      copyForm.city = copyForm.city?.city;
      copyForm.kecamatan = copyForm.kecamatan?.kecamatan;
      copyForm.kelurahan = copyForm.kelurahan?.kelurahan;
      copyForm.province = copyForm.province?.province;
      copyForm.ethnic = form?.ethnic_id || form?.ethnic?.ethnic_id;
      await updateBiodata(copyForm);
      setSnackbarOpen("success", "Update data berhasil");
      fetchBio();
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 2 }}>
        <b>Data Diri</b>
      </Typography>
      <Stack sx={{ display: "flex" }}>
        <IconButton component="label" sx={{ m: "auto", display: "flex" }}>
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={(e) => handleUpload(e.target.files, "photo")}
          />
          <Avatar
            alt={form?.fullname}
            src={baseURL + form?.photo}
            sx={{ width: 120, height: 120 }}
          />
        </IconButton>
      </Stack>
      <CustomInput
        label="Nama Lengkap"
        name="fullname"
        onChange={handleChangeInput}
        value={form?.fullname}
      />
      <CustomInput
        label="Nama Panggilan"
        name="nickname"
        onChange={handleChangeInput}
        value={form?.nickname}
      />
      {/* <CustomInput label="Nomer Identitas" /> */}
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Jenis Kelamin
      </Typography>
      <CustomAutoComplete
        name="gender_code"
        optionsArr={[
          { gender_code: 1, gender_name: "Laki-laki" },
          { gender_code: 2, gender_name: "Perempuan" },
        ]}
        keyLabel={"gender_name"}
        handleSelect={handleSelectAuto}
        value={form?.gender}
        register="true"
        style={{ marginBottom: "1rem" }}
      />
      <CustomInput
        label="Tempat Lahir"
        name="birth_place"
        onChange={handleChangeInput}
        value={form?.birth_place}
      />
      <CustomDatePicker
        label="Tanggal Lahir"
        name="birth_date"
        onChange={handleDateChange}
        value={form?.birth_date}
      />
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>Suku</Typography>
      <CustomAutoComplete
        name="ethnic"
        optionsArr={ethnics}
        keyLabel={"ethnic_name"}
        handleSelect={handleSelectAuto}
        value={form?.ethnic}
        register="true"
        style={{ marginBottom: "1rem" }}
      />
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Kewarganegaraan
      </Typography>
      <CustomAutoComplete
        name="country"
        optionsArr={countries}
        keyLabel={"name"}
        handleSelect={handleSelectAuto}
        value={form?.country}
        register="true"
        style={{ marginBottom: "1rem" }}
      />
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>Agama</Typography>
      <CustomAutoComplete
        name="religion"
        optionsArr={religions}
        keyLabel={"name"}
        handleSelect={handleSelectAuto}
        value={form?.religion}
        register="true"
        style={{ marginBottom: "1rem" }}
      />
      <CustomInput
        label="Tinggi Badan (cm)"
        name="height"
        onChange={handleChangeInput}
        value={form?.height}
      />
      <CustomInput
        label="Berat Badan (kg)"
        name="weight"
        onChange={handleChangeInput}
        value={form?.weight}
      />
      <CustomInput
        label="Nomor Telepon"
        name="home_phone"
        onChange={handleChangeInput}
        value={form?.home_phone}
      />
      <CustomInput
        label="Nomor Whatsapp"
        name="mobile_phone"
        onChange={handleChangeInput}
        value={form?.mobile_phone}
      />
      <CustomInput label="Email" disabled value={form?.email} />
      <Divider />
      <Typography sx={{ fontSize: "20px", mt: 2, mb: 2 }}>
        <b>Alamat</b>
      </Typography>
      <Stack
        sx={{ border: "1px solid #7f8bce", borderRadius: "6px", p: "1rem" }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
          Alamat Domisili
        </Typography>
        <CustomInput
          label="Alamat Lengkap"
          multiline
          rows={4}
          name="address"
          onChange={handleChangeInput}
          value={form?.address}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Provinsi
        </Typography>
        <CustomAutoComplete
          name="province"
          optionsArr={provinces}
          keyLabel={"province"}
          handleSelect={handleSelectAuto}
          value={form?.province}
          register="true"
          style={{ marginBottom: "1rem" }}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kota/Kabupaten
        </Typography>
        <CustomAutoComplete
          name="city"
          optionsArr={cities}
          keyLabel={"city"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.province}
          value={form?.city}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kecamatan
        </Typography>
        <CustomAutoComplete
          name="kecamatan"
          optionsArr={kecamatan}
          keyLabel={"kecamatan"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.city}
          value={form?.kecamatan}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kelurahan
        </Typography>
        <CustomAutoComplete
          name="kelurahan"
          optionsArr={kelurahan}
          keyLabel={"kelurahan"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.kecamatan}
          value={form?.kelurahan}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kode Pos
        </Typography>
        <CustomAutoComplete
          name="kode_pos"
          optionsArr={zipCode}
          keyLabel={"kode_pos"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.kelurahan}
          value={form?.kodepos}
        />
      </Stack>
      <Stack
        sx={{
          border: "1px solid #7f8bce",
          borderRadius: "6px",
          p: "1rem",
          mt: "1rem",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
          Alamat KTP
        </Typography>
        <CustomInput
          label="Alamat Lengkap"
          multiline
          rows={4}
          name="address_ktp"
          onChange={handleChangeInput}
          value={form?.address_ktp}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Provinsi
        </Typography>
        <CustomAutoComplete
          name="province_ktp"
          optionsArr={provincesKtp}
          keyLabel={"province"}
          handleSelect={handleSelectAuto}
          value={form?.province_ktp}
          register="true"
          style={{ marginBottom: "1rem" }}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kota/Kabupaten
        </Typography>
        <CustomAutoComplete
          name="city_ktp"
          optionsArr={citiesKtp}
          keyLabel={"city"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.province_ktp}
          value={form?.city_ktp}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kecamatan
        </Typography>
        <CustomAutoComplete
          name="kecamatan_ktp"
          optionsArr={kecamatanKtp}
          keyLabel={"kecamatan"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.city_ktp}
          value={form?.kecamatan_ktp}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kelurahan
        </Typography>
        <CustomAutoComplete
          name="kelurahan_ktp"
          optionsArr={kelurahanKtp}
          keyLabel={"kelurahan"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.kecamatan_ktp}
          value={form?.kelurahan_ktp}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kode Pos
        </Typography>
        <CustomAutoComplete
          name="kode_pos_ktp"
          optionsArr={zipCodeKtp}
          keyLabel={"kode_pos"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.kelurahan_ktp}
          value={form?.kodepos_ktp}
        />
      </Stack>
      <Button variant="contained" sx={{ float: "right" }} onClick={handleSave}>
        Simpan
      </Button>
    </Container>
  );
};

export default PersonalData;
