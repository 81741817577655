import React from "react";
import { Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomInput from "./CustomInput";

const CustomDatePicker = ({ label, name, onChange, value, ...rest }) => {
  return (
    <div>
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        {label}
      </Typography>
      <DatePicker
        label=""
        value={value}
        onChange={(d) => onChange(d, name)}
        renderInput={(params) => <CustomInput {...params} />}
        {...rest}
      />
    </div>
  );
};

export default CustomDatePicker;
