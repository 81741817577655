import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  articleAPI,
  galleryAPI,
  setArticlePreview,
  setGalPreview,
  setIsChange,
  setTestiPreview,
  testimonyAPI,
} from "src/redux/actions";
import {
  Button,
  Stack,
  Typography,
  styled,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { snackbarFunc } from "src/functions/snackbarFunc";
import { WarningAmber } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { domain } from "src/components/Layout/data";
import localforage from "localforage";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "60px !important",
    color: "#D32F2F",
  },
}));

const CustomCircularProgress = styled(CircularProgress)(() => ({
  "&.MuiCircularProgress-root": {
    height: "15px !important",
    width: "15px !important",
    display: "flex",
    color: "#ffff",
    mr: 1,
  },
}));

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const ModalConfirm = ({ open, onClose, namePanel, dataId, isChange }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [open]);

  const handleDelete = async () => {
    setLoading(true);
    const token = await localforage.getItem("token");

    if (namePanel === "Galery") {
      const resData = await galleryAPI("delete", { token, galleryId: dataId });
      snackbarFunc(resData, setLoading, "Deleted");
      const data = await galleryAPI("get", { domain });
      setGalPreview(data.data);
      setIsChange(!isChange.gal, "gal");
    }
    if (namePanel === "Testimony") {
      const resData = await testimonyAPI("delete", {
        token,
        testimonyId: dataId,
      });
      snackbarFunc(resData, setLoading, "Deleted");
      const data = await testimonyAPI("get", { token, domain });
      setTestiPreview(data.data);
      setIsChange(!isChange.testi, "testi");
      window.location.reload();
    }
    if (namePanel === "Article") {
      const resData = await articleAPI("delete", {
        token,
        articleId: dataId,
      });
      snackbarFunc(resData, setLoading, "Deleted");
      const data = await articleAPI("get", { token, domain });
      setArticlePreview(data.data);
      setIsChange(!isChange.art, "art");
    }
    setLoading(false);
    onClose();
  };

  return (
    <CustomDialog fullWidth open={open} onClose={onClose} maxWidth="xs">
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ m: 1, mb: 4 }}
        >
          <WarningAmber className={classes.root} />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Are you sure?
          </Typography>
          <Typography variant="body2">
            You won't be able to revert this {namePanel.toLowerCase()}?
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="center">
          <Button
            size="medium"
            variant="outlined"
            color="black"
            onClick={onClose}
            // sx={{ width: "47%" }}
          >
            No, not now
          </Button>
          <Button
            size="medium"
            variant="contained"
            color="red"
            sx={{ ml: 2, width: loading ? "35%" : "auto", color: "#ffff" }}
            onClick={loading ? null : handleDelete}
          >
            {loading ? <CustomCircularProgress /> : "Yes, of course"}
          </Button>
        </Stack>
      </DialogContent>
    </CustomDialog>
  );
};

const mapStateToProps = (state) => ({
  isChange: state.preview.isChange,
});

export default connect(mapStateToProps)(ModalConfirm);
