import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import {
  Box,
  Typography,
  useMediaQuery,
  Grid,
  useTheme,
  Paper,
  Stack,
  Chip,
  Tooltip,
  Checkbox,
  Skeleton,
} from "@mui/material";
import { parsedDate } from "src/functions/parsedDate";
import "src/App.css";

const CardArt01 = ({
  v,
  i,
  z,
  userRole,
  searchVal,
  checkbox,
  libPages,
  loading,
  list,
  home,
  mostPopular,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isScreen1536 = useMediaQuery("(min-width:1536px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const isScreen700 = useMediaQuery("(min-width:700px)");
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen500 = useMediaQuery("(min-width:500px)");
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (v) => {
    setOpen(v?.article_id);
  };

  return (
    <>
      {list === "column" && (
        <ListVertical v={v} libPages={libPages} loading={loading} />
      )}

      {list === "row" && (
        <ListHorizontal
          v={v}
          i={i}
          z={z}
          libPages={libPages}
          loading={loading}
          mostPopular={mostPopular}
        />
      )}

      {!list && (
        <Grid
          item
          xs={12}
          sx={{
            maxWidth:
              isScreen1536 && userRole !== "5"
                ? "20% !important"
                : isScreen1200
                ? "25% !important"
                : isScreen700
                ? "33.3% !important"
                : // isScreen500
                  // ? "50% !important"
                  // :
                  "100% !important",
          }}
        >
          <Paper
            elevation={0} // isScreen1024 ? 2 : null
            sx={{
              backgroundColor: "transparent",
              borderRadius: userRole === "5" ? "0.5rem" : "1rem",

              // mobile
              borderBottom: "0.1px solid lightgrey",
              // isScreen1024 ? null : "0.1px solid lightgrey",
              borderBottomLeftRadius:
                userRole === "5" ? "0.5rem" : isScreen500 ? "1rem" : "0",
              borderBottomRightRadius:
                userRole === "5" ? "0.5rem" : isScreen500 ? "1rem" : "0",

              "&:hover": {
                boxShadow: isScreen1024
                  ? "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px"
                  : "none",
              },
            }}
          >
            {loading && (
              <Skeleton
                variant="rectangular"
                sx={{
                  height: userRole === "5" ? "7.5rem" : "10rem",
                  borderRadius: userRole === "5" ? "0.5rem" : "1rem",
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              />
            )}

            {loading && (
              <Stack sx={{ p: 2 }}>
                <Skeleton variant="rectangular" sx={{ height: "1rem" }} />
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "1rem", mt: 1 }}
                />
              </Stack>
            )}

            {checkbox && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "35px",
                  height: "35px",
                  borderRadius: "35px",
                  backgroundColor: "#ffff",
                }}
              >
                <Checkbox />
              </Stack>
            )}

            {!loading && (
              <>
                <Box
                  sx={{
                    backgroundColor: "#f5f5f5",
                    backgroundImage: `url(${v?.cover})`,
                    // backgroundColor: "#F6F8FD",
                    // aspectRatio: "3/2", // between 3/2. 4/3, and 10rem
                    // aspectRatio: "16/9",
                    aspectRatio: "16/9",
                    //untuk sekarang pake cover dulu udah lah gpp gepeng2 dah makanya an harus nyesuain
                    backgroundSize: "cover",
                    // backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // height: userRole === "5" ? "4.5rem" : "10rem",
                    borderRadius: userRole === "5" ? "0.5rem" : "1rem",
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottom: "1px solid lightgrey",

                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={
                    loading || checkbox || userRole === "5"
                      ? null
                      : () =>
                          navigate(`/v2/user/${libPages[2]}/${v?.article_id}`)
                  }
                ></Box>

                <Stack
                  direction="column"
                  sx={{
                    width: "100%",
                    p:
                      userRole === "5"
                        ? `0.25rem ${theme.spacing(1)}`
                        : `0.75rem ${theme.spacing(2)}`,
                    mt: 0,
                    mb:
                      userRole === "5"
                        ? "0.25rem"
                        : isScreen600
                        ? 1
                        : isScreen500
                        ? null
                        : 1,
                  }}
                >
                  <Typography
                    noWrap={true}
                    variant={userRole === "5" ? "caption2" : "caption"}
                    sx={{
                      fontSize: userRole === "5" ? 6 : null,
                      color: "text.tertiary",
                      fontWeight: "500",
                    }}
                  >
                    {parsedDate(v?.created_at, "article")}
                  </Typography>
                  <Typography
                    variant={userRole === "5" ? "caption2" : "body2"}
                    className={
                      userRole === "5"
                        ? "multi-line-text2-adm"
                        : "multi-line-text2"
                    }
                    sx={{
                      fontSize: userRole === "5" ? 8 : null,
                      fontWeight: 600,
                    }}
                  >
                    {v?.title}
                  </Typography>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mt: userRole === "5" ? "0.25rem" : "0.75rem" }}
                  >
                    <Chip
                      label={
                        v.tags && v.tags.length ? v.tags[0].tag_name : "hidden"
                      } // tags that matched with searchVal not data v.tags, if searchVal existed
                      sx={{
                        opacity: v.tags && v.tags.length ? 1 : 0,
                        fontSize: userRole === "5" ? 6 : 12,
                        height: userRole === "5" ? "15px" : "30px",
                        fontWeight: 600,
                        border: home
                          ? `0.1px solid ${theme.palette.primary.main}`
                          : null,
                        backgroundColor: home ? "transparent" : "#f5f5f5",
                        color: home
                          ? `${theme.palette.primary.main}`
                          : `${theme.palette.text.tertiary}`,
                      }}
                    />

                    {v.tags && v.tags.length !== 0 && (
                      <Tooltip
                        title={`${v.tags.length - 1} more tags`}
                        placement="bottom-start"
                        onClose={handleTooltipClose}
                        open={v.article_id === open ? true : false}
                        arrow
                      >
                        <Typography
                          variant="caption"
                          color="primary"
                          sx={{
                            fontWeight: 600,
                            cursor: "default",
                            fontSize: userRole === "5" ? 6 : 12,
                          }}
                          onClick={() => handleTooltipOpen(v)}
                        >{`${v.tags.length - 1} +`}</Typography>
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
              </>
            )}
          </Paper>
        </Grid>
      )}
    </>
  );
};

const ListVertical = ({ v, libPages, loading }) => {
  const navigate = useNavigate();
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#ffff",
          p: 1,
          pb: isScreen1200 ? 2 : null,
          mt: isScreen1200 ? 1 : null,
          borderRadius: "0",
          borderBottom: "1px solid lightgrey",
          "&:hover": { cursor: "pointer" },
        }}
        onClick={
          loading
            ? null
            : () => navigate(`/v2/user/${libPages[2]}/${v?.article_id}`)
        }
      >
        <Grid
          item
          container
          spacing={1}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={5} xl={4}>
            {loading && (
              <Skeleton variant="rectangular" sx={{ height: "4rem" }} />
            )}

            {!loading && (
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  backgroundImage: `url(${v?.cover})`,
                  aspectRatio: "16/9",
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "0.5rem",
                }}
              ></Box>
            )}
          </Grid>
          <Grid item xs={7} xl={8}>
            {loading && (
              <>
                <Skeleton variant="rectangular" sx={{ height: "0.5rem" }} />
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "0.5rem", mt: 1 }}
                />
              </>
            )}

            {!loading && (
              <>
                <Typography
                  variant="caption2"
                  sx={{
                    color: "text.tertiary",
                    fontWeight: "500",
                  }}
                >
                  {parsedDate(v?.created_at, "article")}
                </Typography>
                <Typography
                  variant="caption"
                  className="multi-line-text2"
                  sx={{ fontWeight: 500 }}
                >
                  {v?.title}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const ListHorizontal = ({ v, i, z, libPages, loading, mostPopular }) => {
  const navigate = useNavigate();
  const isScreen500 = useMediaQuery("(min-width:500px)");
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen700 = useMediaQuery("(min-width:700px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");

  return (
    <Grid
      item
      xs={12}
      sx={{
        minWidth: mostPopular
          ? null
          : isScreen700
          ? "50vw !important"
          : isScreen500
          ? "60vw !important"
          : "70vw !important",
        ml: !isScreen700 && i === 0 ? 2 : null,
        mr: !isScreen700 && i === z ? 2 : null,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          borderRadius: "1rem",
          backgroundColor: "#ffff",
          position: "relative",
          "&:hover": {
            cursor: "pointer",
            boxShadow: isScreen1024
              ? "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px"
              : "none",
          },
        }}
        onClick={
          loading
            ? null
            : () => navigate(`/v2/user/${libPages[2]}/${v?.article_id}`)
        }
      >
        {loading && (
          <Skeleton
            variant="rectangular"
            sx={{
              height: mostPopular ? "20rem" : "10rem",
              borderRadius: "1rem",
            }}
          />
        )}

        {!loading && (
          <>
            <Box
              sx={{
                backgroundColor: "#f5f5f5",
                backgroundImage: `url(${v?.cover})`,
                aspectRatio: "16/9",
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "1rem",
              }}
            ></Box>

            <Stack
              direction="column"
              sx={{
                p: 2,
                position: "absolute",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                bottom: 0,
                width: "100%",
                borderBottomLeftRadius: "1rem",
                borderBottomRightRadius: "1rem",
              }}
            >
              {isScreen600 && (
                <Typography variant="caption" sx={{ color: "#ffff", mb: 1 }}>
                  {parsedDate(v?.created_at, "article")}
                </Typography>
              )}

              <Typography
                noWrap={true}
                variant="body2"
                sx={{ fontWeight: 500, color: "#ffff" }}
              >
                {v?.title}
              </Typography>
            </Stack>
          </>
        )}
      </Paper>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  libPages: state.nav.libPages,
});

export default connect(mapStateToProps)(CardArt01);
