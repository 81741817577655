import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { getJobs, getPublish, setIsActiveNavbar } from "src/redux/actions";
import { connect } from "react-redux";
import localforage from "localforage";
import bg from "src/assets/img/background.jpg";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  CircularProgress,
  Stack,
} from "@mui/material";
import FilterInput from "src/components/Layout/FilterInput";
import { dummy, dataPub, domain } from "src/components/Layout/data";
import CardPro01 from "src/components/Cards/CardPro01";
import CardPro02 from "src/components/Cards/CardPro02";
import ModalConfirm from "src/components/ModalConfirm";
import "src/App.css";

const Index = ({ libPages }) => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const [idPage, setIdPage] = useState("");
  const [loading, setLoading] = useState(true);
  const [productName, setProductName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [objContent, setObjContent] = useState({});
  const [userRole, setUserRole] = useState("");
  const [publish, setPublish] = useState([]);

  useEffect(() => {
    checkParamsUrl();
    fetchData();
  }, []);

  const checkParamsUrl = async () => {
    const isLib = libPages.indexOf(params.product);
    if (isLib === -1) {
      navigate("/v2/user/");
      window.location.reload();
      return;
    }
    // when load the data publish
    const arrPublish = [{ ...dataPub }, { ...dataPub }, { ...dataPub }];
    setPublish(arrPublish);

    const resNav = await localforage.getItem("navigation");
    console.log(resNav);
    let idx = resNav.findIndex((obj) => obj.page === "product");
    const title = resNav[idx].title;
    const defaultTitle = resNav[idx].defaultTitle;
    const id = resNav[idx].id;

    const resContent = await localforage.getItem("content");
    const arr = resContent.filter((d) => d.id.indexOf(id) !== -1);

    setProductName(title ? title : defaultTitle);
    setIsActiveNavbar(title ? title : defaultTitle);
    setIdPage(id);
    setObjContent(arr[0]);
  };

  const fetchData = async () => {
    const resRole = await localforage.getItem("userRole");
    setUserRole(resRole);

    const resTheme = await localforage.getItem("theme");
    if (resTheme.websiteFor === "recruitment") {
      const resJobs = await getJobs({ domain });
      setPublish(resJobs.data);
    } else {
      const resPublish = await getPublish();
      setPublish(resPublish.data);
    }
    setLoading(false);
  };

  const handleSearch = () => {};

  return (
    <Grid component="main">
      <Stack
        sx={{
          height: "150px",
          background: `${theme.palette.background.paper2}`,
          // position: "relative",
        }}
      >
        <Box
          sx={{
            maxWidth:
              userRole === "5"
                ? "500px"
                : isScreen2000
                ? "1536px"
                : isScreen1700
                ? "1440px"
                : isScreen1400
                ? "1200px"
                : isScreen1200
                ? "1024px"
                : "900px",
            m: "auto",
            p: 2,
            pl: isScreen600 ? 3 : 2,
            pr: isScreen600 ? 3 : 2,
          }}
        >
          <Typography variant={isScreen1024 ? "h4" : "h5"}>
            {productName}
          </Typography>
          {/* <Typography
            variant="span"
            sx={{
              width: "50px",
              backgroundColor: `${theme.palette.primary.main}`,
              height: "7.5px",
              borderRadius: "1rem",
              m: "1rem auto",
            }}
          ></Typography> */}
        </Box>
      </Stack>
      <Grid
        container
        component="section"
        sx={{
          maxWidth:
            userRole === "5"
              ? "500px"
              : isScreen2000
              ? "1536px"
              : isScreen1700
              ? "1440px"
              : isScreen1400
              ? "1200px"
              : isScreen1200
              ? "1024px"
              : "900px",
          m: "auto",
          p: 2,
          pl: 3,
          pr: 3,
          borderRadius: isScreen900 ? null : "1rem",
          border: isScreen900 ? null : "1px solid lightgrey",
        }}
      >
        {idPage === "pro01" && (
          <>
            <FilterInput handleSearch={handleSearch} />
            <Grid item container spacing={3}>
              {publish.map((v, i) => {
                return (
                  <CardPro01 v={v} key={i} userRole={userRole} home={"true"} />
                );
              })}
            </Grid>
          </>
        )}
        {idPage === "pro02" && (
          <Grid
            item
            container
            spacing={3}
            sx={{
              pt: 2,
            }}
          >
            {publish.map((v, i) => (
              <CardPro02
                v={v}
                key={i}
                objContent={objContent}
                loading={loading}
                home
              />
            ))}
          </Grid>
        )}

        {/* <ModalConfirm
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={dataModal}
        /> */}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  libPages: state.nav.libPages,
});

export default connect(mapStateToProps)(Index);
