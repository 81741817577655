import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import localforage from "localforage";
import { Box, Typography, useMediaQuery, Grid, useTheme } from "@mui/material";
import "src/App.css";

const SectionDes03 = ({ id, contentPreview, contentHoverId }) => {
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const [objContent, setObjContent] = useState({});
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    getInfo();
  }, [contentPreview]);

  const getInfo = async () => {
    const resRole = await localforage.getItem("userRole");
    setUserRole(resRole);

    const resContent = contentPreview.length
      ? contentPreview
      : await localforage.getItem("content");
    const arr = resContent.filter((d) => d.id === id);
    setObjContent(arr[0]);
  };

  return (
    <Grid
      container
      component="section"
      className="slide-in-left"
      sx={{
        maxWidth:
          userRole === "5"
            ? "500px"
            : isScreen2000
            ? "1536px"
            : isScreen1400
            ? "1200px"
            : "1024px",
        m: "1rem auto",
        // p: isScreen1024
        //   ? "2rem 0 1rem 0"
        //   : isScreen600
        //   ? "2rem 2rem 1rem 2rem"
        //   : "1rem 1rem 0 1rem",
        border: contentHoverId === id ? "1px dashed black" : null,
        p: userRole === "5" ? 1 : 2,
        // border: "1px solid black",
      }}
    >
      {/* display title if position center-top =s= */}
      {objContent.posTitle === "center-top" && objContent.title && (
        <Title
          content={objContent.title}
          objContent={objContent}
          userRole={userRole}
        />
      )}

      <Grid
        item
        container
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        spacing={userRole === "5" ? 3 : 5}
      >
        {/* display img if position left =s= */}
        {objContent.posImg === "left" && (
          <Grid item md={5}>
            <img
              alt={`${objContent.id}`}
              src={objContent.img}
              style={{
                objContent: "cover",
                width: "100%",
                height: "100%",
                borderRadius: userRole === "5" ? "2.5rem" : "5rem",
                boxShadow:
                  "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
              }}
            />
          </Grid>
        )}

        <Grid item md={7}>
          {/* display title if position img-side atau subtitle =s= */}
          {objContent.posTitle === "img-side" && objContent.title && (
            <Title
              content={objContent.posTitle === "img-side" && objContent.title}
              objContent={objContent}
              posTitle={objContent.posTitle}
              userRole={userRole}
            />
          )}

          {objContent.subtitle && (
            <Title
              content={objContent.subtitle}
              objContent={objContent}
              posTitle={objContent.posTitle}
              userRole={userRole}
            />
          )}

          <Typography
            component="p"
            variant={userRole === "5" ? "caption2" : "body2"}
            sx={{
              m: userRole === "5" ? "0.25rem auto" : "1rem auto",
              fontSize: userRole === "5" ? 8 : "",
              textAlign:
                objContent.alignText === "center"
                  ? "center"
                  : objContent.alignText === "justify"
                  ? "justify"
                  : objContent.alignText === "left"
                  ? "start"
                  : "end",
            }}
          >
            {objContent.paragraph}
          </Typography>
        </Grid>
        {/* display img if position right =s= */}
        {objContent.posImg === "right" && (
          <Grid item md={5}>
            <img
              alt={`${objContent.id}`}
              src={objContent.img}
              style={{
                objContent: "cover",
                width: "100%",
                height: "100%",
                borderRadius: userRole === "5" ? "2.5rem" : "5rem",
                boxShadow:
                  "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const Title = ({ content, objContent, posTitle, userRole }) => {
  const theme = useTheme();

  const marginFunc = () => {
    if (!posTitle) {
      return userRole === "5"
        ? "0.5rem auto 0.75rem auto"
        : "1rem auto 1.75rem auto";
    } else {
      if (objContent.alignTitle === "center") {
        return userRole === "5"
          ? "0.25rem auto 0.125rem auto"
          : "1rem auto 0.25rem auto";
      } else if (objContent.alignTitle === "left") {
        return userRole === "5" ? "0.25rem 0 0.125rem 0" : "1rem 0 0.25rem 0";
      } else {
        return userRole === "5"
          ? "0.25rem 0 0.125rem auto"
          : "1rem 0 0.25rem auto";
      }
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        m:
          !posTitle && userRole === "5"
            ? "0.5rem auto"
            : !posTitle
            ? "1rem auto"
            : null,
        // backgroundColor: "red",
      }}
    >
      <Typography
        variant={
          content === objContent.title && userRole === "5"
            ? "body2"
            : content === objContent.subtitle && userRole === "5"
            ? "caption"
            : content === objContent.title
            ? "h3"
            : content === objContent.subtitle
            ? "h4"
            : null
        }
        sx={{
          fontWeight: 700,
          textAlign:
            objContent.alignTitle === "center"
              ? "center"
              : objContent.alignTitle === "left"
              ? "start"
              : "end",
        }}
      >
        {content}
      </Typography>
      {content !== objContent.subtitle && (
        <Typography
          variant="span"
          sx={{
            width: userRole === "5" ? "25px" : "50px",
            backgroundColor: `${theme.palette.primary.main}`,
            height: userRole === "5" ? "4px" : "7.5px",
            borderRadius: "1rem",
            m: marginFunc(),
          }}
        ></Typography>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  contentPreview: state.preview.contentPreview,
  contentHoverId: state.preview.contentHoverId,
});

export default connect(mapStateToProps)(SectionDes03);
