import UserLayout from "src/components/Layout/UserLayout";
import AdminLayout from "./components/Layout/AdminLayout";
import Article from "./pages/user/Article";
import DetailArticle from "./pages/user/Article/DetailArticle";
import Galery from "./pages/user/Galery";
import Products from "src/pages/user/Products";
import ProductDetail from "src/pages/user/ProductDetail";
import MyDashboard from "src/pages/user/MyDashboard";
import Register from "src/components/Register";
import LandingUser from "src/pages/user/Landing";
// import LandingAdmin from "src/pages/admin/Landing";
import Setting from "src/pages/admin/Setting";
import { Navigate } from "react-router-dom";
import Profile from "src/pages/user/Profile";
import SignA from "src/pages/user/Sign/SignA"
import SignB from "src/pages/user/Sign/SignB";

const routes = [
  {
    path: "",
    children: [{ path: "", element: <Navigate to="/v2/user/" /> }],
  },
  {
    path: "v2",
    children: [{ path: "", element: <Navigate to="/v2/user/" /> }],
  },
  {
    path: "/v2/user",
    element: <UserLayout />,
    children: [
      { path: "", element: <LandingUser /> },
      { path: "article", element: <Article /> },
      { path: "profile", element: <Profile /> },
      { path: "signA", element: <SignA /> },
      { path: "signB", element: <SignB /> },
      { path: "article/:idArticle", element: <DetailArticle /> },
      { path: "galery", element: <Galery /> },
      { path: "dashboard/:dashboard", element: <MyDashboard /> },
      { path: ":product", element: <Products /> },
      { path: ":product/:id", element: <ProductDetail /> },
    ],
  },
  {
    path: "v2/admin",
    element: <AdminLayout />,
    children: [
      { path: "", element: <Navigate to="/v2/admin/setting" /> }, // element: <LandingAdmin />
      { path: "setting", element: <Setting /> },
    ],
  },
  { path: "/v2/register", element: <Register /> },
];

export default routes;
