import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { setIsActiveNavbar } from "src/redux/actions";
import { connect } from "react-redux";
import localforage from "localforage";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  CircularProgress,
} from "@mui/material";
import shadows from "src/theme/shadow";
// import Card from "src/components/Card";
import "src/App.css";

const Index = ({ libPages }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // const [buttonHoverClass, setButtonHoverClass] = useState(false);

  useEffect(() => {
    checkParamsUrl();
  }, []);

  const checkParamsUrl = async () => {
    const isLib = libPages.indexOf(params.product);
    if (isLib === -1) {
      navigate("/v2/user/");
      window.location.reload();
      return;
    }
    setIsActiveNavbar("");
    setLoading(false);
  };

  const theme = useTheme();
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");

  if (loading)
    return (
      <CircularProgress
        sx={{
          color: `${theme.palette.primary.main}`,
          m: "auto",
          display: "flex",
        }}
      />
    );

  return (
    <Grid component="main" sx={{}}>
      ini detail product
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  libPages: state.nav.libPages,
});

export default connect(mapStateToProps)(Index);
