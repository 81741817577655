import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import {
  getPublish,
  setIsActiveNavbar,
  getJobs,
  myJobs,
} from "src/redux/actions";
import { connect } from "react-redux";
import localforage from "localforage";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  Stack,
} from "@mui/material";
import CardPro02 from "src/components/Cards/CardPro02";
import { dummy, dataPub } from "src/components/Layout/data";
import "src/App.css";
import CardPro01 from "src/components/Cards/CardPro01";
import MyCareer from "src/components/Tables/MyCareer";

const Index = ({ isAuthenticated, libPages }) => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const [idPage, setIdPage] = useState("");
  const [loading, setLoading] = useState(true);
  const [dashboardName, setDashboardName] = useState("");
  const [objContent, setObjContent] = useState({});
  const [userRole, setUserRole] = useState("");
  const [publish, setPublish] = useState([]);

  useEffect(() => {
    checkParamsUrl();
    fetchData();
  }, []);

  const checkParamsUrl = async () => {
    if (!isAuthenticated) {
      navigate("/v2/user/");
      window.location.reload();
      return;
    }
    const isLib = libPages.indexOf(params.dashboard);
    if (isLib === -1) {
      navigate("/v2/user/");
      window.location.reload();
      return;
    }
    // when load the data publish
    const arrPublish = [{ ...dataPub }, { ...dataPub }, { ...dataPub }];
    setPublish(arrPublish);
    const resNav = await localforage.getItem("navigation");
    let idx = resNav.findIndex((obj) => obj.page === "dashboard");
    const title = resNav[idx].title;
    const defaultTitle = resNav[idx].defaultTitle;

    const resContent = await localforage.getItem("content");
    const arr = resContent.filter((d) => d.id.indexOf("pro") !== -1);

    setDashboardName(title ? title : defaultTitle);
    setIsActiveNavbar(title ? title : defaultTitle);
    setIdPage(resNav[idx].id);
    setObjContent(arr[0]);
  };

  const fetchData = async () => {
    const resRole = await localforage.getItem("userRole");
    setUserRole(resRole);

    const token = await localforage.getItem("token");
    const resPublish = await getPublish(token);
    setPublish(resPublish.data);
    setLoading(false);
  };

  return (
    <Grid component="main">
      <Stack
        sx={{
          height: "150px",
          backgroundImage: `${theme.palette.background.paper2}`,
        }}
      >
        <Box
          sx={{
            maxWidth:
              userRole === "5"
                ? "500px"
                : isScreen2000
                ? "1536px"
                : isScreen1700
                ? "1440px"
                : isScreen1400
                ? "1200px"
                : isScreen1200
                ? "1024px"
                : "900px",
            m: "auto",
            p: 2,
            pl: isScreen600 ? 3 : 2,
            pr: isScreen600 ? 3 : 2,
          }}
        >
          <Typography variant={isScreen1024 ? "h4" : "h5"}>
            {dashboardName}
          </Typography>
          {/* <Typography
            variant="span"
            sx={{
              width: "50px",
              backgroundColor: `${theme.palette.primary.main}`,
              height: "7.5px",
              borderRadius: "1rem",
              m: "1rem auto",
            }}
          ></Typography> */}
        </Box>
      </Stack>
      <Grid
        container
        component="section"
        sx={{
          maxWidth:
            userRole === "5"
              ? "500px"
              : isScreen2000
              ? "1536px"
              : isScreen1700
              ? "1440px"
              : isScreen1400
              ? "1200px"
              : isScreen1200
              ? "1024px"
              : "900px",
          m: "auto",
          p: 2,
          pl: 3,
          pr: 3,
          borderRadius: isScreen900 ? null : "1rem",
          border: isScreen900 ? null : "1px solid lightgrey",
        }}
      >
        {idPage === "dash01" && (
          <MyCareer />
          // <Grid
          //   item
          //   container
          //   spacing={3}
          //   sx={{
          //     pt: 2,
          //   }}
          // >
          //   {publish.map((v, i) => (
          //     <CardPro01
          //       v={v}
          //       key={i}
          //       objContent={objContent}
          //       loading={loading}
          //     />
          //   ))}
          // </Grid>
        )}

        {idPage === "dash02" && (
          <Grid
            item
            container
            spacing={3}
            sx={{
              pt: 2,
            }}
          >
            {publish.map((v, i) => (
              <CardPro02
                v={v}
                key={i}
                objContent={objContent}
                loading={loading}
              />
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  libPages: state.nav.libPages,
});

export default connect(mapStateToProps)(Index);
