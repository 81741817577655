import React, { useState, useEffect } from "react";
import localforage from "localforage";
import { connect } from "react-redux";
import Header from "src/components/Header";
import SectionHero01 from "src/components/Layout/SectionHero01";
import SectionDes from "src/components/Layout/SectionDes";
import SectionDes01 from "src/components/Layout/SectionDes01";
import SectionDes02 from "src/components/Layout/SectionDes02";
import SectionDes03 from "src/components/Layout/SectionDes03";
import SectionDes04 from "src/components/Layout/SectionDes04";
import SectionHero02 from "src/components/Layout/SectionHero02";
import SectionProduct from "src/components/Layout/SectionProduct";
import SectionArticle from "src/components/Layout/SectionArticle";
import SectionGalery from "src/components/Layout/SectionGalery";
import SectionTestimony from "src/components/Layout/SectionTestimony";
import Footer from "src/components/Footer";
import { Grid, Stack, useTheme } from "@mui/material";
import { setIsActiveNavbar, setIsPreview } from "src/redux/actions";

const Index = ({ contentPreview, navPreview }) => {
  const theme = useTheme();
  const [content, setContent] = useState([]);

  useEffect(() => {
    fetchMenu();
    setIsPreview(true);
  }, [contentPreview, navPreview]);

  const fetchMenu = async () => {
    const resNav = navPreview.length
      ? navPreview
      : await localforage.getItem("navigation");

    let idx = resNav.findIndex((obj) => obj.page === "home");
    const title = resNav[idx].title;
    const defaultTitle = resNav[idx].defaultTitle;
    setIsActiveNavbar(title ? title : defaultTitle);

    const resContent = contentPreview.length
      ? contentPreview
      : await localforage.getItem("content");
    const arr = resContent.filter((v) => !v.disabled);
    setContent(arr);
  };

  return (
    <Stack
      sx={{
        background: `${theme.palette.background.paper}`,
        minHeight: "100vh",
      }}
    >
      <Header />
      <Grid component="main">
        {content.map((v) => (
          <>
            {v.id === "hero01" && <SectionHero01 key={v.id} id={v.id} />}
            {v.id === "hero02" && <SectionHero02 key={v.id} id={v.id} />}
            {v.id === "pro02-c" && <SectionProduct key={v.id} id={v.id} />}
            {v.id === "art01-c" && <SectionArticle key={v.id} id={v.id} />}
            {v.id === "gal01-c" && <SectionGalery key={v.id} id={v.id} />}
            {v.id === "tes01-c" && <SectionTestimony key={v.id} id={v.id} />}
            {/* {v.id === "des01" && <SectionDes01 key={v.id} id={v.id} />}
            {v.id === "des02" && <SectionDes02 key={v.id} id={v.id} />}
            {v.id === "des03" && <SectionDes03 key={v.id} id={v.id} />}
            {v.id === "des04" && <SectionDes04 key={v.id} id={v.id} />} */}
            {v.id.indexOf("des") !== -1 && <SectionDes key={v.id} id={v.id} />}
          </>
        ))}
      </Grid>
      <Footer />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  contentPreview: state.preview.contentPreview,
  navPreview: state.preview.navPreview,
});

export default connect(mapStateToProps)(Index);
